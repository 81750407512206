import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68632edb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "aria-labelledby"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    role: "group",
    id: 'form-group-' + _ctx.elementKey,
    class: "form-group-container"
  }, [
    _createElementVNode("div", {
      id: 'form-group-legend-container-' + _ctx.elementKey,
      class: "form-group",
      "aria-labelledby": 'form-group-section-legend-' + _ctx.elementKey
    }, [
      _createElementVNode("div", {
        id: 'form-group-section-legend-' + _ctx.elementKey,
        class: "form-legend"
      }, _toDisplayString(_ctx.sectionLabel), 9, _hoisted_3),
      _createElementVNode("div", {
        id: 'form-group-section-container-' + _ctx.elementKey
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 8, _hoisted_4)
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}