import { State } from './state'
import { isInputMeta } from '@/models/wizard'
import { MessageModels } from '@/models/message'
import { UserModels } from '@/models/user'
import { WizardStateModels } from '@/models/store'
import { deepClone } from '@/utils'
import { TransactionOptions } from '@/models/wizard/transactions'

const checkMetaIsValid = (field: any, debugMode: boolean): boolean => {
  try {
    if (isInputMeta(field)) {
      if (!field.valid && debugMode) {
        console.warn('Field not valid for submission', Object.keys(field))
      }
      return !!field.valid
    }
    return !Object.values(field).some(value => {
      const notValid = !checkMetaIsValid(value, debugMode)
      if (debugMode && notValid) {
        console.warn(
          'Missing or Invalid field for: ',
          typeof field === 'object' ? Object.keys(field) : field
        )
      }
      return notValid
    })
  } catch (error: any) {
    return false
  }
}

const getActiveFeatures = (state: State): string[] => {
  return state.activeFeatures
}

const getAuthExpiration = (state: State): number => {
  return state.authorization.expires
}

const getAuthorization = (state: State): WizardStateModels.Authorization => {
  return deepClone(state.authorization)
}

const getCSRFToken = (state: State): string | null => {
  return state.csrf
}

const getCancellationSectionMeta = (state: State): WizardStateModels.WizardCancellationMeta => {
  return deepClone(state.cancellationMeta)
}

const getDetailsSectionMeta = (state: State): WizardStateModels.WizardDetailsSectionFields => {
  return deepClone(state.fields.detailsSection)
}

const getIsLinksLoading = (state: State): boolean => {
  return state.isLinksLoading
}

const getToken = (state: State): string | null => {
  return state.authorization.token
}

const getUser = (state: State): UserModels.UserState => {
  return state.user
}

const getError = (state: State): MessageModels.MessageState => {
  const clone = deepClone(state.error)
  return { ...clone, action: state?.error?.action || null }
}

const getAllFieldsMeta = (state: State): WizardStateModels.WizardFields => {
  return deepClone(state.fields)
}

const getLastActive = (state: State): string => {
  return state.lastActive
}

const getMessage = (state: State): MessageModels.MessageState => {
  const clone = deepClone(state.message)
  return { ...clone, action: state?.message?.action || null }
}

const getPatientSectionMeta = (state: State): WizardStateModels.WizardPatientSectionFields => {
  return deepClone(state.fields.patientSection)
}

const getPrescriberSectionMeta = (state: State): WizardStateModels.WizardPrescriberFields => {
  return deepClone(state.fields.prescriber)
}

const getSubmissionId = (state: State): string | null => {
  return state.submissionId
}

const getMonitoringInterval = (state: State): number | null => {
  return state.monitoringInterval
}

const getProviders = (state: State): WizardStateModels.Provider[] => {
  return state.providers
}

const getProvidersLoading = (state: State): boolean => {
  return state.providersLoading
}

const getPharmacyMeta = (state: State): WizardStateModels.WizardPharmacyFields => {
  return deepClone(state.fields.pharmacy)
}

const getMedicationPrescribedMeta = (
  state: State
): WizardStateModels.WizardMedicationPrescribedFields => {
  return deepClone(state.fields.medicationPrescribed)
}

const getCurrentQuestionMeta = (state: State): WizardStateModels.WizardQuestionMeta => {
  return state.currentQuestion
}

const getQualityUnitsOfMeasure = (state: State): WizardStateModels.SelectOption[] => {
  return state.qualityUnitsOfMeasure
}

const getQualityUnitsOfMeasureLoading = (state: State): boolean => {
  return state.qualityUnitsOfMeasureLoading
}

const getReVerify = (state: State): boolean => {
  return state.reVerifyNeeded
}

const getTransactions = (state: State): string[] => {
  return state.transactions
}

const isAuthorized = (state: State): boolean => {
  return state.authorization.isAuthorized
}

const isCancellationLoading = (state: State): boolean => {
  return state.isCancellationLoading
}

const isDebugMode = (state: State): boolean => {
  return state.debugMode
}

const isPageLoading = (state: State): boolean => {
  return state.pageLoading
}

const isDetailsSectionValid = (state: State): boolean => {
  const metaFields = {
    detailsSection: { ...state.fields.detailsSection },
  }
  return checkMetaIsValid(metaFields, state.debugMode)
}

const isPatientSectionValid = (state: State): boolean => {
  const metaFields = {
    patientSection: { ...state.fields.patientSection },
  }
  return checkMetaIsValid(metaFields, state.debugMode)
}

const isPrescriberSectionValid = (state: State): boolean => {
  const transactions = state.transactions
  const isPriorAuthTransactionEnabled =
    transactions.includes(TransactionOptions.PRIOR_AUTH) || !transactions.length
  const isBenefitsTransactionEnabled = transactions.includes(
    TransactionOptions.ELIGIBILITY_AND_BENEFITS
  )

  const metaFields = {
    prescriber: { ...state.fields.prescriber },
  }

  if (isBenefitsTransactionEnabled && !isPriorAuthTransactionEnabled) {
    if (state.fields.prescriber?.firstName?.valid && state.fields.prescriber?.lastName?.valid) {
      metaFields.prescriber.organizationName = { valid: true }
    } else if (state.fields?.prescriber?.organizationName?.valid) {
      metaFields.prescriber.firstName = { valid: true }
      metaFields.prescriber.lastName = { valid: true }
    }
  } else {
    if (state.fields.prescriber?.firstName?.valid && state.fields.prescriber?.lastName?.valid) {
      metaFields.prescriber.organizationName = { valid: true }
    }
  }

  return checkMetaIsValid(metaFields, state.debugMode)
}

const isPharmacySectionValid = (state: State): boolean => {
  const transactions = state.transactions
  const isPriorAuthTransactionEnabled =
    transactions.includes(TransactionOptions.PRIOR_AUTH) || !transactions.length
  const isBenefitsTransactionEnabled = transactions.includes(
    TransactionOptions.ELIGIBILITY_AND_BENEFITS
  )

  const metaFields: any = {
    pharmacy: { ...state.fields.pharmacy },
  }

  if (isPriorAuthTransactionEnabled && !isBenefitsTransactionEnabled) {
    metaFields.pharmacy = { valid: true }
  }

  return checkMetaIsValid(metaFields, state.debugMode)
}

const isMedicationPrescribedSectionValid = (state: State): boolean => {
  const transactions = state.transactions
  const isPriorAuthTransactionEnabled =
    transactions.includes(TransactionOptions.PRIOR_AUTH) || !transactions.length
  const isBenefitsTransactionEnabled = transactions.includes(
    TransactionOptions.ELIGIBILITY_AND_BENEFITS
  )

  const metaFields: any = {
    medicationPrescribed: { ...state.fields.medicationPrescribed },
  }

  if (isPriorAuthTransactionEnabled && !isBenefitsTransactionEnabled) {
    metaFields.medicationPrescribed.substitutions = { valid: true }
  }

  if (isBenefitsTransactionEnabled && !isPriorAuthTransactionEnabled) {
    metaFields.medicationPrescribed = {
      ...state.fields.medicationPrescribed,
      product: { ...state.fields.medicationPrescribed.product },
      diagnosis: {
        clinicalInformationQualifier: { valid: true },
        primary: { valid: true },
      },
    }
    metaFields.medicationPrescribed.quantity = {
      ...state.fields.medicationPrescribed.quantity,
      codeListQualifier: { valid: true },
    }
  }

  return checkMetaIsValid(metaFields, state.debugMode)
}

const isCancellationValid = (state: State): boolean => {
  const metaFields = {
    ...state.cancellationMeta,
  }
  return checkMetaIsValid(metaFields, state.debugMode)
}

const isReviewSectionVisited = (state: State): boolean => {
  return state.reviewSectionVisited
}

export default {
  getAllFieldsMeta,
  getActiveFeatures,
  getAuthorization,
  getAuthExpiration,
  getCancellationSectionMeta,
  getCSRFToken,
  getCurrentQuestionMeta,
  getDetailsSectionMeta,
  getError,
  getIsLinksLoading,
  getLastActive,
  getMessage,
  getMedicationPrescribedMeta,
  getMonitoringInterval,
  getPatientSectionMeta,
  getPharmacyMeta,
  getPrescriberSectionMeta,
  getProviders,
  getProvidersLoading,
  getReVerify,
  getSubmissionId,
  getToken,
  getTransactions,
  getQualityUnitsOfMeasure,
  getQualityUnitsOfMeasureLoading,
  getUser,
  isAuthorized,
  isCancellationValid,
  isCancellationLoading,
  isDebugMode,
  isPageLoading,
  isMedicationPrescribedSectionValid,
  isPatientSectionValid,
  isPharmacySectionValid,
  isPrescriberSectionValid,
  isReviewSectionVisited,
  isDetailsSectionValid,
}
