<template>
  <div class="container">
    <div v-show="imageFinished" class="login-container">
      <div class="login-inner-container ms-card">
        <div class="login-options">
          <div>
            <img
              :src="settings?.banner?.imageUrl"
              :alt="`${settings?.banner?.title} Logo`"
              class="logo"
              @load="onImageFinished"
              @error="onImageFinished"
            />
            <form @submit.prevent="onSubmit" class="form">
              <p class="ms-label-text">Email</p>
              <div class="field">
                <input
                  type="email"
                  placeholder="example@website.com"
                  v-model="email"
                  autocomplete="off"
                  class="pad-left wl-ms-input"
                />
                <span class="ms-input-icon">
                  <fa-icon icon="envelope"></fa-icon>
                </span>
              </div>
              <p class="ms-label-text">Password</p>
              <div class="field">
                <input
                  type="password"
                  placeholder="min. 8 characters"
                  v-model="password"
                  autocomplete="off"
                  class="pad-left wl-ms-input"
                />
                <span class="ms-input-icon">
                  <fa-icon icon="lock"></fa-icon>
                </span>
              </div>
              <a
                class="wl-link align-right forgot-password"
                :href="passwordResetUrl"
                target="_blank"
                >Forgot password?</a
              >
              <button type="submit" class="login-button margin-tb" :disabled="disabled">
                Login
              </button>
              <p class="align-center ms-label-text">
                Not registered yet?
                <a class="wl-link" :href="createAccountUrl" target="_blank">Create an Account</a>
              </p>
            </form>
            <div
              v-for="provider in signInOptions"
              class="login-option-button"
              :class="provider.type"
              :key="provider.type + provider.id"
              @click="signIn(provider.type, provider.id)"
            >
              <input
                type="image"
                class="google-sign-in"
                v-if="provider.type === 'google'"
                src="/assets/btn_google_signin_light_normal_web@2x.png"
              />
              <button
                v-if="provider.type !== 'google' && provider.type !== 'email'"
                class="login-button"
              >
                Sign in with {{ provider.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useFirebaseAuth } from '@/modules/firebase'
import { doNothing, getMyndviewUrl } from '@/utils'
import { Router, useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'Login',
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters['config/getSettings'])
    const authConfig = computed(() => store.getters['config/getAuthConfig'])
    const email = ref('')
    const password = ref('')
    const disabled = ref(true)
    const imageFinished = ref(false)
    const auth = ref(useFirebaseAuth(window.location.hostname, authConfig.value))

    const router: Router = useRouter()
    const route: any = useRoute()

    const createAccountUrl = `${getMyndviewUrl(document.location.origin)}/register?source=rxs`
    const passwordResetUrl = `${getMyndviewUrl(
      document.location.origin
    )}/forgot-password?source=rxs`

    const routeToLanding = () => {
      const query = route?.query || null
      router.push({ path: `/landing`, query }).catch(doNothing).finally()
    }

    const onImageFinished = () => (imageFinished.value = true)

    const getTokenAndLogin = async (credentials: any) => {
      const idToken = await credentials.user.getIdToken()
      const authorized = await store.dispatch('wizard/login', idToken)

      if (authorized) {
        routeToLanding()
      }
    }

    const onSubmit = async () => {
      const credentials = await auth.value.signIn('email', undefined, email.value, password.value)
      await getTokenAndLogin(credentials)
    }

    const signIn = async (type: string, id: string) => {
      const credentials = await auth.value.signIn(type, id)
      await getTokenAndLogin(credentials)
    }

    watch([email, password], ([email, password]) => {
      disabled.value = !(email && password)
    })

    watch(authConfig, config => {
      if (config?.projectId) {
        auth.value = useFirebaseAuth(window.location.hostname, config)
      }
    })

    return {
      createAccountUrl,
      disabled,
      email,
      password,
      passwordResetUrl,
      providerName: auth.value.providerName,
      signInOptions: auth.value.signInOptions,
      settings,
      imageFinished,

      onImageFinished,
      onSubmit,
      signIn,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.container {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.login-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-inner-container {
    width: 25rem;
    min-height: 26.25rem;

    .logo {
      margin-bottom: 1rem;
      min-width: 18.75rem;
      height: 3.5rem;
    }
  }
}

.login-options,
form {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: stretch;
  justify-content: center;
}

.field {
  position: relative;
  margin-bottom: 0.75rem;
}

.pad-left {
  padding-left: 2.5rem;
}

.margin-tb {
  margin: 0.75rem 0;
}

.login-button {
  @include wl-button-mixin($width: 18.75rem, $primary: var(--primary-color));
}

.link {
  min-height: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;

  &-align-end {
    align-self: flex-end;
  }

  &-align-center {
    align-self: center;
    margin-top: 0.75rem;
  }
}

.google-sign-in {
  width: 80%;
}

.wl-ms-input:focus-visible {
  outline: none;
  box-shadow: 0 0 2px 0.02em var(--primary-color);
}

.align {
  &-right {
    align-self: flex-end;
  }
  &-center {
    align-self: center;
  }
}

.forgot-password {
  min-height: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

#error > * {
  margin: 10px 0 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  color: $myndshft-required-pink;
}

.login-option-button {
  margin: 10px 0 5px;
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
