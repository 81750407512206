<template>
  <div class="wizard-container">
    <div class="wizard">
      <banner :userName="userName" :logout="logout" :settings="settings.banner"></banner>
      <div class="wizard-body">
        <nav>
          <wizard-menu
            :transactions="transactions"
            :valid-sections="validSections"
            :review-visited="reviewVisited"
          ></wizard-menu>
        </nav>
        <main>
          <router-view></router-view>
          <footer>
            <section-navigation
              :nextSectionName="nextSectionDetails?.name"
              :nextHidden="nextSectionDetails?.hidden"
              :nextDisabled="nextSectionDetails?.disabled"
              :previousSectionName="previousSectionDetails?.name"
              :previousHidden="previousSectionDetails?.hidden"
              :previousDisabled="previousSectionDetails?.disabled"
            ></section-navigation>
          </footer>
        </main>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted, watch } from 'vue'
import { RouteLocationNormalizedLoaded, Router, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { WizardMenu } from './menu'
import { Banner, SectionNavigation } from '../shared'
import { doNothing } from '@/utils'
import { UserModels } from '@/models/user'
import Features from '@/services/features'
import { TransactionOptions } from '@/models/wizard/transactions'

type SectionDetails = { name?: string; hidden?: boolean; disabled?: boolean } | null

export default defineComponent({
  name: 'Wizard',
  components: { Banner, SectionNavigation, WizardMenu },
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters['config/getSettings'])
    const router: Router = useRouter()
    const routeToError = () => router.push({ name: 'Error' }).catch(doNothing).finally()
    const routeToLanding = () => {
      router.push({ name: 'Landing' }).catch(doNothing).finally()
    }
    const transactions = computed(() => store.getters['wizard/getTransactions'])

    const isDetailsSectionValid = computed(() => store.getters['wizard/isDetailsSectionValid'])
    const isPatientSectionValid = computed(() => store.getters['wizard/isPatientSectionValid'])
    const isPrescriberSectionValid = computed(
      () => store.getters['wizard/isPrescriberSectionValid']
    )
    const isMedicationPrescribedSectionValid = computed(
      () => store.getters['wizard/isMedicationPrescribedSectionValid']
    )
    const isPharmacySectionValid = computed(() => store.getters['wizard/isPharmacySectionValid'])

    // If the section names are updated this will need to be updated to reflect that
    const validSections = computed(() => {
      return {
        details: isDetailsSectionValid.value,
        patient: isPatientSectionValid.value,
        prescriber: isPrescriberSectionValid.value,
        'drug-and-diagnosis': isMedicationPrescribedSectionValid.value,
        pharmacy: isPharmacySectionValid.value,
        review: true,
      }
    })
    const reviewVisited = computed(() => store.getters['wizard/isReviewSectionVisited'])

    const user = computed(() => store.getters['wizard/getUser'] as UserModels.UserState)
    const userName = computed(() => {
      if (user.value?.firstName && user.value?.lastName) {
        return `${user.value?.firstName} ${user.value?.lastName}`
      } else if (user.value?.email) {
        return user.value?.email
      }
      return ''
    })
    const nextSectionDetails = ref<SectionDetails>(null)
    const previousSectionDetails = ref<SectionDetails>(null)

    const logout = () => {
      Features.getFeatures().then(flags => {
        if (flags.includes('isEnabledToUseTheSessionCookieToFetchTheToken')) {
          if (
            window.confirm(
              'Are you sure you want to log out? This will log you out of all active sessions.'
            )
          ) {
            store.commit('submission/clearSubmission')
            store
              .dispatch('wizard/logout')
              .then((success: boolean) => {
                if (success) {
                  location.reload()
                }
              })
              .catch(routeToError)
          }
        } else {
          if (window.confirm('Are you sure you want to log out?')) {
            store.commit('submission/clearSubmission')
            store
              .dispatch('wizard/logout')
              .then(() => {
                store.commit('wizard/setPageLoading', false)
                routeToLanding()
              })
              .catch(routeToError)
          }
        }
      })
    }

    const setSectionDetails = (currentRoute: RouteLocationNormalizedLoaded) => {
      const allRoutes = router.getRoutes()
      const questionSetRouteName =
        allRoutes.find(route => route.path === '/submission/questionnaire')?.name || null
      const currentSectionName = currentRoute?.name
      const routes =
        currentSectionName !== questionSetRouteName
          ? router.getRoutes()?.filter(route => {
              const routeNotDisabled = !route.meta.disabled
              const routeNotHidden =
                !route.meta.hidden ||
                (transactions.value?.includes(TransactionOptions.ELIGIBILITY_AND_BENEFITS) &&
                  route.path.includes('pharmacy'))
              return routeNotDisabled && routeNotHidden
            }) || []
          : []
      const currentSectionIndex = routes.findIndex(route => route.name === currentSectionName)

      if (routes.length === 1 || currentSectionIndex === -1) {
        previousSectionDetails.value = { hidden: true }
        nextSectionDetails.value = { hidden: true }
      } else if (currentSectionIndex === 0) {
        previousSectionDetails.value = { hidden: true }
        const nextSectionIndex =
          currentSectionIndex + 1 < routes.length ? currentSectionIndex + 1 : null
        nextSectionDetails.value = nextSectionIndex
          ? { name: routes[nextSectionIndex].name as string, hidden: false, disabled: false }
          : { hidden: true }
      } else if (currentSectionIndex === routes.length - 1) {
        nextSectionDetails.value = { hidden: true }
        const previousSectionIndex = currentSectionIndex - 1 > -1 ? currentSectionIndex - 1 : null
        previousSectionDetails.value = previousSectionIndex
          ? { name: routes[previousSectionIndex].name as string, hidden: false, disabled: false }
          : { hidden: true }
      } else {
        nextSectionDetails.value = {
          name: routes[currentSectionIndex + 1].name as string,
          hidden: false,
          disabled: false,
        }
        previousSectionDetails.value = {
          name: routes[currentSectionIndex - 1].name as string,
          hidden: false,
          disabled: false,
        }
      }
      window.scrollTo({ top: -(window.pageYOffset || window.scrollY), behavior: 'smooth' })
    }

    watch(
      router.currentRoute,
      (currentRoute: RouteLocationNormalizedLoaded) => setSectionDetails(currentRoute),
      { immediate: true }
    )

    onMounted(() => {
      store.commit('wizard/setPageLoading', true)
    })

    return {
      settings,
      userName,
      nextSectionDetails,
      previousSectionDetails,
      transactions,
      reviewVisited,
      validSections,

      logout,
      routeToError,
    }
  },
})
</script>

<style lang="scss">
main {
  padding-top: 8px;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1100px;
  width: 100%;

  &:after {
    content: '';
    display: block;
    height: 32px;
  }
}

nav {
  padding-top: 8px;
  padding-left: 2px;
  width: 100%;
}

.wizard-container {
  display: flex;
  justify-content: center;
}

.wizard {
  display: flex;
  flex-direction: column;
  max-width: 1344px;
}

.wizard-body {
  display: grid;
  grid-template-columns: 212px auto;
}

.footer {
  width: 100%;
}
</style>
