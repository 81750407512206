import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-836a0694"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "data-required"]
const _hoisted_3 = ["id", "value", "data-error", "data-required", "disabled", "placeholder", "data-private"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'number-container-' + _ctx.elementKey,
    class: "number-input-container"
  }, [
    _createElementVNode("label", {
      id: 'number-container-label-' + _ctx.elementKey,
      class: "form-field-label",
      "data-required": _ctx.required
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createElementVNode("input", {
      type: "text",
      class: "form-field",
      autocomplete: "off",
      id: 'number-container-input-' + _ctx.elementKey,
      value: _ctx.inputValue,
      "data-error": _ctx.invalid,
      "data-required": _ctx.dataRequired,
      disabled: _ctx.disabled,
      placeholder: _ctx.disabled ? '' : _ctx.placeholder,
      "data-private": _ctx.dataPrivate,
      onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args)), ["prevent"]), ["enter"])),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args)))
    }, null, 40, _hoisted_3),
    (_ctx.invalid && _ctx.invalidHintText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            id: 'number-container-input-invalid-hint-' + _ctx.elementKey,
            class: "invalid-hint"
          }, " *" + _toDisplayString(_ctx.invalidHintText), 9, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}