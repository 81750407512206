<template>
  <div>
    <message
      :banner-title="wizardError?.bannerTitle"
      :banner-url="wizardError?.bannerUrl"
      :message-title="wizardError?.messageTitle"
      :message-line-one="wizardError?.messageLineOne"
      :message-line-two="wizardError?.messageLineTwo"
      :message-line-three="wizardError?.messageLineThree"
      :button-label="wizardError?.buttonLabel"
      :action="wizardError?.action"
    ></message>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { Message } from './shared'
import { useStore } from 'vuex'
import { MessageModels } from '@/models/message'

export default defineComponent({
  name: 'Error',
  components: {
    Message,
  },
  setup() {
    const store = useStore()
    const wizardError = computed<MessageModels.MessageState>(() => store.getters['wizard/getError'])
    return {
      wizardError,
    }
  },
})
</script>

<style lang="scss"></style>
