/**********************
 * LogRocket usage
 *
 * import { logRocket } from '@/modules'
 * logRocket.init(settings)
 * logRocket.identify(email, userId)
 * logRocket.track(eventName, { eventDetails })
 ***********************/

import LogRocket from 'logrocket'

import WhiteListBody from '@myndshft/whitelist'
import { IConfig } from '@myndshft/whitelist/lib/types'

import { EnvironmentSettings } from '@/models/environment-settings'
import { UserModels } from '@/models/user'

export function init(settings: EnvironmentSettings) {
  const canInit = settings.environment !== 'DEVELOPMENT'

  if (canInit) {
    const config: IConfig = {
      request: [],
      response: [],
    }
    const { requestSanitizer, responseSanitizer } = WhiteListBody.init(config)

    LogRocket.init('ta8rj2/rx-submit', {
      network: {
        requestSanitizer,
        responseSanitizer,
      },
      dom: {
        inputSanitizer: true,
      },
      release: settings.version,
    })
  }
}

export function identify(user: UserModels.UserState) {
  const email = user.email || ''
  const name = user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : ''

  LogRocket.identify(user.email, {
    email,
    name,
  })
}

export function track(eventName: string, eventDetails: any = {}) {
  const event = { ...eventDetails }

  LogRocket.track(eventName, event)
}
