<template>
  <div :id="'text-container-' + elementKey" class="text-input-container">
    <label
      :id="'text-container-label-' + elementKey"
      class="form-field-label"
      :data-required="required"
    >
      {{ label }}
    </label>
    <input
      :id="'text-container-input-' + elementKey"
      type="text"
      class="form-field"
      autocomplete="off"
      @keydown.enter.prevent="inputHandler"
      @input="inputHandler"
      :value="displayValue"
      :data-error="invalid"
      :data-required="dataRequired"
      :disabled="disabled"
      :placeholder="disabled ? '' : placeholder"
      :data-public="!!dataPublic || undefined"
    />
  </div>
  <div v-if="invalid && invalidHintText">
    <div :id="'text-container-input-invalid-hint-' + elementKey" class="invalid-hint">
      *{{ invalidHintText }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onBeforeMount, computed } from 'vue'
import { v4 as uuid } from 'uuid'

export default defineComponent({
  name: 'TextInput',
  emits: ['on-input', 'on-validate'],
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidHintText: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    validation: Function,
    reduceValue: {
      type: Function,
      default: (value: any) => value,
    },
    formatInput: {
      type: Function,
      default: (value: any) => value,
    },
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const inputValue = ref<string | null>(null)
    const displayValue = ref<string | null>(null)
    const dataRequired = computed(() => props.required && !displayValue.value)

    const validate = (value: any): boolean => {
      if (!props.required) {
        return props.validation ? props.validation(value) : !props.validation
      }

      return !!value && !!(props.validation ? props.validation(value) : !props.validation)
    }

    const inputHandler = (event: any): void => {
      if (!props.disabled) {
        if (event?.target?.value) {
          displayValue.value = props.formatInput(event?.target?.value)
        } else {
          displayValue.value = null
        }
      }
    }

    watch(displayValue, value => {
      inputValue.value = props.reduceValue(value)
    })

    watch(inputValue, value => {
      context.emit('on-input', value)
      context.emit('on-validate', validate(value))
    })

    onBeforeMount(() => {
      if (props.value) {
        displayValue.value = props.value
        inputValue.value = props.reduceValue(props.value)
      }
    })

    return {
      dataRequired,
      displayValue,
      inputValue,

      inputHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.text-input-container {
  position: relative;
}

.form-field {
  width: 100%;
  padding-right: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &focus {
    outline: none;
  }
}

.invalid-hint {
  padding: 2px;
  color: #f14668;
  font-size: 12px;
  line-height: 14px;
}
</style>
