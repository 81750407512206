import { State } from './state'
import SubmissionService from '@/services/submission'
import { Icon, notify } from '@/modules/notification'

const initialSubmission = async ({ rootState, state }: { rootState: any; state: State }) => {
  const result = await SubmissionService.postInitialSubmission(
    { submission: state.submission, npi: state.npi },
    rootState.authToken,
    rootState.csrfToken
  ).catch(error => {
    notify(error.message, 'Oops Something happened!', Icon.ERROR)
  })

  return result?.id
}
export default { initialSubmission }
