import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b33d5fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "data-public"]
const _hoisted_2 = {
  key: 0,
  class: "file-upload-error-message"
}
const _hoisted_3 = ["id", "data-required"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "loading-spinner"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["id", "disabled"]
const _hoisted_9 = {
  class: "file-name",
  "data-private": ""
}
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'attachment-container-' + _ctx.elementKey,
    class: "attachment-container",
    "data-public": _ctx.dataPublic
  }, [
    (_ctx.fileErrorMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.fileErrorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("label", {
      id: 'attachment-label-' + _ctx.elementKey,
      class: "form-field-label",
      "data-required": _ctx.required
    }, _toDisplayString(_ctx.label), 9, _hoisted_3),
    _createElementVNode("div", {
      id: 'attachment-input-container-' + _ctx.elementKey,
      class: "attachment-input-container",
      "data-private": ""
    }, [
      _createElementVNode("label", {
        class: "attachment-input-label wl-button outlined",
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openFileSelect && _ctx.openFileSelect(...args)))
      }, [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.inputValue?.name ? 'Choose Different File' : 'Choose File'), 1))
      ], 8, _hoisted_5),
      _createElementVNode("input", {
        id: 'attachment-input-' + _ctx.elementKey,
        ref: "fileRef",
        class: "attachment-input-hidden",
        type: "file",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearCurrentFile && _ctx.clearCurrentFile(...args))),
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.fileChangeHandler && _ctx.fileChangeHandler(...args))),
        disabled: _ctx.disabled,
        accept: "application/ti",
        "data-private": ""
      }, null, 40, _hoisted_8),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.inputValue?.name && !_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.inputValue?.name), 1))
          : _createCommentVNode("", true)
      ])
    ], 8, _hoisted_4)
  ], 8, _hoisted_1))
}