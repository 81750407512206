import { QualifierModels } from '../../models/submission'

const clinicalInformationQualifiersOptions = [
  {
    label: '1 - Prescriber/Prescriber Supplied',
    value: '1' as QualifierModels.ClinicalInformationQualifierStrings,
  },
  {
    label: '2 - Pharmacy Inferred',
    value: '2' as QualifierModels.ClinicalInformationQualifierStrings,
  },
]

export { clinicalInformationQualifiersOptions }
