import { State } from './state'
import { CancellationModels as StateModels } from '@/models/store'
import { CancellationModels as TypeModels } from '@/models/cancellation'

const setCancellation = (state: State, value: StateModels.CancellationState) => {
  state.case = value?.case ?? null
  state.reason = value?.reason ?? null
  state.patientHasBeenNotified = value?.patientHasBeenNotified ?? null
  state.additionalNotes = value?.additionalNotes ?? null
}

const setCase = (state: State, value: string) => {
  state.case = value ?? null
}

const setReason = (state: State, value: TypeModels.CancelReason) => {
  state.reason = value ?? null
}

const setPatientHasBeenNotified = (state: State, value: boolean) => {
  state.patientHasBeenNotified = value ?? null
}

const setAdditionalNotes = (state: State, value: string) => {
  state.additionalNotes = value ?? null
}

export default {
  setAdditionalNotes,
  setCancellation,
  setCase,
  setPatientHasBeenNotified,
  setReason,
}
