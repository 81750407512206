export const getBffRoute = () => {
  return window.location.origin.includes('localhost')
    ? `${window.location.origin}`.replace(':8080', ':3300')
    : window.location.origin
}

export const getMyndviewUrl = (url: string) => {
  if (url.includes('myndview.app')) {
    return url.replace('rx-submit.', '')
  } else if (url.includes('rx-submit')) {
    return url.replace('rx-submit', 'myndview')
  } else if (url.includes('localhost')) {
    return url.replace(':8080', ':4200')
  }
  return url
}

export const isLocal = () => {
  const url = document.location.origin
  return url.includes('localhost')
}

export const getHeaderFromResponse = (header: string, response: any): string | null => {
  return response?.headers?.[header] || null
}
