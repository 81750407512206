import { CancellationModels } from '../../models/cancellation'

const cancelOptions = [
  {
    label: 'Change of medication',
    value: CancellationModels.CancelReason.CHANGE_OF_MEDICATION,
  },
  {
    label: 'No longer under care',
    value: CancellationModels.CancelReason.NO_LONGER_UNDER_CARE,
  },
  {
    label: 'Other',
    value: CancellationModels.CancelReason.OTHER,
  },
]

export { cancelOptions }
