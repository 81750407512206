import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-718a0359"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "details-group" }
const _hoisted_2 = { class: "transactions" }
const _hoisted_3 = { class: "provider" }
const _hoisted_4 = { class: "priority" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_form_group_section = _resolveComponent("form-group-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_group_section, {
      "element-key": 'details',
      "section-label": 'Details'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_radio_button, {
            "element-key": 'transactions-selections',
            label: 'Select Transactions to Perform',
            options: _ctx.transactionOptions,
            required: true,
            "multi-select": true,
            value: _ctx.transactionsSelected,
            "data-public": true,
            onOnSelection: _ctx.transactionsUpdate
          }, null, 8, ["options", "value", "onOnSelection"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_select_input, {
            "element-key": 'details-provider',
            label: 'Provider',
            options: _ctx.providerOptions,
            "option-label-key": 'name',
            "option-value-key": 'npi',
            placeholder: 'Select Provider',
            value: _ctx.detailsValues?.npi,
            required: !!_ctx.detailsMeta?.provider?.required,
            invalid: _ctx.detailsMeta?.provider?.dirty && !_ctx.detailsMeta?.provider?.valid,
            "data-public": true,
            loading: _ctx.providersLoading,
            onOnSelection: _ctx.providerUpdate
          }, null, 8, ["options", "value", "required", "invalid", "loading", "onOnSelection"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_radio_button, {
            "element-key": 'details-priority',
            label: 'Urgency',
            options: _ctx.priorityOptions,
            required: !!_ctx.detailsMeta?.priority?.required,
            value: _ctx.detailsValues?.priority,
            "data-public": true,
            onOnSelection: _ctx.priorityUpdate
          }, null, 8, ["options", "required", "value", "onOnSelection"])
        ])
      ]),
      _: 1
    })
  ]))
}