import { State } from './state'
import { SummaryStateModels as StoreModels } from '@/models/store'

const getSummary = (state: State): StoreModels.Summary => {
  return state
}

const getSummarySubmission = (state: State): StoreModels.SummarySubmission | null => {
  return state.submission
}

const getSummaryStatus = (state: State): StoreModels.SummaryStatus | null => {
  return state.status
}

export default {
  getSummary,
  getSummarySubmission,
  getSummaryStatus,
}
