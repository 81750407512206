import { State } from './state'
import { Commit } from 'vuex'
import QuestionsService from '@/services/questions'
import { Icon, notify } from '@/modules/notification'

const ERROR_MESSAGE_TITLE = 'Oops! Something happened!'

const getQuestion = async ({
  rootState,
  state,
  commit,
}: {
  rootState: any
  state: State
  commit: Commit
}) => {
  if (rootState.submissionId) {
    commit('setCheckingForNextQuestion', state.isFirstQuestion)
    const response = await QuestionsService.getQuestion(
      rootState.submissionId,
      rootState.authToken,
      rootState.csrfToken
    ).catch((error: any) => {
      commit('setCurrentQuestionInit', null)
      commit('setCheckingForNextQuestion', false)
      commit('setError', error.message)

      notify(
        'An error occurred retrieving the question. Please try again later.',
        ERROR_MESSAGE_TITLE,
        Icon.ERROR
      )

      throw error
    })

    if (response?.question?.id) {
      commit('setIsFirstQuestion', false)
      commit('setCurrentQuestionInit', response?.question)
    } else {
      commit('setCurrentQuestionInit', null)
    }

    commit('incrementQuestionNumber')
    commit('setCheckingForNextQuestion', false)
    return true
  } else {
    return false
  }
}

const sendAnswer = async ({
  rootState,
  state,
  commit,
}: {
  rootState: any
  state: State
  commit: Commit
}) => {
  if (rootState.submissionId && state.currentQuestion?.id) {
    const response: any = await QuestionsService.sendAnswer(
      rootState.submissionId,
      state.currentQuestion.id,
      state.currentQuestion,
      rootState.authToken,
      rootState.csrfToken
    ).catch((error: any) => {
      commit('setError', error.message)

      notify(error.message, ERROR_MESSAGE_TITLE, Icon.ERROR)

      return { error: true }
    })

    if (response?.status === 200) {
      commit('setError', null)
    } else if (response.error) {
      return false
    } else {
      commit('setCurrentQuestionInit', null)
    }
    return true
  } else {
    return false
  }
}

const uploadAttachment = async (
  {
    rootState,
    state,
    commit,
  }: {
    rootState: any
    state: State
    commit: Commit
  },
  attachment: any
) => {
  if (rootState.submissionId && state.currentQuestion?.id) {
    const id = await QuestionsService.uploadAttachment(
      rootState.submissionId,
      rootState.authToken,
      rootState.csrfToken,
      attachment.data as FormData,
      state.currentQuestion?.id || null
    ).catch((error: any) => {
      notify(error.message, ERROR_MESSAGE_TITLE, Icon.ERROR)

      throw error
    })

    commit('setQuestionAttachmentId', id)
  }
}

const downloadAttachment = async (
  {
    rootState,
  }: {
    rootState: any
  },
  attachmentId: string
) => {
  if (rootState.submissionId) {
    await QuestionsService.downloadAttachment(
      rootState.submissionId,
      rootState.authToken,
      rootState.csrfToken,
      attachmentId
    ).catch((error: any) => {
      notify(error.message, ERROR_MESSAGE_TITLE, Icon.ERROR)

      throw error
    })
  }
}

export default { downloadAttachment, getQuestion, sendAnswer, uploadAttachment }
