import { State } from './state'

import { EnvironmentSettings } from '@/models/environment-settings'
import { SettingStateModels, ConfigStateModels } from '@/models/store'

const getChatbotKey = (state: State): string => {
  return state.chatbotKey || ''
}

const getEnvironmentSettings = (state: State): EnvironmentSettings => {
  return { environment: state.environment, version: state.version }
}

const getExpirationConfig = (state: State): ConfigStateModels.ExpirationConfig => {
  return state.expirationConfig
}

const getSettings = (state: State): SettingStateModels.Settings => {
  return { ...state.settings } as SettingStateModels.Settings
}

const getTermsUrl = (state: State): string | null => {
  return state.termsUrl || null
}

const getVersion = (state: State): string | null => {
  return state.version || null
}

const getAuthConfig = (state: State): ConfigStateModels.TenantAuth => {
  return state.tenantAuth
}

export default {
  getAuthConfig,
  getChatbotKey,
  getEnvironmentSettings,
  getExpirationConfig,
  getSettings,
  getTermsUrl,
  getVersion,
}
