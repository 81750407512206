import { QualifierModels } from '../../models/submission'

const diagnosisCodeQualifierOptions = [
  {
    label: 'ABF -  International Classification of Diseases-10',
    value: QualifierModels.DiagnosisQualifier[QualifierModels.DiagnosisQualifier.ABF],
  },
  {
    label: 'DX - International Classification of Diseases-9',
    value: QualifierModels.DiagnosisQualifier[QualifierModels.DiagnosisQualifier.DX],
  },
  {
    label: 'LD - SNOMED',
    value: QualifierModels.DiagnosisQualifier[QualifierModels.DiagnosisQualifier.LD],
  },
]

export { diagnosisCodeQualifierOptions }
