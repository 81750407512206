<template>
  <div>
    <div
      v-if="
        isPageLoading ||
        !(message?.messageLineOne || message?.messageLineTwo || message?.messageLineThree)
      "
      class="loading-background-full"
    >
      <div class="loading-spinner"></div>
    </div>
    <div
      v-else-if="message?.messageLineOne || message?.messageLineTwo || message?.messageLineThree"
    >
      <message
        :banner-title="message?.bannerTitle"
        :banner-url="message?.bannerUrl"
        :message-title="message?.messageTitle"
        :message-line-one="message?.messageLineOne"
        :message-line-two="message?.messageLineTwo"
        :message-line-three="message?.messageLineThree"
        :button-label="message?.buttonLabel"
        :action="message?.action"
      ></message>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { Router, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Message } from './shared'
import { doNothing } from '@/utils'
import Features from '@/services/features'

export default defineComponent({
  name: 'Landing',
  components: { Message },
  setup() {
    const store = useStore()
    const isPageLoading = computed(() => store.getters['wizard/isPageLoading'])
    const isAuthorized = computed(() => store.getters['wizard/isAuthorized'])
    const message = computed(() => store.getters['wizard/getMessage'])

    const router: Router = useRouter()
    const route: any = useRoute()
    const routeToDestination = () => {
      const query = route?.query || null
      const reroute = `${query?.reRoute || ''}` || null
      router
        .push({ path: `/submission/${reroute || 'details'}`, query })
        .catch(doNothing)
        .finally()
    }

    const routeToLogin = () => {
      const query = route?.query || null
      router.push({ name: 'Login', query }).catch(doNothing).finally()
    }

    watch(
      [isAuthorized, isPageLoading],
      async ([authorized, loading]) => {
        const features = await Features.getFeatures()

        if (authorized) {
          routeToDestination()
        } else if (!loading && features.includes('isEnabledToUseTheSessionCookieToFetchTheToken')) {
          routeToLogin()
        }
      },
      { immediate: true }
    )

    return {
      isPageLoading,
      message,
    }
  },
})
</script>

<style lang="scss">
.placeholder {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;
  align-items: center;
}
</style>
