import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3ad0de4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["color"]
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'inner-fieldset-sub-container-' + _ctx.elementKey,
    class: "inner-container"
  }, [
    _createElementVNode("div", {
      id: 'inner-divider-' + _ctx.elementKey,
      class: "divider"
    }, [
      _createElementVNode("div", {
        color: _ctx.color,
        class: "divider-label"
      }, _toDisplayString(_ctx.sectionLabel), 9, _hoisted_3)
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      id: 'inner-sub-fields-container-' + _ctx.elementKey,
      class: "inner-fields"
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_4)
  ], 8, _hoisted_1))
}