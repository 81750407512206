import axios from 'axios'
import { getBffRoute } from '@/utils'

const PROVIDERS_PATH = '/api/providers'
const PROVIDER_PATH = '/api/provider'

const getUserProviders = async (token: string | null, csrf: string | null) => {
  const ERROR_MESSAGE = 'An error occurred fetching providers. Please try again later.'

  if (!token || !csrf) {
    throw Error(ERROR_MESSAGE)
  }
  const headers = { authorization: token, 'x-csrf-token': csrf }
  const response = await axios
    .get(`${getBffRoute()}${PROVIDERS_PATH}`, { headers })
    .catch(error => {
      console.error(error.message)

      throw Error(ERROR_MESSAGE)
    })
  return response?.data
}

const fetchPrescribingProvider = async (
  token: string | null,
  csrf: string | null,
  npi: string | null
) => {
  const ERROR_MESSAGE = 'An error occurred fetching the provider. Please try again later.'
  if (!token || !csrf || !npi) {
    throw Error(ERROR_MESSAGE)
  }

  const headers = { authorization: token, 'x-csrf-token': csrf }
  const params = { npi }
  const response = await axios
    .get(`${getBffRoute()}${PROVIDER_PATH}`, { headers, params })
    .catch(error => {
      console.error(error.message)

      throw Error(ERROR_MESSAGE)
    })

  return response?.data
}

export { fetchPrescribingProvider, getUserProviders }
