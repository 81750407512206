import axios from 'axios'
import { getBffRoute } from '@/utils'
import { track } from '@/modules/logrocket'

const INITIAL_SUBMIT_PATH = '/api/submit'
const ELIGIBILITY_SUBMIT_PATH = '/api/benefits'

const postInitialSubmission = async (submission: any, auth: string, csrf: string) => {
  if (submission) {
    const headers = { authorization: auth, 'x-csrf-token': csrf }
    const response = await axios
      .post(`${getBffRoute()}${INITIAL_SUBMIT_PATH}`, submission, {
        headers,
      })
      .catch(error => {
        track('PAErrorEvent', { error })

        throw Error(error.message)
      })

    const data = response?.data

    if (data && data.error) {
      track('PAWithErrorEvent', { error: data.error })
    } else {
      track('PASuccessEvent')
    }

    return data
  } else {
    track('PAInvalidEvent')

    throw Error('Submission is invalid.')
  }
}

const postEligibilitySubmission = async (
  submission: any,
  auth: string,
  csrf: string,
  clientNPI: string
) => {
  if (submission) {
    const headers = { authorization: auth, 'x-csrf-token': csrf }
    const response = await axios
      .post(
        `${getBffRoute()}${ELIGIBILITY_SUBMIT_PATH}`,
        { npi: clientNPI, submission },
        {
          headers,
        }
      )
      .catch(error => {
        track('EligibilityErrorEvent', { error })
        throw Error(error.message)
      })

    const data = response?.data

    if (data && data.error) {
      track('EligibilityWithErrorEvent', { error: data.error })
    } else {
      track('EligibilitySuccessEvent')
    }

    return data
  } else {
    track('EligibilityInvalidEvent')

    throw Error('Eligibility submission is invalid.')
  }
}

export { postInitialSubmission, postEligibilitySubmission }
