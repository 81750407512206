import { InputMeta } from '@/models/wizard'
import { MessageModels } from '@/models/message'
import { UserModels } from '@/models/user'
import { WizardStateModels } from '@/models/store'
import { getMyndviewUrl } from '@/utils'
import { QuestionType } from '@/models/questions/question'
import { TransactionOptions } from '@/models/wizard/transactions'

const defaultFieldMeta = (required: boolean, path: string): InputMeta => ({
  required,
  path,
  valid: !required,
  dirty: false,
})

const defaultAddressFields = (
  required: boolean,
  path: string
): WizardStateModels.WizardAddressFields => ({
  lineOne: defaultFieldMeta(required, path),
  lineTwo: defaultFieldMeta(false, path),
  city: defaultFieldMeta(required, path),
  state: defaultFieldMeta(required, path),
  zip: defaultFieldMeta(required, path),
})

const defaultDetailsSectionFields = (
  path: string
): WizardStateModels.WizardDetailsSectionFields => ({
  priority: defaultFieldMeta(false, path),
  provider: defaultFieldMeta(true, path),
})

const defaultPatientSectionFields = (
  path: string
): WizardStateModels.WizardPatientSectionFields => ({
  firstName: defaultFieldMeta(true, path),
  middleName: defaultFieldMeta(false, path),
  lastName: defaultFieldMeta(true, path),
  dob: defaultFieldMeta(true, path),
  gender: defaultFieldMeta(true, path),
  phone: defaultFieldMeta(true, path),
  address: defaultAddressFields(true, path),
})

const defaultPrescriberFields = (path: string): WizardStateModels.WizardPrescriberFields => ({
  firstName: defaultFieldMeta(true, path),
  lastName: defaultFieldMeta(true, path),
  organizationName: defaultFieldMeta(true, path),
  npi: defaultFieldMeta(true, path),
  fax: defaultFieldMeta(false, path),
  phone: defaultFieldMeta(true, path),
  address: defaultAddressFields(true, path),
})

const defaultMedicationDiagnosisCodeFields = (
  required: boolean,
  path: string
): WizardStateModels.WizardMedicationDiagnosisCode => ({
  code: defaultFieldMeta(required, path),
  description: defaultFieldMeta(required, path),
  qualifier: defaultFieldMeta(required, path),
})

const defaultMedicationDiagnosisFields = (
  path: string
): WizardStateModels.WizardMedicationDiagnosis => ({
  clinicalInformationQualifier: defaultFieldMeta(true, path),
  primary: defaultMedicationDiagnosisCodeFields(true, path),
  secondary: defaultMedicationDiagnosisCodeFields(false, path),
})

const defaultMedicationProductFields = (
  path: string
): WizardStateModels.WizardMedicationProduct => ({
  code: defaultFieldMeta(true, path),
})

const defaultMedicationQuantityFields = (
  path: string
): WizardStateModels.WizardMedicationQuantity => ({
  value: defaultFieldMeta(true, path),
  unitOfMeasure: defaultFieldMeta(true, path),
  codeListQualifier: defaultFieldMeta(true, path),
})

const defaultMedicationPrescribedFields = (
  path: string
): WizardStateModels.WizardMedicationPrescribedFields => ({
  description: defaultFieldMeta(true, path),
  product: defaultMedicationProductFields(path),
  quantity: defaultMedicationQuantityFields(path),
  daysSupply: defaultFieldMeta(true, path),
  substitutions: defaultFieldMeta(true, path),
  diagnosis: defaultMedicationDiagnosisFields(path),
})

const defaultPharmacyFields = (path: string): WizardStateModels.WizardPharmacyFields => ({
  npi: defaultFieldMeta(true, path),
  ncpdpId: defaultFieldMeta(true, path),
})

const defaultWizardFieldsConfig = (): WizardStateModels.WizardFields => ({
  detailsSection: defaultDetailsSectionFields('/submission/details'),
  patientSection: defaultPatientSectionFields('/submission/patient'),
  medicationPrescribed: defaultMedicationPrescribedFields('/submission/drug-and-diagnosis'),
  prescriber: defaultPrescriberFields('submission/prescriber'),
  pharmacy: defaultPharmacyFields('submission/pharmacy'),
})

const defaultAuthorization = () =>
  ({
    isAuthorized: false,
    expires: 0,
    checkTimeBuffer: 30000,
    token: null,
  } as WizardStateModels.Authorization)

const defaultUser = (): UserModels.UserState => ({
  email: '',
  firstName: '',
  lastName: '',
})

const defaultErrorState = (): MessageModels.MessageState => ({
  messageTitle: 'Uh oh!',
  messageLineOne: 'An unexpected error has occurred.',
  messageLineTwo: 'Please visit Myndview and try again.',
  buttonLabel: 'Back to Myndview',
  action: () => window.location.replace(getMyndviewUrl(document.location.origin)),
})

const defaultMessageState = (): MessageModels.MessageState => ({
  messageTitle: '',
  messageLineOne: '',
  messageLineTwo: '',
  buttonLabel: 'Back to Myndview',
  action: () => window.location.replace(getMyndviewUrl(document.location.origin)),
})

const defaultProviders = (): WizardStateModels.Provider[] => [] as WizardStateModels.Provider[]

const defaultCurrentQuestionMeta = (): WizardStateModels.WizardQuestionMeta => ({
  type: QuestionType.TEXT,
  value: defaultFieldMeta(true, '/submission/questionnaire'),
})

const defaultCancellationMeta = (): WizardStateModels.WizardCancellationMeta => ({
  case: defaultFieldMeta(true, '/submission/cancel-request'),
  reason: defaultFieldMeta(false, '/submission/cancel-request'),
  patientHasBeenNotified: defaultFieldMeta(false, '/submission/cancel-request'),
  additionalNotes: defaultFieldMeta(false, '/submission/cancel-request'),
})

const defaultState = (): WizardStateModels.WizardState => ({
  activeFeatures: [] as string[],
  authorization: defaultAuthorization(),
  cancellationMeta: defaultCancellationMeta(),
  csrf: null,
  currentQuestion: defaultCurrentQuestionMeta(),
  debugMode: false,
  error: defaultErrorState(),
  fields: defaultWizardFieldsConfig(),
  isCancellationLoading: false,
  isLinksLoading: true,
  lastActive: null,
  message: defaultMessageState(),
  monitoringInterval: null,
  pageLoading: true,
  providers: defaultProviders(),
  providersLoading: true,
  reVerifyNeeded: false,
  qualityUnitsOfMeasure: [] as WizardStateModels.SelectOption[],
  qualityUnitsOfMeasureLoading: true,
  submissionId: null,
  user: defaultUser(),
  transactions: [
    TransactionOptions.PRIOR_AUTH,
    TransactionOptions.ELIGIBILITY_AND_BENEFITS,
  ] as string[],
  reviewSectionVisited: false,
})

export const state = { ...defaultState() }
export type State = typeof state
