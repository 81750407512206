import { Address } from './address'

export enum Gender {
  MALE,
  FEMALE,
  UNKNOWN,
}
export type GenderStrings = keyof typeof Gender

export interface PatientInformation {
  firstName: string | null
  middleName?: string | null
  lastName: string | null
  dob: string | null
  gender: GenderStrings | null
  phone: string | null
}

export interface Patient extends PatientInformation {
  address: Address
}

export interface PatientPartial {
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  dob?: string | null
  gender?: GenderStrings | null
  phone?: string | null
  address?: Address | null
}
