<template>
  <div id="patient-group">
    <form-group-section element-key="patient-information" section-label="Patient Information">
      <patient-information-block
        element-key="patient-information-block"
        :gender-options="options.genderOptions"
        :meta="patientMeta"
        :patient="patient"
        @onInput="patientInformationInputHandler"
        @onMeta="patientInformationMetaHandler"
      ></patient-information-block>
    </form-group-section>
    <form-group-section element-key="patient-address" section-label="Patient Address">
      <address-block
        element-key="patient-address-block"
        :state-options="options.stateOptions"
        :meta="patientMeta.address"
        :address="patient.address || undefined"
        @onInput="addressInputHandler"
        @onMeta="addressMetaHandler"
      ></address-block>
    </form-group-section>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { FormGroupSection } from '@/components/shared'
import { AddressBlock, PatientInformationBlock } from '../blocks'
import * as options from '@/modules/options'
import { AddressModels, PatientModels } from '@/models/submission'
import { WizardStateModels } from '@/models/store'

export default defineComponent({
  name: 'PatientSection',
  components: {
    AddressBlock,
    FormGroupSection,
    PatientInformationBlock,
  },
  setup() {
    const store = useStore()
    const patientMeta = computed<WizardStateModels.WizardPatientSectionFields>(
      () => store.getters['wizard/getPatientSectionMeta']
    )
    const patient = computed<PatientModels.Patient>(() => store.getters['submission/getPatient'])

    const addressInputHandler = (addressValue: AddressModels.Address): void => {
      store.commit('submission/setPatientAddress', addressValue)
    }

    const addressMetaHandler = (addressMeta: WizardStateModels.WizardAddressFields): void => {
      store.commit('wizard/setPatientAddressMeta', addressMeta)
    }

    const patientInformationInputHandler = (
      patientInformationValue: PatientModels.Patient
    ): void => {
      store.commit('submission/setPatientInformation', patientInformationValue)
    }

    const patientInformationMetaHandler = (
      patientMeta: WizardStateModels.WizardPatientInformationFields
    ): void => {
      store.commit('wizard/setPatientInformationMeta', patientMeta)
    }

    return {
      options,

      patientMeta,
      patient,

      addressInputHandler,
      addressMetaHandler,
      patientInformationInputHandler,
      patientInformationMetaHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
}

.information-form-fields {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 2em;
  width: 100%;

  .text-input {
    width: 324px;
  }
}
</style>
