<template>
  <div :id="'address-block-' + elementKey" class="address-fields-container">
    <div class="address-input input-large">
      <text-input
        label="Address Line 1"
        :element-key="'address-line-one-' + elementKey"
        :required="!!meta?.lineOne?.required"
        :validation="validations.lineOne"
        :invalid="!!addressMeta?.lineOne?.dirty && !addressMeta.lineOne.valid"
        invalidHintText="This field is required and must be 40 characters or less."
        :value="values.lineOne || undefined"
        :data-public="dataPublic"
        @onInput="inputHandlers.lineOne"
        @onValidate="validationHandlers.lineOne"
      />
    </div>
    <div class="address-input input-large">
      <text-input
        label="Address Line 2"
        :element-key="'address-line-two-' + elementKey"
        :required="!!meta?.lineTwo?.required"
        :validation="validations.lineTwo"
        :invalid="!!addressMeta?.lineTwo?.dirty && !addressMeta.lineTwo.valid"
        invalidHintText="Although this field is NOT required, when present, it must be 40 characters or less."
        :value="values.lineTwo || undefined"
        :data-public="dataPublic"
        @onInput="inputHandlers.lineTwo"
        @onValidate="validationHandlers.lineTwo"
      />
    </div>
    <div class="address-input input-medium">
      <text-input
        label="City"
        :element-key="'address-city-' + elementKey"
        :required="!!meta?.city?.required"
        :validation="validations.city"
        :invalid="!!addressMeta?.city?.dirty && !addressMeta.city.valid"
        invalidHintText="This field is required and must be 35 characters or less."
        :value="values.city || undefined"
        :data-public="dataPublic"
        @onInput="inputHandlers.city"
        @onValidate="validationHandlers.city"
      />
    </div>
    <div class="address-input input-medium">
      <SelectInput
        label="State"
        :element-key="'address-state-' + elementKey"
        :placeholder="'Select State'"
        :options="stateOptions"
        :required="!!meta?.state?.required"
        :validation="validations.state"
        :invalid="!!addressMeta?.state?.dirty && !addressMeta.state.valid"
        option-label-key="label"
        option-value-key="value"
        :value="values.state || undefined"
        :data-public="dataPublic"
        @onSelection="inputHandlers.state"
        @onValidate="validationHandlers.state"
      />
    </div>
    <div class="address-input input-small">
      <text-input
        label="Zip Code"
        :element-key="'address-zip-' + elementKey"
        :required="!!meta?.zip?.required"
        :validation="validations.zip"
        :invalid="!!addressMeta?.zip?.dirty && !addressMeta.zip.valid"
        invalidHintText="This field requires the format: #####, #####-####, or #########."
        :value="values.zip || undefined"
        :data-public="dataPublic"
        @onInput="inputHandlers.zip"
        @onValidate="validationHandlers.zip"
      />
    </div>
    <div class="address-input input-medium">
      <text-input
        label="Country"
        :element-key="'address-country-' + elementKey"
        value="United States"
        :disabled="true"
        :data-public="dataPublic"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid'
import { defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import { TextInput, SelectInput } from '@/components/shared'
import { AddressModels } from '@/models/submission'
import { WizardStateModels } from '@/models/store'
import { inputValidations } from '@/modules/validations'

type StateOptions = { label: string; value: string }[]

export default defineComponent({
  name: 'AddressBlock',
  emits: ['on-input', 'on-meta'],
  components: { TextInput, SelectInput },
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    address: {
      type: Object as PropType<AddressModels.Address>,
      default: () => ({
        lineOne: null,
        lineTwo: null,
        city: null,
        state: null,
        zip: null,
      }),
    },
    stateOptions: {
      type: Array as PropType<StateOptions>,
      default: () => [],
    },
    meta: {
      type: Object as PropType<WizardStateModels.WizardAddressFields>,
      default: () => ({}),
    },
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const values = ref<AddressModels.Address>({ ...props.address })
    const addressMeta = ref<WizardStateModels.WizardAddressFields>({ ...props.meta })
    const validations = {
      lineOne: (value: string): boolean =>
        inputValidations.textLengthValidation(value, inputValidations.LENGTH_FORTY),
      lineTwo: (value: string): boolean =>
        !value || inputValidations.textLengthValidation(value, inputValidations.LENGTH_FORTY),
      city: (value: string): boolean =>
        inputValidations.textLengthValidation(value, inputValidations.LENGTH_THIRTY_FIVE),
      state: (value: string): boolean => inputValidations.some(value),
      zip: (value: string): boolean => inputValidations.zipcodeValidation(value),
    }

    const setAddressValues = (value: AddressModels.AddressPartial) => {
      values.value = { ...values.value, ...value }
    }

    const setAddressMeta = (value: WizardStateModels.WizardAddressFields) => {
      addressMeta.value = { ...addressMeta.value, ...value }
    }

    const inputHandlers = {
      lineOne: (value: string) => {
        setAddressValues({ lineOne: value })
        setAddressMeta({ lineOne: { ...addressMeta.value.lineOne, dirty: true } })
      },
      lineTwo: (value: string) => {
        setAddressValues({ lineTwo: value })
        setAddressMeta({ lineTwo: { ...addressMeta.value.lineTwo, dirty: true } })
      },
      city: (value: string) => {
        setAddressValues({ city: value })
        setAddressMeta({ city: { ...addressMeta.value.city, dirty: true } })
      },
      state: (value: AddressModels.StateCodeStrings) => {
        setAddressValues({ state: value })
        setAddressMeta({ state: { ...addressMeta.value.state, dirty: true } })
      },
      zip: (value: string) => {
        setAddressValues({ zip: value })
        setAddressMeta({ zip: { ...addressMeta.value.zip, dirty: true } })
      },
    }

    const validationHandlers = {
      lineOne: (valid: boolean) =>
        setAddressMeta({ lineOne: { ...addressMeta.value.lineOne, valid } }),
      lineTwo: (valid: boolean) =>
        setAddressMeta({ lineTwo: { ...addressMeta.value.lineTwo, valid } }),
      city: (valid: boolean) => setAddressMeta({ city: { ...addressMeta.value.city, valid } }),
      state: (valid: boolean) => setAddressMeta({ state: { ...addressMeta.value.state, valid } }),
      zip: (valid: boolean) => setAddressMeta({ zip: { ...addressMeta.value.zip, valid } }),
    }

    watch(values, values => context.emit('on-input', values))
    watch(addressMeta, validation => context.emit('on-meta', validation))
    onBeforeMount(() => (values.value = props.address))

    return {
      addressMeta,
      inputHandlers,
      values,
      validations,
      validationHandlers,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/input';

.address-fields-container {
  display: flex;
  flex-flow: row wrap;
}

.address-input {
  padding: 0 30px 20px 0;
}
</style>
