export enum CancelReason {
  NO_LONGER_UNDER_CARE = 'NO_LONGER_UNDER_CARE',
  CHANGE_OF_MEDICATION = 'CHANGE_OF_MEDICATION',
  OTHER = 'OTHER',
}

export type Case = string | null
export type Reason = CancelReason | null
export type PatientHasBeenNotified = boolean | null
export type AdditionalNotes = string | null

export interface CancelRequest {
  reason?: Reason
  patientHasBeenNotified?: PatientHasBeenNotified
  additionalNotes?: AdditionalNotes
}
