import axios from 'axios'
import { getBffRoute } from '../../utils'

const FEATURES_PATH = '/api/features'

const getFeatures = async (): Promise<string[]> => {
  const featuresResponse: any = await axios
    .post(`${getBffRoute()}${FEATURES_PATH}`, {})
    .catch(error => {
      const message = error?.response?.data?.error?.message || error.message
      throw Error(message)
    })
  if (featuresResponse?.data?.features) {
    return featuresResponse?.data?.features || []
  } else {
    throw Error('An error occurred fetching active features. Please try again later.')
  }
}

export { getFeatures }
