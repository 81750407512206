export enum QuestionType {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  SELECT = 'SELECT',
  DATE = 'DATE',
  ATTACHMENT = 'ATTACHMENT',
}

export interface GenericQuestion {
  id: string
  type: QuestionType
  label: string
}

export interface AttachmentQuestion extends GenericQuestion {
  type: QuestionType.ATTACHMENT
  value: string | null
  required: boolean | null
  attachmentId: string | null
  questionsAttachment?: string | null
}
export const isAttachmentQuestion = (question: GenericQuestion): question is AttachmentQuestion => {
  return question.type === QuestionType.ATTACHMENT
}

export interface TextQuestion extends GenericQuestion {
  type: QuestionType.TEXT
  value: string | null
}
export const isTextQuestion = (question: GenericQuestion): question is TextQuestion => {
  return question.type === QuestionType.TEXT
}

export interface NumericQuestion extends GenericQuestion {
  type: QuestionType.NUMERIC
  value: number | null
}
export const isNumericQuestion = (question: GenericQuestion): question is NumericQuestion => {
  return question.type === QuestionType.NUMERIC
}

export interface DateValue {
  isDateTime: boolean
  date: string | null
}

export interface DateQuestion extends GenericQuestion {
  type: QuestionType.DATE
  isDateTime: boolean
  value: DateValue
}
export const isDateQuestion = (question: GenericQuestion): question is DateQuestion => {
  return question.type === QuestionType.DATE
}

export interface SelectQuestionOption {
  id: string
  label: string
  explanationRequired: boolean | null
}

export interface SelectQuestionOptionSelection {
  id: string
  explanation?: string | null
}

export interface SelectQuestion extends GenericQuestion {
  type: QuestionType.SELECT
  multiSelect: boolean
  options: SelectQuestionOption[]
  value: SelectQuestionOptionSelection[]
}
export const isSelectQuestion = (question: GenericQuestion): question is SelectQuestion => {
  return question.type === QuestionType.SELECT
}

export interface FinishedQuestion {
  finished: boolean
}

export type Question =
  | TextQuestion
  | NumericQuestion
  | DateQuestion
  | SelectQuestion
  | AttachmentQuestion
