import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-efe02fb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "drug-and-diagnosis-group" }
const _hoisted_2 = {
  key: 0,
  class: "button-wrapper"
}
const _hoisted_3 = {
  key: 2,
  class: "remove-button-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_form_field_set_inner_section = _resolveComponent("form-field-set-inner-section")!
  const _component_diagnosis_block = _resolveComponent("diagnosis-block")!
  const _component_drug_block = _resolveComponent("drug-block")!
  const _component_form_field_set_section = _resolveComponent("form-field-set-section")!
  const _component_form_group_section = _resolveComponent("form-group-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_group_section, {
      "element-key": "drug-group",
      "section-label": "Drug and Diagnosis"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_form_field_set_section, {
          "element-key": "primary-drug-section",
          "section-label": "Drug",
          color: "secondary"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_drug_block, {
              "element-key": "primary-drug-fields",
              drug: _ctx.primaryDrug,
              meta: _ctx.primaryDrugMeta,
              "substitution-options": _ctx.options.substitutionOptions,
              "quantity-qualifier-options": _ctx.options.prescriptionQuantityQualifierOptions,
              "units-of-measure-options": _ctx.unitsOfMeasureOptions,
              transactions: _ctx.transactions || [],
              onOnInput: _ctx.primaryDrugInputHandler,
              onOnMeta: _ctx.primaryDrugMetaHandler
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_form_field_set_inner_section, {
                    color: "secondary",
                    "element-key": "primary-drug-clinical-information-qualifier",
                    "section-label": "Clinical Diagnosis Information"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_select_input, {
                        id: 'primary-drug-clinical-information-qualifier-selection',
                        "element-key": 'primary-drug-clinical-information-qualifier-selection',
                        class: "clinical-information-qualifier input-large",
                        label: "Clinical Information Qualifier",
                        placeholder: "Select Information Qualifier",
                        options: _ctx.options.clinicalInformationQualifiersOptions,
                        required: 
                  !!_ctx.primaryDrugMeta?.diagnosis?.clinicalInformationQualifier?.required &&
                  !_ctx.isBenefitsTransactionOnly
                ,
                        validation: _ctx.clinicalInformationValidation,
                        invalid: 
                  !!_ctx.primaryDrugMeta?.diagnosis?.clinicalInformationQualifier?.dirty &&
                  !_ctx.primaryDrugMeta?.diagnosis?.clinicalInformationQualifier?.valid
                ,
                        value: _ctx.primaryDrug?.diagnosis?.clinicalInformationQualifier || undefined,
                        "data-public": true,
                        onOnSelection: _ctx.clinicalInformationQualifierSelectionHandler,
                        onOnValidate: _ctx.clinicalInformationQualifierMetaHandler
                      }, null, 8, ["options", "required", "validation", "invalid", "value", "onOnSelection", "onOnValidate"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_form_field_set_inner_section, {
                    color: "secondary",
                    "element-key": "primary-drug-primary-diagnosis-section",
                    "section-label": "Primary Diagnosis"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_diagnosis_block, {
                        "element-key": "primary-drug-primary-diagnosis",
                        code: _ctx.primaryDrug?.diagnosis?.primary,
                        "diagnosis-qualifier-options": _ctx.options.diagnosisCodeQualifierOptions,
                        meta: _ctx.primaryDrugMeta?.diagnosis?.primary,
                        transactions: _ctx.transactions || [],
                        onOnInput: _ctx.primaryDiagnosisCodeHandler,
                        onOnMeta: _ctx.primaryDiagnosisMetaHandler
                      }, null, 8, ["code", "diagnosis-qualifier-options", "meta", "transactions", "onOnInput", "onOnMeta"])
                    ]),
                    _: 1
                  }),
                  (!_ctx.showSecondaryDiagnosis)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("span", {
                          class: "add-button",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addSecondaryDiagnosis && _ctx.addSecondaryDiagnosis(...args)))
                        }, " + Add Additional Diagnosis ")
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.showSecondaryDiagnosis)
                    ? (_openBlock(), _createBlock(_component_form_field_set_inner_section, {
                        key: 1,
                        color: "tertiary",
                        "element-key": "primary-drug-secondary-diagnosis-section",
                        "section-label": "Additional Diagnosis"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_diagnosis_block, {
                            "element-key": "primary-drug-secondary-diagnosis",
                            code: _ctx.primaryDrug?.diagnosis?.secondary,
                            "diagnosis-qualifier-options": _ctx.options.diagnosisCodeQualifierOptions,
                            meta: _ctx.primaryDrugMeta?.diagnosis?.secondary,
                            transactions: _ctx.transactions || [],
                            onOnInput: _ctx.secondaryDiagnosisCodeHandler,
                            onOnMeta: _ctx.secondaryDiagnosisMetaHandler
                          }, null, 8, ["code", "diagnosis-qualifier-options", "meta", "transactions", "onOnInput", "onOnMeta"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showSecondaryDiagnosis)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("button", {
                          class: "remove-button",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeSecondaryDiagnosis && _ctx.removeSecondaryDiagnosis(...args)))
                        }, "Remove")
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["drug", "meta", "substitution-options", "quantity-qualifier-options", "units-of-measure-options", "transactions", "onOnInput", "onOnMeta"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}