<template>
  <div role="group" :id="'form-group-' + elementKey" class="form-group-container">
    <div
      :id="'form-group-legend-container-' + elementKey"
      class="form-group"
      :aria-labelledby="'form-group-section-legend-' + elementKey"
    >
      <div :id="'form-group-section-legend-' + elementKey" class="form-legend">
        {{ sectionLabel }}
      </div>
      <div :id="'form-group-section-container-' + elementKey">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { v4 as uuid } from 'uuid'

export default defineComponent({
  name: 'FormGroupSection',
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    sectionLabel: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss" scoped>
.form-group-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
}
</style>
