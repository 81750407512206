<template>
  <div class="wl-banner-container">
    <div class="wl-banner">
      <div class="wl-left">
        <div class="item">
          <a v-if="settings.imageUrl"
            ><img class="wl-logo" :src="settings.imageUrl" :alt="`${settings.title || 'App'} Logo`"
          /></a>
        </div>
        <div class="item">
          <h1>{{ settings.title }}</h1>
        </div>
      </div>
      <div class="wl-right">
        <p v-if="!!userName" class="welcome">
          Welcome <strong class="username">{{ userName }}</strong>
        </p>
        <p v-if="!!logout" id="banner-logout-button" class="wl-link p-2" @click="logout">
          <fa-icon icon="fa-solid fa-right-from-bracket"></fa-icon> Log Out
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'Banner',
  props: {
    settings: {
      type: Object as PropType<{ title: string; imageUrl: string }>,
      default: () => ({
        title: null,
        imageUrl: null,
      }),
    },
    userName: String,
    logout: Function,
  },
  setup() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
@import '@myndshft/styles/src/components/wl-banner-mixin';

.p-2 {
  padding: 0 10px;
}

.username {
  color: #363636;
}

.welcome {
  margin-right: 1rem;
}

.wl-banner-container {
  @include wl-banner-mixin();
  flex-direction: column;
}
</style>
