import { CancellationModels } from '@/models/store'

const defaultState = (): CancellationModels.CancellationState => ({
  case: null,
  reason: null,
  patientHasBeenNotified: null,
  additionalNotes: null,
})

export const state = { ...defaultState() }
export type State = typeof state
