import axios, { AxiosResponse } from 'axios'
import { getBffRoute } from '../../utils'

const VERIFY_PATH = '/api/authorize/verify'
const CHECK_ACTIVE_PATH = '/api/authorize/check-active'
const SET_ACTIVE_PATH = '/api/authorize/set-active'
const LOGIN_PATH = '/api/authorize/login'
const LOGOUT_PATH = '/api/authorize/logout'

const checkActive = async (token: string, csrf: string): Promise<AxiosResponse | null> => {
  const checkActiveResponse: any = await axios
    .get(`${getBffRoute()}${CHECK_ACTIVE_PATH}`, {
      headers: {
        'x-csrf-token': csrf,
        Authorization: `Bearer ${token}`,
      },
      params: {
        date: new Date().toISOString(),
      },
    })
    .catch(() => {
      return null
    })

  return checkActiveResponse || null
}

const setActive = async (token: string, csrf: string): Promise<AxiosResponse | null> => {
  const setActiveResponse: any = await axios
    .get(`${getBffRoute()}${SET_ACTIVE_PATH}`, {
      headers: {
        'x-csrf-token': csrf,
        Authorization: `Bearer ${token}`,
      },
      params: {
        date: new Date().toISOString(),
      },
    })
    .catch(() => {
      return null
    })

  return setActiveResponse || null
}

const login = async (idToken: string, csrf: string): Promise<any> => {
  const loginResponse: any = await axios
    .post(
      `${getBffRoute()}${LOGIN_PATH}`,
      { id: idToken },
      {
        headers: {
          'x-csrf-token': csrf,
          'content-type': 'application/json',
        },
      }
    )
    .catch(() => {
      return null
    })

  if (loginResponse?.data?.token) {
    return {
      token: loginResponse?.data?.token || null,
      isAuthorized: loginResponse.status === 200 && !!loginResponse?.data?.token,
      expires: loginResponse?.data?.expires || 0,
      response: loginResponse,
    }
  } else {
    return null
  }
}

const logout = async (token: string | null, csrf: string): Promise<void> => {
  if (token && csrf) {
    const logoutResponse: any = await axios
      .get(`${getBffRoute()}${LOGOUT_PATH}`, {
        headers: { authorization: token, 'x-csrf-token': csrf },
      })
      .catch(error => {
        const message = error?.response?.data?.error?.message || error.message
        throw Error(message)
      })
    if (logoutResponse?.status === 200) {
      return
    }
  } else {
    throw Error('Authorization does not currently exist.')
  }
}

const getRemainingMilliseconds = (expireSeconds: number): number => {
  try {
    if (expireSeconds) {
      const currentMilliseconds: number = new Date().getTime()
      const diffMilliseconds: number = Math.abs(currentMilliseconds - expireSeconds)
      return Math.floor(diffMilliseconds)
    }
    return -1
  } catch (_error: any) {
    return -1
  }
}

const verify = async (csrf: string): Promise<any> => {
  const verifyResponse: any = await axios
    .get(`${getBffRoute()}${VERIFY_PATH}`, {
      headers: {
        'x-csrf-token': csrf,
      },
    })
    .catch(() => {
      return null
    })
  if (verifyResponse?.data?.token) {
    return {
      token: verifyResponse?.data?.token || null,
      isAuthorized: verifyResponse.status === 200 && !!verifyResponse?.data?.token,
      expires: verifyResponse?.data?.expires || 0,
      response: verifyResponse,
    }
  } else {
    return null
  }
}

export { checkActive, getRemainingMilliseconds, login, logout, setActive, verify }
