import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-468f77ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "pharamacy-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pharmacy_information_block = _resolveComponent("pharmacy-information-block")!
  const _component_form_group_section = _resolveComponent("form-group-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_group_section, {
      "element-key": "pharmacy-information",
      "section-label": "Pharmacy Information"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_pharmacy_information_block, {
          "element-key": "pharmacy-information-block",
          pharmacy: _ctx.pharmacy,
          meta: _ctx.pharmacyMeta,
          onOnInput: _ctx.pharmacyInformationInputHandler,
          onOnMeta: _ctx.pharmacyInformationMetaHandler
        }, null, 8, ["pharmacy", "meta", "onOnInput", "onOnMeta"])
      ]),
      _: 1
    })
  ]))
}