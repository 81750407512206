import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-450e8fce"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "data-required"]
const _hoisted_3 = ["id", "data-error", "data-required", "disabled", "min", "max", "value", "data-public"]
const _hoisted_4 = ["id", "type", "data-error", "data-required", "disabled", "min", "max", "value", "data-public"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'date-container-' + _ctx.elementKey,
    class: "text-input-container"
  }, [
    _createElementVNode("label", {
      id: 'date-container-label-' + _ctx.elementKey,
      class: "form-field-label",
      "data-required": _ctx.required
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    (_ctx.timeRequired)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          id: 'date-container-input-' + _ctx.elementKey,
          type: "datetime-local",
          class: "form-field",
          autocomplete: "off",
          "data-error": _ctx.invalid,
          "data-required": _ctx.dataRequired,
          disabled: _ctx.disabled,
          min: _ctx.inputMin,
          max: _ctx.inputMax,
          value: _ctx.inputValue,
          "data-public": !!_ctx.dataPublic || undefined,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args)))
        }, null, 40, _hoisted_3))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          id: 'date-container-input-' + _ctx.elementKey,
          type: _ctx.timeRequired ? 'datetime-local' : 'date',
          class: "form-field",
          autocomplete: "off",
          "data-error": _ctx.invalid,
          "data-required": _ctx.dataRequired,
          disabled: _ctx.disabled,
          min: _ctx.inputMin,
          max: _ctx.inputMax,
          value: _ctx.inputValue,
          "data-public": !!_ctx.dataPublic || undefined,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args)))
        }, null, 40, _hoisted_4)),
    (_ctx.invalid && _ctx.invalidHintText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            id: 'date-container-input-invalid-hint-' + _ctx.elementKey,
            class: "invalid-hint"
          }, " *" + _toDisplayString(_ctx.invalidHintText), 9, _hoisted_6)
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}