<template>
  <div :id="'explanation-container-' + elementKey" class="explanation-container">
    <div :id="'explanation-label-container-' + elementKey" class="multi-select-label-container">
      <label :id="'explanation-label-' + elementKey" class="form-field-label">
        Selection Details
      </label>
    </div>
    <div :id="'explanation-area-' + elementKey" class="explanation-area">
      <div :id="'explanation-items-' + elementKey" class="explanation-items">
        <div class="no-items" v-show="!displayItems?.length">
          Selections with optional and required details will show here.
        </div>
        <div
          v-for="item of displayItems"
          :key="item.key"
          :invalid="!isValid(item.id)"
          class="details-items"
        >
          <div
            v-if="item.required !== null"
            class="details-item"
            :invalid="!displayValidations.text(item.id)"
          >
            <label class="form-field-label details-item-label" :data-required="item.required">
              {{ item.label }}
            </label>
            <text-area-input
              :id="'item-details-text-' + elementKey + '-' + item.key"
              placeholder="Enter details here..."
              class="details-item-text"
              :element-key="'item-details-text-' + item.key"
              :required="item.required"
              :value="item.explanation"
              @onInput="inputHandler(item.id, $event)"
            ></text-area-input>
            <div class="remove-details-item-icon" @click="removeItemHandler(item.id)">
              <fa-icon
                :id="'remove-item-trash-icon-' + elementKey"
                icon="fa-solid fa-trash"
                tabindex="-1"
              ></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, computed } from 'vue'
import { v4 as uuid } from 'uuid'
import { InputMeta } from '@/models/wizard'
import { TextAreaInput } from '@/components/shared'

interface DisplayItem {
  id: string
  key: string
  explanation: string
  label: string
  required: boolean | null
}

interface ItemType {
  id: string
  label: string
  required: boolean | null
}

export default defineComponent({
  name: 'ExplanationBlock',
  emits: ['on-input', 'on-remove', 'on-validate'],
  components: { TextAreaInput },
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    items: {
      type: Array as PropType<(ItemType & any)[]>,
      default: () => [],
    },
    meta: {
      type: Array as PropType<InputMeta[]>,
      default: () => [],
    },
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const itemValues = ref<DisplayItem[]>([] as DisplayItem[])
    const displayItems = computed(() =>
      itemValues.value.filter(itemValue => itemValue.required !== null)
    )
    const itemsMeta = ref<InputMeta[]>([] as InputMeta[])

    const isValid = (id: string): boolean => {
      const itemsCopy = [...itemValues.value]
      const itemIndex = itemsCopy?.findIndex(item => item.id === id)
      const item = itemIndex !== -1 ? itemsCopy[itemIndex] : null
      if (item) {
        return !!item.explanation || !item.required
      }

      return false
    }

    const displayValidations = {
      text: (id: string) => isValid(id) || !itemsMeta.value?.find(item => item.id === id)?.dirty,
    }

    const createItemsFromProps = (items: any[]): void => {
      itemValues.value =
        items?.map(item => ({
          id: item.id,
          key: item.id,
          label: item.label,
          required: item.required,
          explanation: item.explanation,
        })) || []
    }

    const inputHandler = (id: string, value: string): void => {
      const itemsCopy = [...itemValues.value]
      const itemIndex = itemsCopy?.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        itemsCopy[itemIndex].explanation = value
        context.emit('on-input', itemsCopy[itemIndex])
        context.emit('on-validate', { id, valid: isValid(id) })
      }
    }

    const removeItemHandler = (id: string): void => {
      if (id) {
        context.emit('on-remove', id)
      }
    }

    const keyboardEnterKeyHandler = (event: any): void => {
      event.preventDefault()
      event.element.blur()
    }

    watch(() => props.items, createItemsFromProps, { immediate: true })
    watch(
      () => props.meta,
      meta => {
        itemsMeta.value = meta
      },
      { immediate: true }
    )

    return {
      displayItems,
      displayValidations,

      isValid,
      inputHandler,
      keyboardEnterKeyHandler,
      removeItemHandler,
    }
  },
})
</script>

<style lang="scss">
@import '@/styles/global';
@import '@/styles/input';

.explanation-container {
  margin: 20px 0;
}

.explanation-area {
  display: flex;
  flex-direction: column;
  border: 1px solid $myndshft-light-gray;
  border-radius: 2px;
  width: 100%;
  padding: 20px;
}

.explanation-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 0;
  min-width: 0;
}

.details-items {
  display: flex;
  flex-direction: column;
}

.details-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--secondary-color);

  &[invalid='true'] {
    border: 1px solid $myndshft-required-pink;
    box-shadow: 0 0 2px $myndshft-required-pink;
  }

  > .form-field-label {
    margin-bottom: 0;
  }
}

.remove-details-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  max-width: 80px;
  border: none;
  color: $myndshft-dark-orange;
  height: 110px;
  transition: all 200ms ease-in-out;

  &:hover {
    color: $myndshft-white;
    background-color: $myndshft-dark-orange;
  }
  :focus {
    outline: none;
  }
}

.details-item-label {
  display: flex;
  align-items: center;
  border: none;

  padding: 8px 16px;
  min-width: fit-content;
  max-width: 200px;
  word-wrap: normal;
  margin-bottom: 0;
  max-height: 110px;
  height: 110px;
  overflow-y: scroll;
  margin-right: -1px;
  justify-content: center;
}

.details-item-text {
  width: 100%;
  height: 110px;
  > .form-field {
    border-left: 1px solid var(--secondary-color);
    border-right: 1px solid var(--secondary-color);
    border-top: none;
    border-bottom: none;
    border-radius: 0;
    height: 110px;
  }
  > .form-field:focus {
    box-shadow: none;
    margin: 0;
    padding: 6px 12px;
  }
}
</style>
