import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard_menu_item = _resolveComponent("wizard-menu-item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: `wizard-section-${index}`
    }, [
      (!section?.meta?.hidden)
        ? (_openBlock(), _createBlock(_component_wizard_menu_item, {
            key: 0,
            section: section,
            "valid-sections": _ctx.validSections,
            "review-visited": _ctx.reviewVisited,
            onClick: ($event: any) => (_ctx.selectSection(section))
          }, null, 8, ["section", "valid-sections", "review-visited", "onClick"]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}