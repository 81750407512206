import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d43b010a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wl-banner-container" }
const _hoisted_2 = { class: "wl-banner" }
const _hoisted_3 = { class: "wl-left" }
const _hoisted_4 = { class: "item" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "wl-right" }
const _hoisted_9 = {
  key: 0,
  class: "welcome"
}
const _hoisted_10 = { class: "username" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.settings.imageUrl)
            ? (_openBlock(), _createElementBlock("a", _hoisted_5, [
                _createElementVNode("img", {
                  class: "wl-logo",
                  src: _ctx.settings.imageUrl,
                  alt: `${_ctx.settings.title || 'App'} Logo`
                }, null, 8, _hoisted_6)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.settings.title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (!!_ctx.userName)
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
              _createTextVNode(" Welcome "),
              _createElementVNode("strong", _hoisted_10, _toDisplayString(_ctx.userName), 1)
            ]))
          : _createCommentVNode("", true),
        (!!_ctx.logout)
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              id: "banner-logout-button",
              class: "wl-link p-2",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
            }, [
              _createVNode(_component_fa_icon, { icon: "fa-solid fa-right-from-bracket" }),
              _createTextVNode(" Log Out ")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}