import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import cancellationModule from './cancellation'
import configModule from './config'
import submissionModule from './submission'
import summaryModule from './summary'
import wizardModule from './wizard'
import questionsModule from './questions'
import eligibilityModule from './eligibility'

const Store = createStore({
  state: {
    authToken: null,
    csrfToken: null,
    submissionId: null,
  },
  getters: {
    getAuthToken: (state: any): string | null => state.authToken,
    getCSRFToken: (state: any): string | null => state.csrfToken,
    getSubmissionId: (state: any): string | null => state.submissionId,
  },
  mutations: {
    setAuthTokenOnRoot: (state: any, value: string) => (state.authToken = value),
    setCSRFTokenOnRoot: (state: any, value: string) => (state.csrfToken = value),
    setSubmissionIdOnRoot: (state: any, value: string) => (state.submissionId = value),
    clearState: (state: any) => {
      state.csrf = null
      state.authorization = null
      state.submissionId = null
    },
  },
  actions: {},
  modules: {
    cancellation: {
      namespaced: true,
      ...cancellationModule,
    },
    config: {
      namespaced: true,
      ...configModule,
    },
    wizard: {
      namespaced: true,
      ...wizardModule,
    },
    submission: {
      namespaced: true,
      ...submissionModule,
    },
    summary: {
      namespaced: true,
      ...summaryModule,
    },
    questions: {
      namespaced: true,
      ...questionsModule,
    },
    eligibility: {
      namespaced: true,
      ...eligibilityModule,
    },
  },
  plugins: [
    createPersistedState({
      key: 'settings',
      paths: ['config'],
      storage: window.sessionStorage,
    }),
  ],
})

const State = typeof Store.state

export { Store, State }
