import { Commit } from 'vuex'

import ConfigService from '@/services/config'
import { getHeaderFromResponse, getMyndviewUrl } from '@/utils'
import { Icon, notify } from '@/modules/notification'

const getConfig = async ({ commit }: { commit: Commit }) => {
  try {
    const configResponse = await ConfigService.getConfig()
    const csrfToken = getHeaderFromResponse('x-csrf-token', configResponse?.response)
    commit('setCSRFTokenOnRoot', csrfToken, { root: true })
    const termsUrl = `${getMyndviewUrl(document.location.origin)}/terms`
    commit('setConfig', { ...configResponse?.config, termsUrl })
    return
  } catch (error: any) {
    const message =
      error?.response?.data?.error?.message ||
      'An error occurred fetching configuration information. Please try again later.'

    notify(message, 'Oops! Something happened!', Icon.ERROR).finally()

    throw new Error(message)
  }
}

export default { getConfig }
