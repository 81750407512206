import { validateNPI } from '@myndshft/validation'

export const LENGTH_THIRTY_FIVE = 35
export const LENGTH_FORTY = 40
export const LENGTH_SEVENTY = 70
export const LENGTH_ONE_HUNDRED = 100
export const LENGTH_ONE_HUNDRED_FIVE = 105
export const LENGTH_TWO_FIFTY_FIVE = 255
export const LENGTH_TWO_THOUSAND = 2000
export const LENGTH_ONE = 1
export const LENGTH_TWO = 2
export const LENGTH_SEVEN = 7
export const LENGTH_TEN = 10
export const LENGTH_ELEVEN = 11

export const optional = (_value: any) => true
export const some = (value: any) => !!value
export const textLengthValidation = (
  text?: string | null,
  maxLength?: number,
  minLength?: number
): boolean => {
  if (!text) {
    return false
  }

  let valid = !!text
  if (valid && maxLength) {
    valid = text.length <= maxLength
  }
  if (valid && minLength) {
    valid = text.length >= minLength
  }

  return valid
}

export const basicCharValidation = (text?: string | null): boolean => {
  const CHAR_REGEX = /^[a-zA-Z0-9]+$/
  return !!text && text.length < Number.MAX_VALUE && CHAR_REGEX.test(text)
}

export const npiValidation = (npi?: string | null): boolean => {
  const NPI_REGEX = /^\d{10}$/
  return !!npi && npi.length < Number.MAX_VALUE && NPI_REGEX.test(npi) && validateNPI(npi)
}

export const ncpdpValidation = (id?: string | null): boolean => {
  return !!id && id.length === LENGTH_SEVEN
}

export const drugQuantityValidation = (quantity?: string | null): boolean => {
  const DRUG_QUANTITY_REGEX = /^[Cc]\d{5}$|^[Cc]\d{6}$/
  const MAX_SIZE = 7
  return !!quantity && quantity.length <= MAX_SIZE && DRUG_QUANTITY_REGEX.test(quantity)
}

export const diagnosisCodeValidation = (code?: string | null): boolean => {
  const MIN_LENGTH = 3
  const MAX_LENGTH = 7
  return !!code && code.length >= MIN_LENGTH && code.length <= MAX_LENGTH
}

export const zipcodeValidation = (zip?: string | null): boolean => {
  const ZIP_REGEX = /^\d{5}(?:-?\d{4})?$/
  return !!zip && zip.length < Number.MAX_VALUE && ZIP_REGEX.test(zip)
}

export const positiveIntegerValidation = (value?: string | null, length?: number): boolean => {
  const POS_INTEGER_REGEX = /^(([0-9]*)(\.0)?)$/
  return (
    !!value && value.length < (length ? length : Number.MAX_VALUE) && POS_INTEGER_REGEX.test(value)
  )
}
export const wholeNumberValidation = (value?: string | null): boolean => {
  const NUMBER_REGEX = /^([0-9]*)$/
  return !!value && value.length < Number.MAX_VALUE && NUMBER_REGEX.test(value)
}

export const ndcCodeValidation = (value?: string | null): boolean => {
  const NDC_FORMAT_REGEX = /(^\d{5}-\d{4}-\d{2}$)|(^\d{11}$)/
  return (
    !!value && value.replaceAll('-', '').length === LENGTH_ELEVEN && NDC_FORMAT_REGEX.test(value)
  )
}
