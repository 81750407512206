import { State } from './state'
import { EligibilityStateModels } from '@/models/store'

const setEligibilityResponse = (state: State, value: EligibilityStateModels.BenefitsResponse) => {
  state.eligibilityResponse = value
}

const setPriorAuthIsRequiredFromEligibilityResponse = (state: State, value: boolean) => {
  state.priorAuthIsRequiredFromEligibilityResponse = value
}

const setErrorMessage = (state: State, value: string | null) => {
  state.errorMessage = value
}

export default {
  setEligibilityResponse,
  setErrorMessage,
  setPriorAuthIsRequiredFromEligibilityResponse,
}
