import axios from 'axios'
import { getBffRoute } from '@/utils'

const CANCEL_REQUEST_PATH = (submissionId: string) => `/api/submissions/${submissionId}/cancel`

const cancelRequest = async (
  token: string | null,
  csrf: string | null,
  submissionId: string | null,
  body: any
) => {
  if (!token || !csrf || !submissionId) {
    throw Error('An error occurred attempting auth cancellation. Please try again later.')
  }

  const headers = { authorization: token, 'x-csrf-token': csrf }
  await axios.post(`${getBffRoute()}${CANCEL_REQUEST_PATH(submissionId)}`, body, { headers })
}

export { cancelRequest }
