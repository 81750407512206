import axios, { AxiosResponse } from 'axios'
import { ConfigStateModels } from '@/models/store'
import { getBffRoute } from '@/utils'
import { track } from '@/modules/logrocket'

const CONFIG_PATH = '/api/config'
const ERROR_MESSAGE =
  'An error occurred fetching configuration information. Please try again later.'

const getConfig = async (): Promise<{
  config: ConfigStateModels.Config
  response: AxiosResponse<any>
}> => {
  const configResponse: AxiosResponse<any> = await axios
    .get(`${getBffRoute()}${CONFIG_PATH}`)
    .catch(error => {
      track('RXConfigError', { origin: window.location.origin })
      const message = error?.response?.data?.error?.message || error.message
      console.error(message)
      throw Error(ERROR_MESSAGE)
    })
  if (configResponse?.data) {
    return { config: configResponse?.data, response: configResponse }
  } else {
    track('RXConfigError', { origin: window.location.origin })
    throw Error(ERROR_MESSAGE)
  }
}

export { getConfig }
