import { State } from './state'
import { deepMerge } from '@/utils'
import { QuestionModels } from '@/models/questions'

const incrementQuestionNumber = (state: State) => {
  state.questionNumber = state.questionNumber + 1
}

const resetQuestionNumber = (state: State) => {
  state.questionNumber = 0
}

const setCurrentQuestionInit = (state: State, question: QuestionModels.GenericQuestion | null) => {
  state.currentQuestion = question
    ? ({ ...question, value: null } as QuestionModels.Question)
    : null
}

const setQuestionValues = (state: State, value: QuestionModels.Question) => {
  state.error = null
  state.currentQuestion = state.currentQuestion ? deepMerge(state.currentQuestion, value) : value
}

const setIsFirstQuestion = (state: State, value: boolean) => {
  state.isFirstQuestion = value
}

const setError = (state: State, value: string) => {
  state.error = value
}

const setCheckingForNextQuestion = (state: State, value: boolean) => {
  state.checkingForNextQuestion = value
}

const setQuestionAttachmentId = (state: State, value: string) => {
  // Only currently setup for attachment questions
  if (state.currentQuestion && QuestionModels.isAttachmentQuestion(state.currentQuestion)) {
    state.currentQuestion = { ...state.currentQuestion, attachmentId: value }
  }
}

export default {
  incrementQuestionNumber,
  resetQuestionNumber,
  setCurrentQuestionInit,
  setCheckingForNextQuestion,
  setError,
  setIsFirstQuestion,
  setQuestionValues,
  setQuestionAttachmentId,
}
