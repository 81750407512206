import axios from 'axios'
import { getBffRoute } from '@/utils'

const QUALITY_UNITS_OF_MEASURE_PATH = '/api/quality-units-of-measure'
const ERROR_MESSAGE = 'An error occurred fetching quality units of measure.'

const getQualityUnitsOfMeasure = async (
  token: string | null,
  csrf: string | null
): Promise<any> => {
  if (!token || !csrf) {
    throw Error(ERROR_MESSAGE)
  }

  const headers = { authorization: token, 'x-csrf-token': csrf }
  const unitsResponse: any = await axios
    .get(`${getBffRoute()}${QUALITY_UNITS_OF_MEASURE_PATH}`, { headers })
    .catch(error => {
      const message = error?.response?.data?.error?.message || error.message
      console.error(message)

      throw Error(ERROR_MESSAGE)
    })
  if (unitsResponse?.data) {
    return unitsResponse?.data
  } else {
    throw Error(ERROR_MESSAGE)
  }
}

export { getQualityUnitsOfMeasure }
