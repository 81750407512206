import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_message = _resolveComponent("message")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_message, {
      "banner-title": _ctx.wizardError?.bannerTitle,
      "banner-url": _ctx.wizardError?.bannerUrl,
      "message-title": _ctx.wizardError?.messageTitle,
      "message-line-one": _ctx.wizardError?.messageLineOne,
      "message-line-two": _ctx.wizardError?.messageLineTwo,
      "message-line-three": _ctx.wizardError?.messageLineThree,
      "button-label": _ctx.wizardError?.buttonLabel,
      action: _ctx.wizardError?.action
    }, null, 8, ["banner-title", "banner-url", "message-title", "message-line-one", "message-line-two", "message-line-three", "button-label", "action"])
  ]))
}