import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "no-items" }
const _hoisted_7 = ["invalid"]
const _hoisted_8 = ["invalid"]
const _hoisted_9 = ["data-required"]
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'explanation-container-' + _ctx.elementKey,
    class: "explanation-container"
  }, [
    _createElementVNode("div", {
      id: 'explanation-label-container-' + _ctx.elementKey,
      class: "multi-select-label-container"
    }, [
      _createElementVNode("label", {
        id: 'explanation-label-' + _ctx.elementKey,
        class: "form-field-label"
      }, " Selection Details ", 8, _hoisted_3)
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      id: 'explanation-area-' + _ctx.elementKey,
      class: "explanation-area"
    }, [
      _createElementVNode("div", {
        id: 'explanation-items-' + _ctx.elementKey,
        class: "explanation-items"
      }, [
        _withDirectives(_createElementVNode("div", _hoisted_6, " Selections with optional and required details will show here. ", 512), [
          [_vShow, !_ctx.displayItems?.length]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayItems, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.key,
            invalid: !_ctx.isValid(item.id),
            class: "details-items"
          }, [
            (item.required !== null)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "details-item",
                  invalid: !_ctx.displayValidations.text(item.id)
                }, [
                  _createElementVNode("label", {
                    class: "form-field-label details-item-label",
                    "data-required": item.required
                  }, _toDisplayString(item.label), 9, _hoisted_9),
                  _createVNode(_component_text_area_input, {
                    id: 'item-details-text-' + _ctx.elementKey + '-' + item.key,
                    placeholder: "Enter details here...",
                    class: "details-item-text",
                    "element-key": 'item-details-text-' + item.key,
                    required: item.required,
                    value: item.explanation,
                    onOnInput: ($event: any) => (_ctx.inputHandler(item.id, $event))
                  }, null, 8, ["id", "element-key", "required", "value", "onOnInput"]),
                  _createElementVNode("div", {
                    class: "remove-details-item-icon",
                    onClick: ($event: any) => (_ctx.removeItemHandler(item.id))
                  }, [
                    _createVNode(_component_fa_icon, {
                      id: 'remove-item-trash-icon-' + _ctx.elementKey,
                      icon: "fa-solid fa-trash",
                      tabindex: "-1"
                    }, null, 8, ["id"])
                  ], 8, _hoisted_10)
                ], 8, _hoisted_8))
              : _createCommentVNode("", true)
          ], 8, _hoisted_7))
        }), 128))
      ], 8, _hoisted_5)
    ], 8, _hoisted_4)
  ], 8, _hoisted_1))
}