import { MessageModels } from '@/models/message'
import { QuestionModels } from '@/models/questions'
import { UserModels } from '@/models/user'
import { InputMeta } from '@/models/wizard/input-meta'

export interface Authorization {
  isAuthorized: boolean
  expires: number
  checkTimeBuffer?: number
  token: string | null
}

export interface Provider {
  name: string
  npi: string
}

export interface WizardDetailsSectionFields {
  priority?: InputMeta
  provider?: InputMeta
}

export interface WizardAddressFields {
  lineOne?: InputMeta
  lineTwo?: InputMeta
  city?: InputMeta
  state?: InputMeta
  zip?: InputMeta
}

export interface WizardPatientInformationFields {
  firstName?: InputMeta
  middleName?: InputMeta
  lastName?: InputMeta
  dob?: InputMeta
  gender?: InputMeta
  phone?: InputMeta
}

export interface WizardPatientSectionFields extends WizardPatientInformationFields {
  address?: WizardAddressFields
}

export interface WizardMedicationProduct {
  code?: InputMeta
}

export interface WizardMedicationQuantity {
  value?: InputMeta
  unitOfMeasure?: InputMeta
  codeListQualifier?: InputMeta
}

export interface WizardMedicationDiagnosisCode {
  code?: InputMeta
  description?: InputMeta
  qualifier?: InputMeta
}

export interface WizardMedicationDiagnosis {
  clinicalInformationQualifier?: InputMeta
  primary?: WizardMedicationDiagnosisCode
  secondary?: WizardMedicationDiagnosisCode
}

export interface WizardMedicationPrescribedInformationFields {
  description?: InputMeta
  product?: WizardMedicationProduct
  quantity?: WizardMedicationQuantity
  daysSupply?: InputMeta
  substitutions?: InputMeta
}

export interface WizardMedicationPrescribedFields
  extends WizardMedicationPrescribedInformationFields {
  diagnosis?: WizardMedicationDiagnosis
}

export interface WizardPrescriberInformationFields {
  firstName?: InputMeta
  lastName?: InputMeta
  npi?: InputMeta
  phone?: InputMeta
  fax?: InputMeta
  organizationName?: InputMeta
}

export interface WizardPrescriberFields extends WizardPrescriberInformationFields {
  address?: WizardAddressFields
}

export interface WizardPharmacyFields {
  npi?: InputMeta
  ncpdpId?: InputMeta
}

export interface WizardGeneralQuestionMeta {
  type: QuestionModels.QuestionType
  value: InputMeta
}

export interface WizardAttachmentQuestionMeta extends WizardGeneralQuestionMeta {
  type: QuestionModels.QuestionType.ATTACHMENT
}
export const isWizardAttachmentQuestionMeta = (meta: any): meta is WizardAttachmentQuestionMeta => {
  return meta?.type === QuestionModels.QuestionType.ATTACHMENT
}

export interface WizardTextQuestionMeta extends WizardGeneralQuestionMeta {
  type: QuestionModels.QuestionType.TEXT
}
export const isWizardTextQuestionMeta = (meta: any): meta is WizardTextQuestionMeta => {
  return meta?.type === QuestionModels.QuestionType.TEXT
}

export interface WizardNumericQuestionMeta extends WizardGeneralQuestionMeta {
  type: QuestionModels.QuestionType.NUMERIC
}
export const isWizardNumericQuestionMeta = (meta: any): meta is WizardNumericQuestionMeta => {
  return meta?.type === QuestionModels.QuestionType.NUMERIC
}

export interface WizardDateQuestionMeta extends WizardGeneralQuestionMeta {
  type: QuestionModels.QuestionType.DATE
}
export const isWizardDateQuestionMeta = (meta: any): meta is WizardDateQuestionMeta => {
  return meta?.type === QuestionModels.QuestionType.DATE
}

export interface WizardSelectQuestionMeta {
  type: QuestionModels.QuestionType.SELECT
  select: InputMeta
  values: InputMeta[]
}
export const isWizardSelectQuestionMeta = (meta: any): meta is WizardSelectQuestionMeta => {
  return meta?.type === QuestionModels.QuestionType.SELECT
}

export const isGeneralQuestionMeta = (meta: any): meta is WizardGeneralQuestionMeta => {
  return [
    QuestionModels.QuestionType.TEXT,
    QuestionModels.QuestionType.DATE,
    QuestionModels.QuestionType.NUMERIC,
    QuestionModels.QuestionType.ATTACHMENT,
  ].includes(meta?.type)
}

export type WizardQuestionMeta =
  | WizardTextQuestionMeta
  | WizardNumericQuestionMeta
  | WizardDateQuestionMeta
  | WizardSelectQuestionMeta
  | WizardAttachmentQuestionMeta

export interface WizardFields {
  detailsSection: WizardDetailsSectionFields
  patientSection: WizardPatientSectionFields
  medicationPrescribed: WizardMedicationPrescribedFields
  prescriber: WizardPrescriberFields
  pharmacy: WizardPharmacyFields
}

export interface SelectOption {
  label: string
  value: string
}

export interface WizardCancellationMeta {
  case: InputMeta
  reason?: InputMeta
  patientHasBeenNotified?: InputMeta
  additionalNotes?: InputMeta
}

export interface WizardCancellationRequestMeta {
  reason?: InputMeta
  patientHasBeenNotified?: InputMeta
  additionalNotes?: InputMeta
}

export interface WizardState {
  activeFeatures: string[]
  authorization: Authorization
  cancellationMeta: WizardCancellationMeta
  csrf: string | null
  currentQuestion: WizardQuestionMeta
  debugMode: boolean
  error: MessageModels.MessageState
  fields: WizardFields
  isCancellationLoading: boolean
  isLinksLoading: boolean
  message: MessageModels.MessageState
  monitoringInterval: number | null
  pageLoading: boolean
  providers: Provider[]
  providersLoading: boolean
  lastActive: string | null
  reVerifyNeeded: boolean
  submissionId: string | null
  qualityUnitsOfMeasure: SelectOption[]
  qualityUnitsOfMeasureLoading: boolean
  user: UserModels.UserState
  transactions: string[]
  reviewSectionVisited: boolean
}
