import { Commit } from 'vuex'
import * as jwt from 'jsonwebtoken'
import { State } from './state'

import AuthService from '@/services/auth'
import FeaturesService from '@/services/features'
import ProvidersService from '@/services/providers'
import PrescriptionService from '@/services/prescription'

import { UIDisplayMessage, getUITimeOfDayText } from '@/utils'
import { InputMeta } from '@/models/wizard'
import { Icon, notify } from '@/modules/notification'
import Features from '@/services/features'

const ERROR_MESSAGE_TITLE = 'Oops! Something happened!'

const checkActive = async ({
  rootState,
  state,
  commit,
}: {
  rootState: any
  state: State
  commit: Commit
}): Promise<boolean> => {
  try {
    const token = state?.authorization?.token || ''
    const csrf = rootState?.csrfToken

    const response = await AuthService.checkActive(token, csrf)

    if (response.status === 200) {
      commit('setLastActive', response?.data?.date || null)

      if (response?.data?.reVerify) {
        commit('setReVerify', response.data.reVerify)
      }

      return !!response?.data?.date
    } else {
      return false
    }
  } catch (_error) {
    return false
  }
}

const setActive = async ({
  rootState,
  state,
  commit,
}: {
  rootState: any
  state: State
  commit: Commit
}): Promise<boolean> => {
  try {
    const token = state?.authorization?.token || ''
    const csrf = rootState?.csrfToken

    const response = await AuthService.setActive(token, csrf)

    if (response.status === 200) {
      commit('setLastActive', response?.data?.date || null)

      if (response?.data?.reVerify) {
        commit('setReVerify', response.data.reVerify)
      }
    }

    return response.status === 200
  } catch (_error) {
    return false
  }
}

const features = async ({ commit }: { commit: Commit }) => {
  const features = await FeaturesService.getFeatures()
  if (features.length) {
    commit('setActiveFeatures', features)
  }
}

const fetchPrescribingProvider = async (
  {
    rootState,
    commit,
    state,
  }: {
    rootState: any
    commit: Commit
    state: State
  },
  npi?: string
) => {
  if (npi) {
    const providerResponse = await ProvidersService.fetchPrescribingProvider(
      rootState.authToken,
      rootState.csrfToken,
      npi
    ).catch(() => {
      return null
    })

    if (providerResponse?.provider) {
      const getPopulatedMeta = (valid: boolean): InputMeta => ({
        id: '',
        disabled: false,
        dirty: true,
        hidden: false,
        valid,
        path: '/submission/prescriber',
      })

      const provider: any = providerResponse.provider
      const informationMeta: any = {
        firstName: getPopulatedMeta(!!provider?.firstName),
        lastName: getPopulatedMeta(!!provider?.lastName),
        npi: getPopulatedMeta(!!provider?.npi),
        fax: getPopulatedMeta(true),
        phone: getPopulatedMeta(!!provider?.phone),
      }

      commit('setPrescriberInformationMeta', {
        ...state.fields.prescriber,
        ...informationMeta,
        address: { ...state.fields.prescriber.address },
      })

      if (provider?.address) {
        const address = provider.address
        const addressMeta: any = {
          lineOne: getPopulatedMeta(!!address?.lineOne),
          lineTwo: getPopulatedMeta(true),
          city: getPopulatedMeta(!!address?.city),
          state: getPopulatedMeta(!!address?.state),
          zip: getPopulatedMeta(!!address?.zip),
        }

        commit('setPrescriberAddressMeta', { ...state.fields.prescriber.address, ...addressMeta })
      }

      return { ...provider, npi }
    } else {
      return null
    }
  }
}

const decodeUser = (token: string) => {
  const decodedToken = jwt.decode(token) as any
  return {
    email: decodedToken.email || '',
    firstName: decodedToken.given_name || '',
    lastName: decodedToken.family_name || '',
  }
}

const getQualityUnitsOfMeasure = async ({
  rootState,
  commit,
}: {
  rootState: any
  commit: Commit
}) => {
  const codesResponse: { codes?: any[] } = await PrescriptionService.getQualityUnitsOfMeasure(
    rootState.authToken,
    rootState.csrfToken
  ).catch((error: any) => {
    notify(error.message, ERROR_MESSAGE_TITLE, Icon.ERROR)
    commit('setQualityUnitsOfMeasureLoading', false)
    commit('setQualityUnitsOfMeasure', [])
  })

  if (codesResponse?.codes) {
    commit('setQualityUnitsOfMeasure', codesResponse?.codes)
    commit('setQualityUnitsOfMeasureLoading', false)
  }
}

const handleAuthResponse = async (
  authorization: {
    token: string
    isAuthorized: boolean
    expires: number
  },
  commit: Commit
) => {
  if (authorization?.isAuthorized) {
    const cleanToken = authorization.token?.replace('Bearer ', '')

    commit('setAuthTokenOnRoot', authorization.token, { root: true })
    commit('setToken', cleanToken)
    commit('setUser', decodeUser(cleanToken))
    commit('setAuthorized', authorization.isAuthorized)
    commit('setExpiration', authorization.expires)
  }
}

const handleAuthorizationError = (error: any, state: State, commit: Commit) => {
  const uiError = new UIDisplayMessage()
    .setMessage(state.error)
    .setMessageLineOne(error.message)
    .getUiMessage()
  commit('setError', uiError)
  commit('setAuthorized', false)
  commit('setPageLoading', false)
  notify(
    'An error occurred logging in. Please try again later.',
    ERROR_MESSAGE_TITLE,
    Icon.ERROR
  ).finally()
}

const verify = async ({
  rootState,
  commit,
  state,
}: {
  rootState: any
  commit: Commit
  state: State
}): Promise<boolean> => {
  try {
    commit('setPageLoading', true)
    const csrf = rootState.csrfToken
    const authorization = await AuthService.verify(csrf).catch(() => {
      return {}
    })
    await handleAuthResponse(authorization, commit)
    commit('setPageLoading', false)

    const features = await Features.getFeatures()
    if (features.includes('isEnabledToUseTheSessionCookieToFetchTheToken')) {
      return !!authorization?.isAuthorized
    } else {
      if (authorization?.isAuthorized) {
        return true
      } else {
        const message = new UIDisplayMessage()
          .setMessage(state.message)
          .setMessageLineOne(
            'We had an issue with your authorization, please log into Myndview and try again.'
          )
          .getUiMessage()
        commit('setMessage', message)

        throw new Error('')
      }
    }
  } catch (error: any) {
    handleAuthorizationError(error, state, commit)
    return false
  }
}

const login = async (
  { rootState, commit, state }: { rootState: any; commit: Commit; state: State },
  idToken: string
): Promise<boolean> => {
  try {
    commit('setPageLoading', true)
    const csrf = rootState.csrfToken
    const authorization = await AuthService.login(idToken, csrf).catch(() => {
      return {}
    })
    await handleAuthResponse(authorization, commit)
    commit('setPageLoading', false)
    return !!authorization?.isAuthorized
  } catch (error: any) {
    handleAuthorizationError(error, state, commit)
    return false
  }
}

const logout = async ({
  rootState,
  commit,
  state,
}: {
  rootState: any
  commit: Commit
  state: State
}): Promise<boolean> => {
  try {
    await AuthService.logout(rootState.authToken, rootState.csrfToken)
    commit('clearState', null, { root: true })
    commit('setAuthorized', false)
    commit('setToken', null)

    const message = new UIDisplayMessage()
      .setMessage(state.message)
      .setMessageTitle('Logout Success!')
      .setMessageLineOne('Thanks for using pharmacy authorization.')
      .setMessageLineTwo(`Have a great ${getUITimeOfDayText()}!`)
      .getUiMessage()
    commit('setMessage', message)

    return true
  } catch (error: any) {
    const uiError = new UIDisplayMessage()
      .setMessage(state.error)
      .setMessageLineOne(error.message)
      .getUiMessage()
    commit('setError', uiError)
    commit('setAuthorized', false)
    throw new Error('failed to logout successfully')
  }
}

const providers = async ({ rootState, commit }: { rootState: any; commit: Commit }) => {
  const providers = await ProvidersService.getUserProviders(
    rootState.authToken,
    rootState.csrfToken
  ).catch(error => {
    notify(error.message, ERROR_MESSAGE_TITLE, Icon.ERROR)
    commit('setProvidersLoading', false)
    commit('setProviders', [])
  })
  commit('setProviders', providers.providers)
  commit('setProvidersLoading', false)
}

export default {
  checkActive,
  features,
  fetchPrescribingProvider,
  getQualityUnitsOfMeasure,
  login,
  logout,
  providers,
  setActive,
  verify,
}
