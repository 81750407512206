<template>
  <div :id="'select-container-' + elementKey" class="select-container">
    <label
      :id="'select-container-label-' + elementKey"
      class="form-field-label"
      :data-required="required"
    >
      {{ label }}
    </label>
    <div :id="'select-input-container-' + elementKey" class="select-input-container">
      <input
        :ref="inputElement"
        :id="'select-container-input-' + elementKey"
        class="form-field"
        autocomplete="off"
        @click="openSelectOptionsView"
        @focus="openSelectOptionsView"
        @blur="closeSelectOptionsView"
        @keydown.enter.prevent="keyboardEnterKeyHandler"
        @keydown.down.prevent="keyboardDownKeyHandler"
        @keydown.up.prevent="keyboardUpKeyHandler"
        @input="inputHandler"
        :value="currentSelection?.label || searchValue"
        :data-error="invalid"
        :data-required="dataRequired"
        :disabled="disabled"
        :placeholder="disabled ? '' : placeholderDisplay"
        :data-public="dataPublic"
      />
      <fa-icon
        :id="'select-container-input-chevrondown-icon-' + elementKey"
        @click="openSelectOptionsView"
        v-if="showChevronDownIcon"
        icon="fa-solid fa-chevron-down"
        class="icon icon-chevron"
        tabindex="-1"
        size="lg"
      ></fa-icon>
      <div v-if="loading" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <fa-icon
        :id="'select-container-input-chevronup-icon-' + elementKey"
        @click="closeSelectOptionsView"
        v-if="showChevronUpIcon"
        icon="fa-solid fa-chevron-up"
        class="icon icon-chevron"
        tabindex="-1"
        size="lg"
      ></fa-icon>
      <fa-icon
        :id="'select-container-input-times-icon-' + elementKey"
        @click="clearSelection"
        v-if="showCloseIcon"
        icon="fa-solid fa-xmark"
        class="icon icon-close"
        tabindex="-1"
        size="lg"
      ></fa-icon>
      <ul
        :id="'select-container-option-list-' + elementKey"
        ref="ulElement"
        :data-search="!!searchValue && !filteredDisplayOptions.length"
        class="select-result-list"
        v-if="optionsViewOpened"
        :data-public="dataPublic"
      >
        <li
          tabindex="0"
          :id="'select-container-option-list-item-' + elementKey + '-' + selectionOption.key"
          :key="elementKey + '-' + selectionOption.key"
          :focused="selectionOption.focused"
          class="select-result"
          v-for="(selectionOption, index) in filteredDisplayOptions"
          @mouseenter="() => focusHandler(index)"
          @mousedown="() => selectionHandler(selectionOption)"
          :data-public="dataPublic"
        >
          {{ selectionOption.label }}
        </li>
      </ul>
    </div>
    <div v-if="invalid && required">
      <div :id="'select-input-invalid-hint-' + elementKey" class="invalid-hint">
        *{{ invalidHintText ? invalidHintText : 'This selection is required.' }}
      </div>
    </div>
    <div v-else-if="invalid && invalidHintText">
      <div :id="'select-input-invalid-hint-' + elementKey" class="invalid-hint">
        *{{ invalidHintText }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, PropType, ref, watch, computed } from 'vue'
import { v4 as uuid } from 'uuid'

interface SelectDisplayOption {
  key: string
  label: string
  value: string
  focused: boolean
}
type SelectDisplayOptions = SelectDisplayOption[]

export default defineComponent({
  name: 'SelectInput',
  emits: ['on-selection', 'on-input', 'on-validate'],
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    options: {
      type: Array as PropType<any>,
      default: () => [],
    },
    optionLabelKey: {
      type: String,
      default: 'label',
    },
    optionValueKey: {
      type: String,
      default: 'value',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidHintText: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
    },
    validation: Function,
    loading: Boolean,
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const filteredDisplayOptions = ref<SelectDisplayOptions>([] as SelectDisplayOptions)
    const allDisplayOptions = ref<SelectDisplayOptions>([] as SelectDisplayOptions)
    const optionsViewOpened = ref<boolean>(false)
    const searchValue = ref<string | null>(null)
    const placeholderDisplay = ref<string>(props.placeholder)
    const currentSelection = ref<SelectDisplayOption | null>(null)
    const currentSelectionHolder = ref<SelectDisplayOption | null>(null)
    const inputElement = ref<HTMLInputElement | null>(null)
    const ulElement = ref<HTMLUListElement | null>(null)
    const openWhenFinished = ref<boolean>(false)
    const focusIndex = ref<number | null>(null)

    const dataRequired = computed(() => props.required && !currentSelection.value)
    const showCloseIcon = computed(() => {
      return (currentSelection.value || searchValue.value?.length > 0) && !props.disabled
    })
    const showChevronDownIcon = computed(() => {
      return !props.disabled && !optionsViewOpened.value && !props.loading && !showCloseIcon.value
    })
    const showChevronUpIcon = computed(() => {
      return !props.disabled && optionsViewOpened.value
    })

    const validate = (value: any): boolean => {
      if (!props.required) {
        return props.validation ? props.validation(value) : !props.validation
      }
      return !!value && props.validation ? props.validation(value) : !props.validation
    }

    const openSelectOptionsView = () => {
      if (!props.loading) {
        optionsViewOpened.value = true
        searchValue.value = null
        if (currentSelection.value) {
          placeholderDisplay.value = currentSelection.value?.label
          currentSelectionHolder.value = currentSelection.value
          currentSelection.value = null
        }
      } else {
        openWhenFinished.value = true
      }
    }

    const clearFields = () => {
      currentSelection.value = null
      currentSelectionHolder.value = null
      searchValue.value = null
      placeholderDisplay.value = props.placeholder
      focusIndex.value = null
    }

    const clearSelection = () => {
      clearFields()

      context.emit('on-selection', null)
      context.emit('on-validate', !props.required && !props.validation)
    }

    const createSelectOptionElementKey = (label: string): string => {
      return label.replaceAll(' ', '').toLowerCase().trim()
    }

    const createSelectDisplayOption = (
      option: any,
      labelKey?: string,
      valueKey?: string
    ): SelectDisplayOption => {
      const label = labelKey ? option?.[labelKey] : option

      if (typeof label !== 'string') {
        throw new Error('Invalid display options, maybe you need to specify "optionLabelKey"')
      }

      const key = createSelectOptionElementKey(label)

      let value = null

      if (valueKey && option?.[valueKey]) {
        value = option[valueKey]
      } else if (typeof option === 'string') {
        value = option
      }

      return { key, label, value, focused: false }
    }

    const closeSelectOptionsView = () => {
      focusIndex.value = null
      optionsViewOpened.value = false
      searchValue.value = null
      if (!currentSelection.value && currentSelectionHolder.value) {
        currentSelection.value = currentSelectionHolder.value
      } else if (currentSelection.value) {
        currentSelectionHolder.value = null
      } else if (!currentSelection.value && !currentSelectionHolder.value) {
        context.emit('on-selection', null)
      }
    }

    const findOptionByLabel = (label?: string): any => {
      if (!label) {
        return null
      }

      const key = props.optionLabelKey
      return props.options?.find((option: any) => {
        const optionLabel = typeof option === 'string' ? option : key ? option?.[key] : ''
        return optionLabel === label
      })
    }

    const inputHandler = (event: any): void => {
      if (!props.disabled) {
        if (!optionsViewOpened.value) {
          optionsViewOpened.value = true
        }
        if (event?.target?.value) {
          searchValue.value = event.target.value
        } else {
          searchValue.value = null
        }
      }
    }

    const focusHandler = (index: number | null): void => {
      focusIndex.value = index
      const optionsCopy = [...filteredDisplayOptions.value].map(item => ({
        ...item,
        focused: false,
      }))
      if (index === 0 || index) {
        optionsCopy[index].focused = true
      }
      filteredDisplayOptions.value = optionsCopy
    }

    const keyboardEnterKeyHandler = (event: any): void => {
      event.preventDefault()
      if (!props.disabled && filteredDisplayOptions.value?.length === 1) {
        const option = filteredDisplayOptions.value[0]

        clearFields()

        currentSelection.value = option

        const optionValue = findOptionByLabel(currentSelection.value?.label || '')
        const value = props.optionValueKey ? optionValue?.[props.optionValueKey] : optionValue

        context.emit('on-selection', value)
        context.emit('on-validate', validate(value))

        event.target.blur()
      } else if (focusIndex.value !== null) {
        const option = filteredDisplayOptions.value[focusIndex.value]

        clearFields()

        currentSelection.value = option

        const optionValue = findOptionByLabel(currentSelection.value?.label || '')
        const value = props.optionValueKey ? optionValue?.[props.optionValueKey] : optionValue

        context.emit('on-selection', value)
        context.emit('on-validate', validate(value))

        event.target.blur()
      } else {
        return
      }
    }

    const keyboardDownKeyHandler = (event: any): void => {
      event.preventDefault()
      if (optionsViewOpened.value) {
        if (focusIndex.value === null) {
          focusIndex.value = 0
        } else {
          focusIndex.value =
            focusIndex.value < filteredDisplayOptions.value.length - 1
              ? focusIndex.value + 1
              : focusIndex.value
        }

        if (
          focusIndex.value > 2 &&
          focusIndex.value < filteredDisplayOptions.value.length - 1 &&
          filteredDisplayOptions.value.length > 5 &&
          ulElement.value
        ) {
          const liElements = ulElement.value.children as any
          const nextSibling: any = liElements[focusIndex.value].nextElementSibling
          nextSibling.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      } else if (filteredDisplayOptions.value.length) {
        optionsViewOpened.value = true
        focusIndex.value = 0
      }
    }

    const keyboardUpKeyHandler = (event: any): void => {
      event.preventDefault()
      if (optionsViewOpened.value) {
        if (focusIndex.value === null) {
          focusIndex.value = 0
        } else if (focusIndex.value > 0) {
          focusIndex.value = focusIndex.value - 1
        } else if (focusIndex.value === 0) {
          optionsViewOpened.value = false
        }

        if (focusIndex.value > 0 && filteredDisplayOptions.value.length > 5 && ulElement.value) {
          const liElements = ulElement.value.children as any
          const prevSibling: any = liElements[focusIndex.value].previousSibling
          prevSibling.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }
    }

    const selectionHandler = (option: SelectDisplayOption): void => {
      searchValue.value = null
      currentSelectionHolder.value = null
      if (!props.disabled) {
        currentSelection.value = option
      }

      const optionValue = findOptionByLabel(currentSelection.value?.label || '')
      const value = props.optionValueKey ? optionValue?.[props.optionValueKey] : optionValue

      inputElement.value?.blur()
      optionsViewOpened.value = false

      context.emit('on-selection', value)
      context.emit('on-validate', validate(value))
    }

    const setOptionsAndSelectionFromProps = (options: any[]) => {
      const newOptions = [] as SelectDisplayOptions
      for (const option of options) {
        const selectOption = createSelectDisplayOption(
          option,
          props.optionLabelKey,
          props.optionValueKey
        )

        newOptions.push(selectOption)

        if (props.value) {
          if (props.optionValueKey && option?.[props.optionValueKey] === props.value) {
            currentSelection.value = selectOption
          } else if (option === props.value) {
            currentSelection.value = selectOption
          }
        }
      }
      allDisplayOptions.value = newOptions
    }

    watch(
      () => props.options,
      options => {
        setOptionsAndSelectionFromProps(options)
        if (openWhenFinished.value) {
          openSelectOptionsView()
          openWhenFinished.value = false
        }
      },
      { immediate: true }
    )

    watch(
      searchValue,
      searchString => {
        if (searchString) {
          focusIndex.value = null
          filteredDisplayOptions.value = allDisplayOptions.value
            .filter(item => item?.label?.toLowerCase().includes(searchString?.toLowerCase()))
            .map(item => ({ ...item, focused: false }))
          context.emit('on-input', searchString)
        } else {
          filteredDisplayOptions.value = allDisplayOptions.value
        }
      },
      { immediate: true }
    )

    watch(allDisplayOptions, allOptions => {
      const searchString = searchValue.value || ''
      if (searchString) {
        focusIndex.value = null
        filteredDisplayOptions.value = allOptions
          .filter(item => item?.label?.toLowerCase().includes(searchString?.toLowerCase()))
          .map(item => ({ ...item, focused: false }))
      } else {
        filteredDisplayOptions.value = allOptions
      }
    })

    watch(focusIndex, index => focusHandler(index))

    onBeforeMount(() => {
      if (!!props.options?.length && !!props.value) {
        setOptionsAndSelectionFromProps(props.options)
      }
    })

    return {
      dataRequired,
      filteredDisplayOptions,
      inputElement,
      ulElement,
      searchValue,
      currentSelection,
      optionsViewOpened,
      placeholderDisplay,
      showCloseIcon,
      showChevronDownIcon,
      showChevronUpIcon,

      focusHandler,
      inputHandler,
      keyboardEnterKeyHandler,
      keyboardDownKeyHandler,
      keyboardUpKeyHandler,
      selectionHandler,
      openSelectOptionsView,
      closeSelectOptionsView,
      clearSelection,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.select-container {
  position: relative;
}

.select-input-container {
  position: relative;
}

.select-result-list {
  position: absolute;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid $myndshft-light-gray;
  border-radius: 4px;
  margin-top: 4px;
  width: 100%;
  z-index: 15;
  &[data-search='true'] {
    border: none;
  }
}

.select-result {
  background: $myndshft-white;
  font-size: 14px;
  padding: 8px 16px;
  user-select: none;
  word-wrap: break-word;
  overflow-y: auto;

  &[focused='true'] {
    color: $myndshft-white;
    background: var(--primary-color);
  }

  &:nth-of-type(even) {
    background: $myndshft-background-accent;

    &[focused='true'] {
      background: var(--primary-color);
    }
  }
}

.form-field {
  width: 100%;
  padding-right: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &focus {
    outline: none;
  }
}

.icon {
  position: absolute;
  cursor: pointer;
  top: 9px;
  z-index: 12;

  &:focus {
    outline: none;
  }
  &-chevron {
    color: var(--primary-color);
    right: 10px;
  }
  &-close {
    right: 12px;
  }
}

.spinner-container {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 12;
}

.spinner {
  display: inline-block;
  border: 4px solid;
  border-radius: 50%;
  border-top-color: transparent;
  color: var(--primary-color);
  width: 18px;
  height: 18px;
  animation: turn 1s linear infinite;
  z-index: 2;
  margin: 10px;
}

.invalid-hint {
  padding: 2px;
  color: #f14668;
  font-size: 12px;
  line-height: 14px;
}
</style>
