import { Commit } from 'vuex'
import SummaryService from '@/services/summary'

const getSummaryDetails = async ({ rootState, commit }: { rootState: any; commit: Commit }) => {
  const summary = await SummaryService.getSummaryDetails(
    rootState.submissionId,
    rootState.authToken,
    rootState.csrfToken
  ).catch((error: any) => {
    console.error(error.message)
  })

  if (summary) {
    commit('setSummary', summary)
  }
}

export default { getSummaryDetails }
