import { RouteRecordRaw } from 'vue-router'
import {
  AuthCancellationSection,
  DetailsSection,
  DrugAndDiagnosisSection,
  PatientSection,
  PayerQuestionnaireSection,
  PharamacySection,
  PrescriberSection,
  ReviewSection,
} from '@/components/wizard/sections'

export const wizardSections: Array<RouteRecordRaw> = [
  {
    path: 'details',
    name: 'Details',
    component: DetailsSection,
    meta: {
      selected: true,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: 'patient',
    name: 'Patient',
    component: PatientSection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: 'prescriber',
    name: 'Prescriber',
    component: PrescriberSection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: 'drug-and-diagnosis',
    name: 'Drug and Diagnosis',
    component: DrugAndDiagnosisSection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: 'pharmacy',
    name: 'Pharmacy',
    component: PharamacySection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      hidden: true,
      requiresAuth: true,
    },
  },
  {
    path: 'questionnaire',
    name: 'Question Set',
    component: PayerQuestionnaireSection,
    meta: {
      selected: false,
      valid: null,
      disabled: true,
      hidden: true,
      requiresAuth: true,
    },
  },
  {
    path: 'review',
    name: 'Review',
    component: ReviewSection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: 'cancel-request',
    name: 'Cancel Request',
    component: AuthCancellationSection,
    meta: {
      selected: false,
      valid: null,
      disabled: true,
      hidden: true,
      requiresAuth: true,
    },
  },
  {
    path: '',
    name: 'redirect-details',
    redirect: 'details',
    meta: {
      hidden: true,
    },
  },
]
