import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08853d67"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id", "data-required"]
const _hoisted_4 = { class: "select-info-text" }
const _hoisted_5 = ["id", "invalid"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["id", "value", "disabled", "data-public"]
const _hoisted_8 = { class: "search-clear-icon" }
const _hoisted_9 = ["id"]
const _hoisted_10 = {
  key: 0,
  class: "no-items"
}
const _hoisted_11 = ["id", "selected", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'multi-select-container-' + _ctx.elementKey,
    class: "multi-select-container"
  }, [
    _createElementVNode("div", {
      id: 'multi-select-label-container-' + _ctx.elementKey,
      class: "multi-select-label-container"
    }, [
      _createElementVNode("label", {
        id: 'multi-select-label-' + _ctx.elementKey,
        class: "form-field-label",
        "data-required": _ctx.required
      }, _toDisplayString(_ctx.label), 9, _hoisted_3),
      _createElementVNode("span", _hoisted_4, "( Select " + _toDisplayString(_ctx.multiSelect ? 'all that apply' : 'one') + " )", 1)
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      id: 'multi-select-area-' + _ctx.elementKey,
      class: "multi-select-area",
      invalid: _ctx.invalid
    }, [
      _createElementVNode("div", {
        id: 'multi-select-area-search-' + _ctx.elementKey,
        class: "multi-select-area-search"
      }, [
        _createElementVNode("input", {
          ref: _ctx.inputElement,
          id: 'multi-select-container-search-' + _ctx.elementKey,
          class: "form-field",
          autocomplete: "off",
          onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.keyboardEnterKeyHandler && _ctx.keyboardEnterKeyHandler(...args)), ["prevent"]), ["enter"])),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args))),
          value: _ctx.searchValue,
          disabled: _ctx.disabled,
          placeholder: "Search...",
          "data-public": !!_ctx.dataPublic || undefined
        }, null, 40, _hoisted_7),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.searchValue?.length > 0 && !_ctx.disabled)
            ? (_openBlock(), _createBlock(_component_fa_icon, {
                key: 0,
                id: 'multi-select-container-search-times-icon-' + _ctx.elementKey,
                onClick: _ctx.clearSearch,
                icon: "fa-solid fa-xmark",
                tabindex: "-1"
              }, null, 8, ["id", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_6),
      _createElementVNode("div", {
        id: 'multi-select-area-items-' + _ctx.elementKey,
        class: "multi-select-area-items"
      }, [
        (!_ctx.filteredDisplayOptions?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, "No Selections Found..."))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredDisplayOptions, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            id: 'multi-select-area-item-' + _ctx.elementKey + '-' + item.key,
            key: item.key,
            selected: item.selected,
            class: "item",
            onClick: ($event: any) => (_ctx.itemSelectionHandler(item))
          }, _toDisplayString(item.label), 9, _hoisted_11))
        }), 128))
      ], 8, _hoisted_9)
    ], 8, _hoisted_5)
  ], 8, _hoisted_1))
}