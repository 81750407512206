import { State } from './state'
import { QuestionModels } from '@/models/questions'
import { deepClone } from '@/utils'

const getCurrentQuestion = (state: State): QuestionModels.Question | null => {
  return deepClone(state.currentQuestion)
}

const getError = (state: State): string | null => {
  return state.error
}

const getIsFirstQuestion = (state: State): boolean => {
  return state.isFirstQuestion
}

const getQuestionNumber = (state: State): number => {
  return state.questionNumber
}

const isCheckingForNextQuestion = (state: State): boolean | null => {
  return state.checkingForNextQuestion
}

export default {
  getCurrentQuestion,
  getError,
  getIsFirstQuestion,
  getQuestionNumber,
  isCheckingForNextQuestion,
}
