import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4483a10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-root-container" }
const _hoisted_2 = { class: "message-container" }
const _hoisted_3 = { class: "message-display" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["titleAlign"]
const _hoisted_6 = ["messageAlign"]
const _hoisted_7 = {
  key: 0,
  class: "message"
}
const _hoisted_8 = {
  key: 1,
  class: "message"
}
const _hoisted_9 = {
  key: 2,
  class: "message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!!_ctx.bannerUrl || !!_ctx.bannerTitle)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "logo",
              src: _ctx.bannerUrl || '',
              alt: `${_ctx.bannerTitle || ''} ` + 'Logo'
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (!!_ctx.messageTitle)
          ? (_openBlock(), _createElementBlock("h2", {
              key: 1,
              titleAlign: _ctx.titleAlign
            }, _toDisplayString(_ctx.messageTitle), 9, _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "message-lines",
          messageAlign: _ctx.messageAlign
        }, [
          (!!_ctx.messageLineOne)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.messageLineOne), 1))
            : _createCommentVNode("", true),
          (!!_ctx.messageLineTwo)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.messageLineTwo), 1))
            : _createCommentVNode("", true),
          (!!_ctx.messageLineThree)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.messageLineThree), 1))
            : _createCommentVNode("", true)
        ], 8, _hoisted_6),
        (_ctx.action !== null)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "wl-button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)))
            }, _toDisplayString(_ctx.buttonLabel), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}