export const deepClone = <T>(obj: T): T => {
  return obj ? JSON.parse(JSON.stringify(obj)) : null
}

export const deepMerge = (objOne: any, objTwo: any) => {
  const mergedObject = objOne ? deepClone(objOne) : {}
  const cloneTwo = deepClone(objTwo)

  if (!cloneTwo) {
    return null
  }

  for (const [key, value] of Object.entries(cloneTwo)) {
    if (typeof value !== 'object' || (typeof value === 'object' && Array.isArray(value))) {
      mergedObject[key] = value
    } else {
      mergedObject[key] = deepMerge(mergedObject[key], value)
    }
  }
  return mergedObject
}

export const formatErrorMessageForDisplay = (message: string): string => {
  let result = message?.replace('{', '')?.replace('}', '')

  if (!result) {
    return ''
  }

  try {
    if (result.includes(', ')) {
      result = result
        .split(', ')
        .map((str: string) => {
          const temp = str.split('=')?.[1]
          return temp.charAt(0).toUpperCase() + temp.slice(1)
        })
        .join(', ')
    } else {
      result = result.split('=')?.[1]
      result = result.charAt(0).toUpperCase() + result.slice(1)
    }
  } catch (error: any) {
    result = 'An error occurred submitting the answer. Please try again later.'
  }

  return result
}
