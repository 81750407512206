import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { applyDefaultFontFamily } from '@myndshft/fonts'

import Router from '@/router'
import Store from '@/store'
import App from '@/App.vue'

import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faCopy,
  faTrash,
  faCheck,
  faLock,
  faEnvelope,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faCopy,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faTrash,
  faCheck,
  faLock,
  faEnvelope,
  faRightFromBracket
)

createApp(App).use(Router).use(Store).component('fa-icon', FontAwesomeIcon).mount('#app')

applyDefaultFontFamily()
