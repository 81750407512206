<template>
  <div :id="'date-container-' + elementKey" class="text-input-container">
    <label
      :id="'date-container-label-' + elementKey"
      class="form-field-label"
      :data-required="required"
    >
      {{ label }}
    </label>
    <input
      v-if="timeRequired"
      :id="'date-container-input-' + elementKey"
      type="datetime-local"
      class="form-field"
      autocomplete="off"
      :data-error="invalid"
      :data-required="dataRequired"
      :disabled="disabled"
      :min="inputMin"
      :max="inputMax"
      :value="inputValue"
      :data-public="!!dataPublic || undefined"
      @input="inputHandler"
    />
    <input
      v-else
      :id="'date-container-input-' + elementKey"
      :type="timeRequired ? 'datetime-local' : 'date'"
      class="form-field"
      autocomplete="off"
      :data-error="invalid"
      :data-required="dataRequired"
      :disabled="disabled"
      :min="inputMin"
      :max="inputMax"
      :value="inputValue"
      :data-public="!!dataPublic || undefined"
      @input="inputHandler"
    />
    <div v-if="invalid && invalidHintText">
      <div :id="'date-container-input-invalid-hint-' + elementKey" class="invalid-hint">
        *{{ invalidHintText }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onBeforeMount, computed } from 'vue'
import { v4 as uuid } from 'uuid'

export default defineComponent({
  name: 'DateInput',
  emits: ['on-input', 'on-validate'],
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    label: {
      type: String,
      default: '',
    },
    min: {
      type: [String, Date],
      default: null,
    },
    max: {
      type: [String, Date],
      default: null,
    },
    timeRequired: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidHintText: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Date],
      default: '',
    },
    validation: Function,
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const inputValue = ref<string | null>(null)
    const inputMin = ref<string | null>(null)
    const inputMax = ref<string | null>(null)
    const dataRequired = computed(() => props.required && !inputValue.value)

    const validate = (value: any): boolean => {
      if (!props.required) {
        return props.validation ? props.validation(value) : !props.validation
      }
      return !!value && props.validation ? props.validation(value) : !props.validation
    }

    const inputHandler = (event: any): void => {
      if (!props.disabled) {
        if (event?.target?.value) {
          inputValue.value = event?.target?.value
        } else {
          inputValue.value = null
        }
      }
    }

    const getLocalDateTimeInputValue = (isoString: string): string => {
      const isoDate = new Date(isoString)
      const localDate = new Date(isoDate.getTime() - isoDate.getTimezoneOffset() * 60000)
      return localDate.toISOString().substring(0, 16)
    }

    const getDateString = (value: Date | string): string => {
      const dateString = typeof value === 'string' ? value : value.toISOString()

      if (props.timeRequired) {
        return getLocalDateTimeInputValue(dateString)
      } else {
        return dateString.substring(0, 10)
      }
    }

    watch(inputValue, value => {
      if (props.timeRequired) {
        context.emit('on-input', value ? new Date(value).toISOString() : value)
      } else {
        context.emit('on-input', value)
      }

      context.emit('on-validate', validate(value))
    })

    onBeforeMount(() => {
      inputValue.value = props.value ? getDateString(props.value) : null
      inputMin.value = props.min ? getDateString(props.min) : null
      inputMax.value = props.max ? getDateString(props.max) : null
    })

    return {
      dataRequired,
      inputMax,
      inputMin,
      inputValue,

      inputHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.text-input-container {
  position: relative;
}

.form-field {
  width: 100%;
}

.invalid-hint {
  padding: 2px;
  color: #f14668;
  font-size: 12px;
  line-height: 14px;
}
</style>
