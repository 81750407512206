import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74e35b9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "information-input" }
const _hoisted_3 = { class: "information-input" }
const _hoisted_4 = { class: "information-input" }
const _hoisted_5 = { class: "information-input" }
const _hoisted_6 = { class: "information-input" }
const _hoisted_7 = { class: "information-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_phone_input = _resolveComponent("phone-input")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'information-fields-' + _ctx.elementKey,
    class: "information-fields-container"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_input, {
        "element-key": 'patient-first-name-' + _ctx.elementKey,
        label: "First Name",
        required: !!_ctx.meta?.firstName?.required,
        validation: _ctx.validations.firstName,
        invalid: !!_ctx.fieldsMeta?.firstName?.dirty && !_ctx.fieldsMeta?.firstName.valid,
        invalidHintText: "This field is required and must be 35 characters or less.",
        value: _ctx.values.firstName || undefined,
        onOnInput: _ctx.inputHandlers.firstName,
        onOnValidate: _ctx.validationHandlers.firstName
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_input, {
        label: "Middle Name",
        "element-key": 'patient-middle-name-' + _ctx.elementKey,
        required: !!_ctx.meta?.middleName?.required,
        validation: _ctx.validations.middleName,
        invalid: !!_ctx.fieldsMeta?.middleName?.dirty && !_ctx.fieldsMeta?.middleName.valid,
        invalidHintText: "Although this field is NOT required, when present, it must be 35 characters or less.",
        value: _ctx.values.middleName || undefined,
        onOnInput: _ctx.inputHandlers.middleName,
        onOnValidate: _ctx.validationHandlers.middleName
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_text_input, {
        label: "Last Name",
        "element-key": 'patient-last-name-' + _ctx.elementKey,
        required: !!_ctx.meta?.lastName?.required,
        validation: _ctx.validations.lastName,
        invalid: !!_ctx.fieldsMeta?.lastName?.dirty && !_ctx.fieldsMeta?.lastName.valid,
        invalidHintText: "This field is required and must be 35 characters or less.",
        value: _ctx.values.lastName,
        onOnInput: _ctx.inputHandlers.lastName,
        onOnValidate: _ctx.validationHandlers.lastName
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_date_input, {
        label: "Date of Birth",
        "element-key": 'patient-dob-' + _ctx.elementKey,
        max: new Date().toISOString(),
        required: !!_ctx.meta?.dob?.required,
        validation: _ctx.validations.dob,
        invalid: !!_ctx.fieldsMeta?.dob?.dirty && !_ctx.fieldsMeta?.dob.valid,
        value: _ctx.values.dob,
        onOnInput: _ctx.inputHandlers.dob,
        onOnValidate: _ctx.validationHandlers.dob
      }, null, 8, ["element-key", "max", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_select_input, {
        label: "Gender",
        "element-key": 'patient-gender-' + _ctx.elementKey,
        placeholder: "Select Gender",
        "option-label-key": "label",
        "option-value-key": "value",
        options: _ctx.genderOptions,
        required: !!_ctx.meta?.gender?.required,
        validation: _ctx.validations.gender,
        invalid: !!_ctx.fieldsMeta?.gender?.dirty && !_ctx.fieldsMeta?.gender.valid,
        value: _ctx.values.gender,
        onOnSelection: _ctx.inputHandlers.gender,
        onOnValidate: _ctx.validationHandlers.gender
      }, null, 8, ["element-key", "options", "required", "validation", "invalid", "value", "onOnSelection", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_phone_input, {
        label: "Phone",
        "element-key": 'patient-phone-' + _ctx.elementKey,
        required: !!_ctx.meta?.phone?.required,
        invalid: !!_ctx.fieldsMeta?.phone?.dirty && !_ctx.fieldsMeta?.phone.valid,
        invalidHintText: "This field requires a valid phone number.",
        value: _ctx.values.phone || undefined,
        onOnInput: _ctx.inputHandlers.phone,
        onOnValidate: _ctx.validationHandlers.phone
      }, null, 8, ["element-key", "required", "invalid", "value", "onOnInput", "onOnValidate"])
    ])
  ], 8, _hoisted_1))
}