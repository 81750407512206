import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-218aa711"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "request-input reason-input" }
const _hoisted_3 = { class: "request-input notified-input" }
const _hoisted_4 = { class: "request-input additional-notes-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_radio_button = _resolveComponent("radio-button")!
  const _component_text_area_input = _resolveComponent("text-area-input")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'cancel-request-block-container-' + _ctx.elementKey,
    class: "cancel-request-block-container"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_select_input, {
        "element-key": 'reason-' + _ctx.elementKey,
        label: "Reason",
        options: _ctx.reasonOptions,
        "option-label-key": "label",
        "option-value-key": "value",
        required: !!_ctx.meta?.reason?.required,
        invalid: !!_ctx.fieldsMeta?.reason?.dirty && !_ctx.fieldsMeta?.reason.valid,
        value: _ctx.values.reason || undefined,
        onOnSelection: _ctx.inputHandlers.reason
      }, null, 8, ["element-key", "options", "required", "invalid", "value", "onOnSelection"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_radio_button, {
        "element-key": 'patient-notified-' + _ctx.elementKey,
        label: "Patient Notified",
        options: _ctx.notifiedOptions,
        required: !!_ctx.meta?.patientHasBeenNotified?.required,
        invalid: 
          !!_ctx.fieldsMeta?.patientHasBeenNotified?.dirty && !_ctx.fieldsMeta?.patientHasBeenNotified.valid
        ,
        value: _ctx.values.patientHasBeenNotified || undefined,
        onOnSelection: _ctx.inputHandlers.patientHasBeenNotified
      }, null, 8, ["element-key", "options", "required", "invalid", "value", "onOnSelection"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_text_area_input, {
        "element-key": 'notes-' + _ctx.elementKey,
        label: "Additional Notes",
        validation: _ctx.validations.additionalNotes,
        required: !!_ctx.meta?.additionalNotes?.required,
        invalid: !!_ctx.fieldsMeta?.additionalNotes?.dirty && !_ctx.fieldsMeta?.additionalNotes.valid,
        value: _ctx.values.additionalNotes || undefined,
        onOnInput: _ctx.inputHandlers.additionalNotes,
        onOnValidate: _ctx.validationHandlers.additionalNotes
      }, null, 8, ["element-key", "validation", "required", "invalid", "value", "onOnInput", "onOnValidate"])
    ])
  ], 8, _hoisted_1))
}