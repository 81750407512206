<template>
  <div role="group" id="review-group" class="section-container">
    <div class="form-group" aria-labelledby="review-section-legend">
      <div id="review-section-legend" class="form-legend">Review</div>
      <div id="review-section-fields">
        <div class="review-submission">
          <div v-if="eligibilityTransactionEnabled" class="submission-btn">
            <button
              @click="submitEligibility()"
              id="submit-eligibility-button"
              class="wl-button"
              :disabled="!eligibilitySubmissionButtonEnabled"
            >
              <span>Submit Eligibility Request</span>
            </button>
            <div v-if="isSubmittingEligibility" class="review-submission-loading">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div
            v-if="paTransactionEnabled || (!paTransactionEnabled && !eligibilityTransactionEnabled)"
            class="submission-btn"
          >
            <button
              @click="submitPriorAuth()"
              id="submit-prior-auth-button"
              class="wl-button"
              :disabled="!priorAuthSubmissionButtonEnabled"
            >
              <span>Submit Prior Auth Request</span>
            </button>
            <div v-if="isSubmittingPriorAuth" class="review-submission-loading">
              <div class="loading-spinner"></div>
            </div>
          </div>
        </div>
        <div
          v-if="eligibilityResponse && !submissionId && !eligibilityErrorMessage"
          class="prior-auth-message"
        >
          {{
            priorAuthIsRequiredFromEligibilityResponse
              ? 'Prior authorization is required for this drug.'
              : 'Prior authorization is not required for this drug.'
          }}
        </div>
        <div v-if="!!eligibilityResponse">
          <div class="collapse-view">
            <div class="callapse-view-panel">
              <button class="collapse-button" @click="toggleEligibilityView">
                {{ eligibilityViewOpen ? '-' : '+' }}
              </button>
              <strong @click="toggleEligibilityView">Eligibility and Benefits</strong>
            </div>
            <transition name="fade">
              <div v-if="eligibilityViewOpen" class="collapse-view-body">
                <table-view
                  v-if="eligibilityResponse?.patient"
                  :data="eligibilityResponse.patient"
                  :title="'Patient'"
                  :use-nested-tables="false"
                />
                <table-view
                  v-if="eligibilityResponse?.patient?.address"
                  :data="eligibilityResponse.patient.address"
                  :title="'Patient Address'"
                  :use-nested-tables="false"
                />
                <table-view
                  v-if="eligibilityResponse?.insurance"
                  :data="eligibilityResponse.insurance"
                  :title="'Insurance'"
                  :use-nested-tables="false"
                />
                <table-view
                  v-if="eligibilityResponse?.insurance?.plan"
                  :data="eligibilityResponse.insurance.plan"
                  :title="'Insurance Plan'"
                  :use-nested-tables="false"
                />
                <table-view
                  v-if="eligibilityResponse?.insurance?.plan?.effectiveDates"
                  :data="eligibilityResponse.insurance.plan.effectiveDates"
                  :title="'Insurance Plan Effective Dates'"
                />
                <table-view
                  v-if="eligibilityResponse?.prescriber"
                  :data="eligibilityResponse.prescriber"
                  :title="'Prescriber'"
                  :use-nested-tables="false"
                />
                <table-view
                  v-if="eligibilityResponse?.prescriber?.address"
                  :data="eligibilityResponse.prescriber.address"
                  :title="'Prescriber Address'"
                  :use-nested-tables="false"
                />
                <table-view
                  v-if="eligibilityResponse?.medicationRequestedInformation?.medicationRequested"
                  :data="eligibilityResponse.medicationRequestedInformation.medicationRequested"
                  :title="'Medication Requested'"
                />
                <div
                  v-for="(coverage, coverageIndex) in eligibilityResponse
                    ?.medicationRequestedInformation?.patientSpecificPricingCoverages"
                  class="table-container"
                  :key="'coverage-' + (coverageIndex + 1)"
                >
                  <div class="table-header">
                    <h3>{{ 'Patient Coverages - ' + (coverageIndex + 1) }}</h3>
                  </div>
                  <div class="table-content">
                    <table-view
                      v-if="coverage?.pharmacy"
                      :data="coverage.pharmacy"
                      title="Pharmacy"
                    />
                    <table-view
                      v-if="coverage?.pricingCoverage"
                      :data="coverage.pricingCoverage"
                      title="Pricing Coverage"
                      :ignore-keys="['coverageAlerts']"
                    />
                    <table-view
                      v-if="
                        coverage?.pricingCoverage?.coverageAlerts &&
                        coverage?.pricingCoverage?.coverageAlerts?.length
                      "
                      :data="coverage.pricingCoverage.coverageAlerts"
                      title=" Coverage Alerts"
                    />
                  </div>
                </div>
                <div
                  class="table-container"
                  v-for="(alternateMedication, alternateIndex) in eligibilityResponse
                    ?.medicationRequestedInformation?.alternativeMedicationInformation"
                  :key="'alternate-medication-' + (alternateIndex + 1)"
                >
                  <div class="table-header">
                    <h3>{{ 'Alternative Medication - ' + (alternateIndex + 1) }}</h3>
                  </div>
                  <div class="table-content">
                    <table-view
                      v-if="alternateMedication?.alternativeMedication"
                      :data="alternateMedication.alternativeMedication"
                      title="Information"
                    />
                    <table-view
                      v-if="alternateMedication?.patientSpecificPricingCoverages?.pharmacy"
                      :data="alternateMedication.patientSpecificPricingCoverages.pharmacy"
                      title="Pharmacy"
                    />
                    <table-view
                      v-if="alternateMedication?.patientSpecificPricingCoverages?.pricingCoverage"
                      :data="alternateMedication.patientSpecificPricingCoverages.pricingCoverage"
                      title="Pricing Coverage"
                      :ignore-keys="['coverageAlerts']"
                    />
                    <table-view
                      v-if="
                        alternateMedication?.patientSpecificPricingCoverages?.pricingCoverage
                          ?.coverageAlerts
                      "
                      :data="
                        alternateMedication.patientSpecificPricingCoverages.pricingCoverage
                          .coverageAlerts
                      "
                      title="Coverage Alerts"
                    />
                  </div>
                </div>
                <div class="w-full" v-if="!!eligibilityErrorMessage">
                  <div class="review-section-eligibility-benefits-error" v-if="eligibilityViewOpen">
                    <span>Error: {{ eligibilityErrorMessage }}</span>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="!!eligibilityErrorMessage && !eligibilityResponse">
          <div class="collapse-view">
            <div class="callapse-view-panel">
              <button class="collapse-button" @click="toggleEligibilityView">
                {{ eligibilityViewOpen ? '-' : '+' }}
              </button>
              <strong @click="toggleEligibilityView">Eligibility and Benefits</strong>
            </div>
            <transition name="fade">
              <div class="review-section-eligibility-benefits-error" v-if="eligibilityViewOpen">
                <span>Error: {{ eligibilityErrorMessage }}</span>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="!!submissionId">
          <div class="collapse-view">
            <div class="callapse-view-panel">
              <button class="collapse-button" @click="togglePriorAuthView">
                {{ priorAuthViewOpen ? '-' : '+' }}
              </button>
              <strong @click="togglePriorAuthView">Prior Authorization</strong>
            </div>
            <transition name="fade">
              <div v-if="priorAuthViewOpen" class="collapse-view-body">
                <div class="review-section-success">
                  <span>Submission ID: {{ submissionId }}</span>
                  <fa-icon
                    id="review-id-copy-icon"
                    class="review-copy-icon"
                    icon="fa-solid fa-copy"
                    @click="copyId()"
                  ></fa-icon>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { TransactionOptions } from '@/models/wizard/transactions'
import TableView from '@/components/shared/TableView.vue'

export default defineComponent({
  name: 'ReviewSection',
  components: {
    TableView,
  },
  setup() {
    const store = useStore()
    const transactions = computed(() => store.getters['wizard/getTransactions'])
    const submission = computed(() => store.getters['submission/getSubmission'])
    const clientNPI = computed(() => store.getters['submission/getNPI'])
    const eligibilityResponse = computed(() => store.getters['eligibility/getEligibilityResponse'])
    const priorAuthIsRequiredFromEligibilityResponse = computed(
      () => store.getters['eligibility/getPriorAuthIsRequiredFromEligibilityResponse']
    )
    const submissionId = computed<string | null>(() => store.getters['getSubmissionId'])
    const eligibilityErrorMessage = computed(() => store.getters['eligibility/getErrorMessage'])
    const paTransactionEnabled = computed(
      () => !transactions.value.length || transactions.value.includes(TransactionOptions.PRIOR_AUTH)
    )
    const eligibilityTransactionEnabled = computed(() =>
      transactions.value.includes(TransactionOptions.ELIGIBILITY_AND_BENEFITS)
    )
    const isValidForSubmission = computed(
      () =>
        store.getters['wizard/isDetailsSectionValid'] &&
        store.getters['wizard/isPatientSectionValid'] &&
        store.getters['wizard/isPrescriberSectionValid'] &&
        store.getters['wizard/isMedicationPrescribedSectionValid'] &&
        store.getters['wizard/isPharmacySectionValid'] &&
        (paTransactionEnabled.value
          ? !!submission.value.prescriber?.firstName && !!submission.value.prescriber?.lastName
          : true)
    )
    const eligibilitySubmissionButtonEnabled = computed(
      () =>
        !!isValidForSubmission.value &&
        !!eligibilityTransactionEnabled.value &&
        !eligibilityResponse.value &&
        !eligibilityErrorMessage.value
    )
    const priorAuthSubmissionButtonEnabled = computed(
      () =>
        (eligibilityTransactionEnabled.value && paTransactionEnabled.value
          ? !!eligibilityResponse.value &&
            !eligibilityErrorMessage.value &&
            !!priorAuthIsRequiredFromEligibilityResponse.value
          : paTransactionEnabled.value) &&
        isValidForSubmission.value &&
        !submissionId.value
    )
    const isSubmittingPriorAuth = ref<boolean>(false)
    const isSubmittingEligibility = ref<boolean>(false)
    const eligibilityViewOpen = ref<boolean>(true)
    const priorAuthViewOpen = ref<boolean>(true)

    const toggleEligibilityView = () => {
      eligibilityViewOpen.value = !eligibilityViewOpen.value
    }
    const togglePriorAuthView = () => {
      priorAuthViewOpen.value = !priorAuthViewOpen.value
    }

    const submitEligibility = async () => {
      isSubmittingEligibility.value = true
      if (transactions.value?.includes(TransactionOptions.ELIGIBILITY_AND_BENEFITS)) {
        await store
          .dispatch('eligibility/submitEligibilityRequest', {
            npi: clientNPI.value,
            request: submission.value,
          })
          .catch(error => {
            console.error('Error submitting eligibility request', error)
          })
      }

      if (!paTransactionEnabled.value && priorAuthIsRequiredFromEligibilityResponse.value) {
        store.commit('wizard/setTransactions', [
          TransactionOptions.ELIGIBILITY_AND_BENEFITS,
          TransactionOptions.PRIOR_AUTH,
        ])
      }

      isSubmittingEligibility.value = false
    }

    const submitPriorAuth = async () => {
      isSubmittingPriorAuth.value = true
      eligibilityViewOpen.value = false
      if (
        !transactions.value.length ||
        transactions.value.includes(TransactionOptions.PRIOR_AUTH)
      ) {
        const result = await store.dispatch('submission/initialSubmission')
        if (result) {
          store.commit('setSubmissionIdOnRoot', result)
        }
      }
      isSubmittingPriorAuth.value = false
    }

    const copyId = () => {
      if (submissionId.value) {
        navigator.clipboard.writeText(submissionId.value)
      }
    }

    onMounted(() => {
      store.commit('wizard/setReviewSectionVisited', true)
    })

    return {
      eligibilityResponse,
      eligibilityViewOpen,
      eligibilitySubmissionButtonEnabled,
      isValidForSubmission,
      isSubmittingEligibility,
      isSubmittingPriorAuth,
      submissionId,
      priorAuthViewOpen,
      priorAuthSubmissionButtonEnabled,
      priorAuthIsRequiredFromEligibilityResponse,
      paTransactionEnabled,
      eligibilityTransactionEnabled,
      eligibilityErrorMessage,

      copyId,
      submitEligibility,
      submitPriorAuth,
      toggleEligibilityView,
      togglePriorAuthView,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@myndshft/styles/src/components/wl-button-mixin.scss';

.w-full {
  width: 100%;
}

.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
}

.provider,
.priority {
  padding: 0 30px 20px 0;
}

.prior-auth-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  color: white;
  background: green;
  margin: 10px 0;
  border: solid 1px darkgreen;
  border-radius: 5px;
}

.prior-auth-message-open {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  color: green;
  border: solid 1px darkgreen;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.wl-button-sm {
  @include wl-button-mixin($width: 4.047rem, $height: 30px, $primary: var(--primary-color));
  margin: 0 5px;
}

.review-submission {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.submission-btn {
  display: inline-flex;
  align-items: center;
  padding: 8px 10px;
}

.review-submission-loading {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: fit-content;
  .loading-spinner {
    margin: unset;
    padding: unset;
  }
}

.review-section-success {
  display: inline-flex;
  cursor: default;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  align-items: center;
  background: green;
  color: white;
}

.review-section-eligibility-benefits-error {
  display: inline-flex;
  cursor: default;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  align-items: center;
  background: black;
  color: white;
}

.review-copy-icon {
  margin: 0 10px;
  :hover {
    cursor: pointer;
    transform: translateY(-40px);
  }
  :active {
    color: lightgray;
  }
}

.collapse-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin: 10px 0;
  border: 1px solid #ededed;
  border-radius: 6px;
}

.callapse-view-panel {
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  color: #363636;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
  cursor: default;
}

.collapse-view-body {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 2px;
  ul {
    width: 500px;
  }
}

.collapse-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #999;
  color: #fff;
  border: none;
  border-radius: 3px;
  margin-right: 5px;
  padding: 0;
  width: 20px;
  height: 20px;
}

.table-container {
  width: 100%;
  display: block;
  padding: 5px;
}

.table-header {
  font-style: normal;
  letter-spacing: normal;
  font-weight: 600;
  color: #000;
  background-color: #ededed;
  padding: 0.625rem;
}

.table-content {
  border: 1px solid #dee2e6;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
