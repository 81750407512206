import { defaultSubmissionState, State } from './state'
import {
  AddressModels,
  DiagnosisModels,
  PatientModels,
  PharmacyModels,
  PrescriberModels,
  PriorityModels,
  PrescriptionDrugModels,
} from '@/models/submission'
import { deepMerge } from '@/utils'

const addSecondaryDiagnosis = (state: State) => {
  state.submission.medicationPrescribed.diagnosis.secondary = {
    code: null,
    qualifier: null,
    description: null,
  } as DiagnosisModels.DiagnosisCode
}

const removeSecondaryDiagnosis = (state: State) => {
  state.submission.medicationPrescribed.diagnosis.secondary = null
}

const setPatient = (state: State, value: PatientModels.PatientPartial) => {
  state.submission.patient = deepMerge(state.submission.patient, value)
}

const setPatientInformation = (state: State, value: PatientModels.PatientPartial) => {
  state.submission.patient = deepMerge(state.submission.patient, value)
}

const setPatientAddress = (state: State, value: AddressModels.AddressPartial) => {
  state.submission.patient = deepMerge(state.submission.patient, { address: value })
}

const setPharmacyInformation = (state: State, value: PharmacyModels.PharmacyInformation) => {
  state.submission.pharmacy = deepMerge(state.submission.pharmacy, value)
}

const setPrescriber = (state: State, value: PrescriberModels.PrescriberPartial) => {
  state.submission.prescriber = deepMerge(state.submission.prescriber, value)
}

const clearPrescriber = (state: State) => {
  state.submission.prescriber = {
    firstName: null,
    lastName: null,
    npi: null,
    phone: null,
    fax: null,
    address: {
      lineOne: null,
      lineTwo: null,
      city: null,
      state: null,
      zip: null,
    },
  }
}

const setPrescriberInformation = (
  state: State,
  value: PrescriberModels.PrescriberInformationPartial
) => {
  state.submission.prescriber = deepMerge(state.submission.prescriber, value)
}

const setPrescriberAddress = (state: State, value: AddressModels.AddressPartial) => {
  state.submission.prescriber = deepMerge(state.submission.prescriber, { address: value })
}

const setMedicationPrescribed = (
  state: State,
  value: PrescriptionDrugModels.PartialPrescriptionDrug
) => {
  state.submission.medicationPrescribed = deepMerge(state.submission.medicationPrescribed, value)
}

const setMedicationProduct = (
  state: State,
  value: PrescriptionDrugModels.PartialPrescriptionProduct
) => {
  state.submission.medicationPrescribed = deepMerge(state.submission.medicationPrescribed, {
    product: value,
  })
}

const setMedicationQuantity = (
  state: State,
  value: PrescriptionDrugModels.PartialPrescriptionQuantity
) => {
  state.submission.medicationPrescribed = deepMerge(state.submission.medicationPrescribed, {
    quantity: value,
  })
}

const setMedicationDiagnosis = (state: State, value: DiagnosisModels.PartialDiagnosis) => {
  state.submission.medicationPrescribed = deepMerge(state.submission.medicationPrescribed, {
    diagnosis: deepMerge(state.submission.medicationPrescribed.diagnosis, value),
  })
}

const setMedicationDiagnosisPrimaryCode = (
  state: State,
  value: DiagnosisModels.PartialDiagnosisCode
) =>
  setMedicationDiagnosis(
    state,
    deepMerge(state.submission.medicationPrescribed.diagnosis, { primary: value })
  )

const setMedicationDiagnosisSecondaryCode = (
  state: State,
  value: DiagnosisModels.PartialDiagnosisCode
) =>
  setMedicationDiagnosis(
    state,
    deepMerge(state.submission.medicationPrescribed.diagnosis, { secondary: value })
  )

const setNPI = (state: State, value: string) => {
  state.npi = value
}

const setPriority = (state: State, value: PriorityModels.PriorityStrings) => {
  state.submission.priority = value
}

const clearSubmission = (state: State) => {
  state.submission = { ...defaultSubmissionState() }
}

export default {
  addSecondaryDiagnosis,
  clearPrescriber,
  clearSubmission,
  removeSecondaryDiagnosis,
  setMedicationPrescribed,
  setMedicationDiagnosis,
  setMedicationDiagnosisPrimaryCode,
  setMedicationDiagnosisSecondaryCode,
  setMedicationProduct,
  setMedicationQuantity,
  setNPI,
  setPatient,
  setPatientAddress,
  setPatientInformation,
  setPharmacyInformation,
  setPrescriber,
  setPrescriberAddress,
  setPrescriberInformation,
  setPriority,
}
