<template>
  <div id="prescriber-group">
    <form-group-section element-key="prescriber-information" section-label="Prescriber Information">
      <div v-if="loadingPrescriberData" class="loading-background-section">
        <div class="loading-spinner"></div>
      </div>
      <prescriber-information-block
        v-else
        element-key="prescriber-information-block"
        :prescriber="prescriber"
        :meta="prescriberMeta"
        :transactions="transactions"
        @onInput="prescriberInformationInputHandler"
        @onMeta="prescriberInformationMetaHandler"
      ></prescriber-information-block>
    </form-group-section>
    <form-group-section element-key="prescriber-address" section-label="Prescriber Address">
      <div v-if="loadingPrescriberData" class="loading-background-section">
        <div class="loading-spinner"></div>
      </div>
      <address-block
        v-else
        element-key="prescriber-address-block"
        :state-options="options.stateOptions"
        :meta="prescriberMeta.address"
        :address="prescriber.address || undefined"
        :data-public="true"
        @onInput="prescriberAddressInputHandler"
        @onMeta="prescriberAddressMetaHandler"
      ></address-block>
    </form-group-section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue'
import { AddressBlock, PrescriberInformationBlock } from '@/components/wizard/blocks'
import { FormGroupSection } from '@/components/shared'
import * as options from '@/modules/options'
import { useStore } from 'vuex'
import { WizardStateModels } from '@/models/store'
import { AddressModels, PrescriberModels } from '@/models/submission'
import { inputValidations } from '@/modules/validations'

export default defineComponent({
  name: 'PrescriberSection',
  components: { AddressBlock, FormGroupSection, PrescriberInformationBlock },
  setup() {
    let timeout: number | null = null
    const store = useStore()
    const transactions = computed(() => store.getters['wizard/getTransactions'])
    const prescriber = computed<PrescriberModels.Prescriber>(
      () => store.getters['submission/getPrescriber']
    )
    const prescriberMeta = computed<WizardStateModels.WizardPrescriberFields>(
      () => store.getters['wizard/getPrescriberSectionMeta']
    )
    const loadingPrescriberData = ref<boolean>(false)

    const prescriberInformationInputHandler = (
      prescriberInformationValue: PrescriberModels.PrescriberInformation
    ): void => {
      if (
        prescriberInformationValue.npi &&
        inputValidations.npiValidation(prescriberInformationValue.npi) &&
        prescriberInformationValue.npi !== prescriber.value.npi
      ) {
        loadingPrescriberData.value = true
        store.commit('submission/clearPrescriber')
        store.commit('wizard/resetPrescriberMeta')
        store
          .dispatch('wizard/fetchPrescribingProvider', prescriberInformationValue.npi)
          .then(prescriberData => {
            if (prescriberData) {
              store.commit('submission/setPrescriber', prescriberData)
              timeout = window.setTimeout(() => (loadingPrescriberData.value = false), 500)
            } else {
              store.commit('submission/setPrescriberInformation', {
                npi: prescriberInformationValue.npi,
              })
              timeout = window.setTimeout(() => (loadingPrescriberData.value = false), 500)
            }
          })
          .catch(() =>
            store.commit('submission/setPrescriberInformation', prescriberInformationValue)
          )
      } else {
        store.commit('submission/setPrescriberInformation', prescriberInformationValue)
      }
    }

    const prescriberInformationMetaHandler = (
      prescriberInformationMeta: WizardStateModels.WizardPrescriberInformationFields
    ): void => {
      store.commit('wizard/setPrescriberInformationMeta', prescriberInformationMeta)
    }

    const prescriberAddressInputHandler = (addressValue: AddressModels.Address): void => {
      store.commit('submission/setPrescriberAddress', addressValue)
    }

    const prescriberAddressMetaHandler = (
      addressMeta: WizardStateModels.WizardAddressFields
    ): void => {
      store.commit('wizard/setPrescriberAddressMeta', addressMeta)
    }

    onBeforeUnmount(() => {
      if (timeout) {
        window.clearTimeout(timeout)
      }
    })

    return {
      loadingPrescriberData,
      options,
      prescriber,
      prescriberMeta,
      transactions,

      prescriberInformationInputHandler,
      prescriberInformationMetaHandler,
      prescriberAddressInputHandler,
      prescriberAddressMetaHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
}

.loading-background-section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 50px;
  width: 100%;
  z-index: 2;
}
</style>
