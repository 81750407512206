import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a917c58c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "data-required"]
const _hoisted_3 = ["id", "value", "data-error", "data-required", "disabled", "placeholder", "data-public"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'textarea-container-' + _ctx.elementKey,
    class: "textarea-input-container"
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          id: 'textarea-container-label-' + _ctx.elementKey,
          class: "form-field-label",
          "data-required": _ctx.required
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      id: 'textarea-container-input-' + _ctx.elementKey,
      class: "form-field",
      autocomplete: "off",
      rows: "4",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputHandler && _ctx.inputHandler(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args))),
      value: _ctx.inputValue,
      "data-error": _ctx.invalid,
      "data-required": _ctx.dataRequired,
      disabled: _ctx.disabled,
      placeholder: _ctx.disabled ? '' : _ctx.placeholder,
      "data-public": !!_ctx.dataPublic || undefined
    }, null, 40, _hoisted_3),
    (_ctx.invalid && _ctx.invalidHintText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            id: 'textarea-container-input-invalid-hint-' + _ctx.elementKey,
            class: "invalid-hint"
          }, " *" + _toDisplayString(_ctx.invalidHintText), 9, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}