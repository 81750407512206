import { State } from './state'
import { ConfigStateModels, SettingStateModels } from '@/models/store'

const setConfig = (state: State, value: ConfigStateModels.Config) => {
  state.chatbotKey = value.chatbotKey
  state.environment = value.environment
  state.expirationConfig = value.expirationConfig
  state.settings = value.settings
  state.tenantAuth = value.tenantAuth
  state.version = value?.version || null
  state.termsUrl = value?.termsUrl || null
}

const setExpirationConfig = (state: State, value: ConfigStateModels.ExpirationConfig) => {
  state.expirationConfig = { ...value }
}

const setSettings = (state: State, value: SettingStateModels.Settings) => {
  state.settings = {} as SettingStateModels.Settings
  state.settings.id = value?.id || null
  state.settings.banner = (value?.banner ? { ...value.banner } : {}) as SettingStateModels.Image
  state.settings.logo = (value?.logo ? { ...value.logo } : {}) as SettingStateModels.ImagePath
  state.settings.colors = (value?.colors ? { ...value.colors } : {}) as SettingStateModels.Colors
  state.settings.browserTab = (
    value?.browserTab ? { ...value.browserTab } : {}
  ) as SettingStateModels.Image
  state.settings.font = (value?.font ? { ...value.font } : {}) as SettingStateModels.Font
}

export default { setConfig, setExpirationConfig, setSettings }
