import { Commit } from 'vuex'
import { EligibilityStateModels } from '@/models/store'
import { Icon, notify } from '@/modules/notification'

import SubmissionService from '@/services/submission'

const ERROR_MESSAGE_TITLE = 'Oops! Something happened!'
const REQUIRED = 'REQUIRED'

const getPriorAuthRequiredFromEligibilityResponse = (response: any): boolean => {
  return response?.medicationRequestedInformation?.patientSpecificPricingCoverages?.some(
    (coverage: any) => {
      return coverage?.pricingCoverage?.priorAuthRequired === REQUIRED
    }
  )
}

const submitEligibilityRequest = async (
  {
    rootState,
    commit,
  }: {
    rootState: any
    commit: Commit
  },
  requestObject: { request: any; npi: string }
) => {
  const { request, npi } = requestObject
  const mappedRequest: EligibilityStateModels.BenefitsRequest = {
    patient: {
      firstName: request.patient.firstName,
      middleName: request.patient.middleName,
      lastName: request.patient.lastName,
      dateOfBirth: request.patient.dob,
      gender: request.patient.gender,
      phone: request.patient.phone,
      address: {
        lineOne: request.patient.address.lineOne,
        lineTwo: request.patient.address.lineTwo,
        city: request.patient.address.city,
        state: request.patient.address.state,
        zip: request.patient.address.zip,
      },
    },
    prescriber: {
      firstName: request.prescriber.firstName,
      lastName: request.prescriber.lastName,
      clinicName: request.prescriber.organizationName,
      npi: request.prescriber.npi,
      fax: request.prescriber.fax,
      phone: request.prescriber.phone,
      address: {
        lineOne: request.prescriber.address.lineOne,
        lineTwo: request.prescriber.address.lineTwo,
        city: request.prescriber.address.city,
        state: request.prescriber.address.state,
        zip: request.prescriber.address.zip,
      },
    },
    medicationRequested: {
      drugCode: {
        value: request.medicationPrescribed.product.code,
        qualifier: null,
      },
      quantity: {
        value: request.medicationPrescribed.quantity.value,
        unitOfMeasure: request.medicationPrescribed.quantity.unitOfMeasure,
        codeListQualifier: null,
      },
      substitutions: request.medicationPrescribed.substitutions,
      drugDescription: request.medicationPrescribed.description,
      daysSupply: request.medicationPrescribed.daysSupply,
    },
    pharmacy: {
      npi: request.pharmacy.npi,
      ncpdpId: request.pharmacy.ncpdpId,
      type: null,
    },
  }

  const response: any = await SubmissionService.postEligibilitySubmission(
    mappedRequest,
    rootState.authToken,
    rootState.csrfToken,
    npi
  ).catch((error: any) => {
    notify(error.message, ERROR_MESSAGE_TITLE, Icon.ERROR)
    return { error: true, message: error.message }
  })

  if (response?.data && !response?.error) {
    const priorAuthRequired = getPriorAuthRequiredFromEligibilityResponse(response.data)
    commit('setPriorAuthIsRequiredFromEligibilityResponse', priorAuthRequired)
    commit('setEligibilityResponse', response.data)
  } else if (response?.data) {
    commit('setEligibilityResponse', response.data)
  }

  if (response?.error) {
    commit('setErrorMessage', response?.error)
  }

  return true
}

export default { submitEligibilityRequest }
