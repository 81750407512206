import { State } from './state'
import {
  AddressModels,
  PatientModels,
  PharmacyModels,
  PrescriberModels,
  PrescriptionDrugModels,
  PriorityModels,
} from '@/models/submission'
import { SubmissionStateModels } from '@/models/store'
import { deepClone } from '@/utils'

const getNPI = (state: State): string | null => {
  return state.npi
}

const getPatient = (state: State): PatientModels.Patient => {
  return deepClone(state.submission.patient)
}

const getPatientAddress = (state: State): AddressModels.Address => {
  return deepClone(state.submission.patient.address)
}

const getPriority = (state: State): PriorityModels.PriorityStrings | null => {
  return state.submission.priority
}

const getPrescriber = (state: State): PrescriberModels.Prescriber => {
  return deepClone(state.submission.prescriber)
}

const getDetailsSection = (state: State): SubmissionStateModels.DetailsSection => {
  return { npi: state.npi, priority: state.submission.priority }
}

const getSubmission = (state: State): SubmissionStateModels.Submission => {
  return deepClone(state.submission)
}

const getMedicationPrescribed = (state: State): PrescriptionDrugModels.PrescriptionDrug => {
  return deepClone(state.submission.medicationPrescribed)
}

const getPharmacyInformation = (state: State): PharmacyModels.PharmacyInformation => {
  return state.submission.pharmacy
}

export default {
  getDetailsSection,
  getMedicationPrescribed,
  getNPI,
  getPatient,
  getPatientAddress,
  getPharmacyInformation,
  getPrescriber,
  getPriority,
  getSubmission,
}
