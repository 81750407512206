<template>
  <div
    class="menu-item"
    :class="{
      selected: isSelected(section),
      valid: isValid(section),
      disabled: isDisabled(section),
    }"
  >
    <router-link class="link" :to="{ name: section?.name }">{{ section?.name }}</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, UnwrapRef } from 'vue'
import { RouteRecordRaw } from 'vue-router'

export default defineComponent({
  name: 'WizardMenuItem',
  props: {
    section: Object as PropType<UnwrapRef<RouteRecordRaw>>,
    validSections: {
      type: Object,
      default: () => ({} as any),
    },
    reviewVisited: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isDisabled = (section: any) => section?.meta?.disabled || false
    const isSelected = (section: any) => section?.meta?.selected || false
    const isValid = (section: any) => {
      return props.validSections?.[section.path] || !props.reviewVisited
    }
    return {
      isDisabled,
      isSelected,
      isValid,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.menu-item {
  cursor: pointer;
  user-select: none;
  background: #ffe6e6;
  border-radius: 4px;
  padding: 6px 16px;
  margin-bottom: 4px;
  transition: 50ms ease-out;
  outline: none;

  .link {
    color: #383838;
    text-decoration: none;
    &:visited {
      color: inherit;
    }
  }

  &.valid {
    color: $myndshft-light-black;
    background: $myndshft-white;
  }
  &.selected {
    font-weight: 600;
    background: var(--secondary-color);
    .link {
      color: $myndshft-white;
      text-decoration: none;
      &::after {
        color: $myndshft-white;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
