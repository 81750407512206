import {
  AddressModels,
  DiagnosisModels,
  PharmacyModels,
  PrescriptionDrugModels,
  PrescriberModels,
  PatientModels,
} from '@/models/submission'
import { SubmissionStateModels } from '@/models/store'

const defaultAddressState = (): AddressModels.Address => ({
  lineOne: null,
  lineTwo: null,
  city: null,
  state: null,
  zip: null,
})

const defaultPatientState = (): PatientModels.Patient => ({
  firstName: null,
  middleName: null,
  lastName: null,
  dob: null,
  gender: null,
  phone: null,
  address: defaultAddressState(),
})

const defaultPrescriber = (): PrescriberModels.Prescriber => ({
  firstName: null,
  lastName: null,
  organizationName: null,
  npi: null,
  phone: null,
  fax: null,
  address: defaultAddressState(),
})

const defaultPharmacyState = (): PharmacyModels.PharmacyInformation => ({
  npi: null,
  ncpdpId: null,
})

const defaultPrescriptionProduct = (): PrescriptionDrugModels.PrescriptionProduct => ({
  code: null,
})

const defaultPrescriptionQuantity = (): PrescriptionDrugModels.PrescriptionQuantity => ({
  value: null,
  unitOfMeasure: null,
  codeListQualifier: null,
})

const defaultDiagnosisCode = (): DiagnosisModels.DiagnosisCode => ({
  code: null,
  description: null,
  qualifier: null,
})

const defaultDiagnosis = (): DiagnosisModels.Diagnosis => ({
  clinicalInformationQualifier: null,
  primary: defaultDiagnosisCode(),
  secondary: null,
})

const defaultMedicationPrescribed = (): PrescriptionDrugModels.PrescriptionDrug => ({
  product: defaultPrescriptionProduct(),
  quantity: defaultPrescriptionQuantity(),
  daysSupply: null,
  substitutions: null,
  diagnosis: defaultDiagnosis(),
})

export const defaultSubmissionState = (): SubmissionStateModels.Submission => ({
  priority: 'STANDARD',
  patient: defaultPatientState(),
  medicationPrescribed: defaultMedicationPrescribed(),
  prescriber: defaultPrescriber(),
  pharmacy: defaultPharmacyState(),
})

export const defaultState = (): SubmissionStateModels.SubmissionBundle => ({
  submission: defaultSubmissionState(),
  npi: null,
})

export const state = { ...defaultState() }
export type State = typeof state
