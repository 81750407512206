<template>
  <div :id="'information-fields-' + elementKey" class="information-fields-container">
    <div class="information-input">
      <text-input
        label="Pharmacy NPI"
        invalidHintText="This field is required and must be a valid NPI."
        :element-key="'pharmacy-npi-' + elementKey"
        :required="!!meta?.npi?.required && !values.ncpdpId"
        :validation="validations.npi"
        :invalid="!!fieldsMeta?.npi?.dirty && !fieldsMeta?.npi.valid"
        :value="values.npi || undefined"
        :disabled="!!values.ncpdpId"
        @onInput="inputHandlers.npi"
        @onValidate="validationHandlers.npi"
      />
    </div>
    <div class="input-seperator">OR</div>
    <div class="information-input">
      <text-input
        label="NCPDP ID"
        invalidHintText="This field is required and must be a valid NCPDP ID."
        :element-key="'pharmacy-ncpdp-id-' + elementKey"
        :required="!!meta?.ncpdpId?.required && !values.npi"
        :validation="validations.ncpdpId"
        :invalid="!!fieldsMeta?.ncpdpId?.dirty && !fieldsMeta?.ncpdpId.valid"
        :value="values.ncpdpId || undefined"
        :disabled="!!values.npi"
        @onInput="inputHandlers.ncpdpId"
        @onValidate="validationHandlers.ncpdpId"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid'
import { defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import { TextInput } from '@/components/shared'
import { WizardStateModels } from '@/models/store'
import { PharmacyModels } from '@/models/submission'
import { inputValidations } from '@/modules/validations'

export default defineComponent({
  name: 'PharmacyInformationBlock',
  emits: ['on-input', 'on-meta'],
  components: { TextInput },
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    pharmacy: {
      type: Object as PropType<PharmacyModels.PharmacyInformation>,
      default: () => ({
        npi: null,
        ncpdpId: null,
      }),
    },
    meta: {
      type: Object as PropType<WizardStateModels.WizardPharmacyFields>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const values = ref<PharmacyModels.PharmacyInformation>({ ...props.pharmacy })
    const fieldsMeta = ref<WizardStateModels.WizardPharmacyFields>({ ...props.meta })
    const validations = {
      npi: (value: string): boolean => inputValidations.npiValidation(value),
      ncpdpId: (value: string | null): boolean => inputValidations.ncpdpValidation(value),
    }

    const setValues = (value: PharmacyModels.PharmacyInformation) => {
      values.value = { ...values.value, ...value }
    }

    const setMeta = (value: WizardStateModels.WizardPharmacyFields) => {
      fieldsMeta.value = { ...fieldsMeta.value, ...value }
    }

    const inputHandlers = {
      npi: (value: string) => {
        setValues({ npi: value, ncpdpId: values.value.ncpdpId })
        setMeta({ npi: { ...fieldsMeta.value.npi, dirty: true } })
      },
      ncpdpId: (value: string) => {
        setValues({ npi: values.value.npi, ncpdpId: value })
        setMeta({ ncpdpId: { ...fieldsMeta.value.ncpdpId, dirty: true } })
      },
    }

    const validationHandlers = {
      npi: (valid: boolean) => {
        if (valid) {
          setMeta({ ncpdpId: { ...fieldsMeta.value.ncpdpId, valid: true } })
        }
        setMeta({ npi: { ...fieldsMeta.value.npi, valid } })
      },
      ncpdpId: (valid: boolean) => {
        if (valid) {
          setMeta({ npi: { ...fieldsMeta.value.npi, valid: true } })
        }
        setMeta({ ncpdpId: { ...fieldsMeta.value.ncpdpId, valid } })
      },
    }

    watch(values, values => context.emit('on-input', values))
    watch(fieldsMeta, validation => context.emit('on-meta', validation))
    onBeforeMount(() => (values.value = props.pharmacy))

    return {
      fieldsMeta,
      inputHandlers,
      values,
      validations,
      validationHandlers,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/input';

.information-fields-container {
  display: flex;
  gap: 2em;
  flex-flow: row wrap;
}

.information-input {
  width: 324px;
}

.input-seperator {
  display: flex;
  align-items: flex-end;
  padding: 10px 0;
}
</style>
