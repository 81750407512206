<template>
  <div class="tos-container">
    <p class="copyright-info" v-if="showCopyright">
      &copy; {{ year }} Myndshft. All rights reserved.
      <a class="wl-link" :href="url" target="_blank">Terms of Service</a><br />
      <i>Version {{ version }}</i>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TermsOfService',
  props: {
    showCopyright: {
      type: Boolean,
      default: true,
    },
    version: {
      type: String,
      default: 'PLACEHOLDER',
    },
    url: {
      type: String,
      default: '#',
    },
  },
  setup() {
    const year = new Date().getFullYear()
    return {
      year,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@myndshft/styles/src/components/mixins.scss';

p {
  text-align: center;
}
</style>
