import { State } from './state'
import { EligibilityStateModels } from '@/models/store'

const getEligibilityResponse = (state: State): EligibilityStateModels.BenefitsResponse | null => {
  return state.eligibilityResponse
}

const getPriorAuthIsRequiredFromEligibilityResponse = (state: State): boolean => {
  return !!state.priorAuthIsRequiredFromEligibilityResponse
}

const getErrorMessage = (state: State): string | null => {
  return state.errorMessage
}

export default {
  getEligibilityResponse,
  getErrorMessage,
  getPriorAuthIsRequiredFromEligibilityResponse,
}
