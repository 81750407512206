import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menu-item", {
      selected: _ctx.isSelected(_ctx.section),
      valid: _ctx.isValid(_ctx.section),
      disabled: _ctx.isDisabled(_ctx.section),
    }])
  }, [
    _createVNode(_component_router_link, {
      class: "link",
      to: { name: _ctx.section?.name }
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.section?.name), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ], 2))
}