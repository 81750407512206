<template>
  <div :id="'fieldset-container-' + elementKey" class="fieldset-container">
    <fieldset :id="'fieldset-' + elementKey">
      <legend :id="'legend-' + elementKey" :color="color">{{ sectionLabel }}</legend>
      <slot></slot>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { v4 as uuid } from 'uuid'

export default defineComponent({
  name: 'FormFieldSetSection',
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    sectionLabel: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.form-group-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
}

fieldset {
  border: 1px solid $myndshft-dark-blue;
  border-radius: 4px;
  margin-bottom: 32px;

  & > legend {
    font-size: 14px;
    color: $myndshft-white;
    padding: 4px 16px;
    border-radius: 4px;
    &[color='primary'] {
      background: var(--primary-color);
    }
    &[color='secondary'] {
      background: var(--secondary-color);
    }
    &[color='tertiary'] {
      background: var(--tertiary-color);
    }
  }
}
</style>
