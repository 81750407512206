<template>
  <div class="message-root-container">
    <div class="message-container">
      <div class="message-display">
        <img
          class="logo"
          v-if="!!bannerUrl || !!bannerTitle"
          :src="bannerUrl || ''"
          :alt="`${bannerTitle || ''} ` + 'Logo'"
        />
        <h2 v-if="!!messageTitle" :titleAlign="titleAlign">{{ messageTitle }}</h2>
        <div class="message-lines" :messageAlign="messageAlign">
          <span v-if="!!messageLineOne" class="message">{{ messageLineOne }}</span>
          <span v-if="!!messageLineTwo" class="message">{{ messageLineTwo }}</span>
          <span v-if="!!messageLineThree" class="message">{{ messageLineThree }}</span>
        </div>

        <button class="wl-button" v-if="action !== null" @click="action">
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

type AlignmentOptions = 'center' | 'left' | 'right'

export default defineComponent({
  name: 'Message',
  props: {
    bannerUrl: {
      type: String,
      required: false,
    },
    bannerTitle: {
      type: String,
      required: false,
    },
    messageTitle: {
      type: String,
      required: false,
    },
    messageLineOne: {
      type: String,
      required: false,
    },
    messageLineTwo: {
      type: String,
      required: false,
    },
    messageLineThree: {
      type: String,
      required: false,
    },
    titleAlign: {
      type: String as PropType<AlignmentOptions>,
      default: 'center',
    },
    messageAlign: {
      type: String as PropType<AlignmentOptions>,
      default: 'center',
    },
    buttonLabel: {
      type: String,
      default: 'Continue',
    },
    action: {
      type: Function,
      default: null,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.message-root-container {
  display: flex;
  flex-direction: column;
}

.message-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message-display {
  display: flex;
  flex-direction: column;
  width: 500px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.11);
  padding: 20px;

  .message-lines {
    display: flex;
    flex-direction: column;
    word-break: normal;

    padding: 10px;
    width: 100%;

    &[messageAlign='left'] {
      text-align: left;
    }
    &[messageAlign='right'] {
      text-align: right;
    }
    &[messageAlign='center'] {
      text-align: center;
    }

    .message {
      width: 100%;
      color: $myndshft-notification-accent-red;
    }
  }

  img {
    margin: 20px;
  }
  h2 {
    color: $myndshft-notification-accent-red;
    margin-bottom: 5px;
    padding: 0 10px;

    &[titleAlign='left'] {
      text-align: left;
    }
    &[titleAlign='right'] {
      text-align: right;
    }
    &[titleAlign='center'] {
      text-align: center;
    }
  }
  button {
    margin: 15px;
    padding: 15px;
    width: fit-content;
    align-self: center;
  }
}
</style>
