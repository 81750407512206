<template>
  <div :id="'information-fields-' + elementKey" class="information-fields-container">
    <div class="information-input-group">
      <div class="information-input">
        <text-input
          label="NPI"
          invalidHintText="This field is required and must be a valid NPI."
          :element-key="'prescriber-npi-' + elementKey"
          :required="!!meta?.npi?.required"
          :validation="validations.npi"
          :invalid="!!fieldsMeta?.npi?.dirty && !fieldsMeta?.npi.valid"
          :value="values.npi || undefined"
          :data-public="true"
          @onInput="inputHandlers.npi"
          @onValidate="validationHandlers.npi"
        />
      </div>
    </div>
    <div class="information-input">
      <text-input
        label="First Name"
        invalidHintText="This field is required and must be 35 characters or less."
        :element-key="'prescriber-first-name-' + elementKey"
        :required="firstNameRequired"
        :validation="validations.firstName"
        :invalid="!!fieldsMeta?.firstName?.dirty && !fieldsMeta?.firstName.valid"
        :value="values?.firstName || undefined"
        :disabled="!!values?.organizationName && isOnlyBenefitsTransaction"
        :data-public="true"
        @onInput="inputHandlers.firstName"
        @onValidate="validationHandlers.firstName"
      />
    </div>
    <div class="information-input">
      <text-input
        label="Last Name"
        invalidHintText="This field is required and must be 35 characters or less."
        :element-key="'prescriber-last-name-' + elementKey"
        :required="lastNameRequired"
        :validation="validations.lastName"
        :invalid="!!fieldsMeta?.lastName?.dirty && !fieldsMeta?.lastName.valid"
        :value="values?.lastName || undefined"
        :disabled="!!values?.organizationName && isOnlyBenefitsTransaction"
        :data-public="true"
        @onInput="inputHandlers.lastName"
        @onValidate="validationHandlers.lastName"
      />
    </div>
    <span v-if="isOnlyBenefitsTransaction">OR</span>
    <div v-if="isOnlyBenefitsTransaction" class="information-input">
      <text-input
        label="Organization Name"
        invalidHintText="This field is required and must be 35 characters or less."
        :element-key="'prescriber-organization-name-' + elementKey"
        :required="organizationNameRequired"
        :validation="validations.organizationName"
        :invalid="!!fieldsMeta?.organizationName?.dirty && !fieldsMeta?.organizationName.valid"
        :value="values?.organizationName || undefined"
        :disabled="!!values?.firstName || !!values?.lastName"
        :data-public="true"
        @onInput="inputHandlers.organizationName"
        @onValidate="validationHandlers.organizationName"
      />
    </div>
    <div class="information-input">
      <phone-input
        label="Phone"
        invalidHintText="This field requires a valid phone number."
        :element-key="'prescriber-phone-' + elementKey"
        :required="!!meta?.phone?.required"
        :invalid="!!fieldsMeta?.phone?.dirty && !fieldsMeta?.phone.valid"
        :value="values?.phone || undefined"
        :data-public="true"
        @onInput="inputHandlers.phone"
        @onValidate="validationHandlers.phone"
      />
    </div>
    <div class="information-input">
      <phone-input
        label="Fax"
        invalidHintText="Although this field is NOT required, when present, it must be a valid phone number."
        :element-key="'prescriber-fax-' + elementKey"
        :required="!!meta?.fax?.required"
        :invalid="!!fieldsMeta?.fax?.dirty && !fieldsMeta?.fax.valid"
        :value="values?.fax || undefined"
        :data-public="true"
        @onInput="inputHandlers.fax"
        @onValidate="validationHandlers.fax"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid'
import { defineComponent, onBeforeMount, PropType, ref, watch, computed } from 'vue'
import { TextInput, PhoneInput } from '@/components/shared'
import { WizardStateModels } from '@/models/store'
import { PrescriberModels } from '@/models/submission'
import { inputValidations } from '@/modules/validations'
import { TransactionOptions } from '@/models/wizard/transactions'

export default defineComponent({
  name: 'PrescriberInformationBlock',
  emits: ['on-input', 'on-meta'],
  components: { TextInput, PhoneInput },
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    prescriber: {
      type: Object as PropType<PrescriberModels.PrescriberInformation>,
      default: () => ({
        firstName: null,
        lastName: null,
        organizationName: null,
        npi: null,
        phone: null,
        fax: null,
      }),
    },
    meta: {
      type: Object as PropType<WizardStateModels.WizardPrescriberInformationFields>,
      default: () => ({} as any),
    },
    transactions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const values = ref<PrescriberModels.PrescriberInformation>({ ...props.prescriber })
    const fieldsMeta = ref<WizardStateModels.WizardPrescriberInformationFields>({ ...props.meta })
    const isOnlyBenefitsTransaction = computed(
      () =>
        props.transactions?.includes(TransactionOptions.ELIGIBILITY_AND_BENEFITS) &&
        !props.transactions?.includes(TransactionOptions.PRIOR_AUTH)
    )
    const firstNameRequired = computed(
      () =>
        (!!props.meta?.firstName?.required &&
          !values.value?.organizationName &&
          isOnlyBenefitsTransaction.value) ||
        (!!props.meta?.firstName?.required && !isOnlyBenefitsTransaction.value)
    )
    const lastNameRequired = computed(
      () =>
        (!!props.meta?.lastName?.required &&
          !values.value?.organizationName &&
          isOnlyBenefitsTransaction.value) ||
        (!!props.meta?.lastName?.required && !isOnlyBenefitsTransaction.value)
    )
    const organizationNameRequired = computed(
      () => !values.value?.firstName && !values.value?.lastName && isOnlyBenefitsTransaction.value
    )

    const validations = {
      firstName: (value: string): boolean =>
        inputValidations.textLengthValidation(value, inputValidations.LENGTH_THIRTY_FIVE),
      lastName: (value: string): boolean =>
        inputValidations.textLengthValidation(value, inputValidations.LENGTH_THIRTY_FIVE),
      organizationName: (value: string): boolean => !!value,
      npi: (value: string): boolean => inputValidations.npiValidation(value),
    }

    const setValues = (value: PrescriberModels.PrescriberInformationPartial) => {
      values.value = { ...values.value, ...value }
    }

    const setMeta = (value: WizardStateModels.WizardPrescriberInformationFields) => {
      fieldsMeta.value = { ...fieldsMeta.value, ...value }
    }

    const inputHandlers = {
      firstName: (value: string) => {
        setValues({ organizationName: null })
        setValues({ firstName: value })
        setMeta({ firstName: { ...fieldsMeta.value.firstName, dirty: true } })
        setMeta({
          organizationName: { ...fieldsMeta.value.organizationName, dirty: false },
        })
      },
      lastName: (value: string) => {
        setValues({ organizationName: null })
        setValues({ lastName: value })
        setMeta({ lastName: { ...fieldsMeta.value.lastName, dirty: true } })
        setMeta({
          organizationName: { ...fieldsMeta.value.organizationName, dirty: false },
        })
      },
      organizationName: (value: string) => {
        setValues({ firstName: null, lastName: null })
        setValues({ organizationName: value })
        setMeta({ organizationName: { ...fieldsMeta.value.organizationName, dirty: true } })
        setMeta({
          firstName: { ...fieldsMeta.value.firstName, dirty: false },
        })
        setMeta({
          lastName: { ...fieldsMeta.value.lastName, dirty: false },
        })
      },
      npi: (value: string) => {
        setValues({ npi: value })
        setMeta({ npi: { ...fieldsMeta.value.npi, dirty: true } })
      },
      phone: (value: string) => {
        setValues({ phone: value })
        setMeta({ phone: { ...fieldsMeta.value.phone, dirty: true } })
      },
      fax: (value: string) => {
        setValues({ fax: value })
        setMeta({ fax: { ...fieldsMeta.value.fax, dirty: true } })
      },
    }

    const validationHandlers = {
      firstName: (valid: boolean) =>
        setMeta({ firstName: { ...fieldsMeta.value.firstName, valid } }),
      lastName: (valid: boolean) => setMeta({ lastName: { ...fieldsMeta.value.lastName, valid } }),
      organizationName: (valid: boolean) =>
        setMeta({ organizationName: { ...fieldsMeta.value.organizationName, valid } }),
      npi: (valid: boolean) => setMeta({ npi: { ...fieldsMeta.value.npi, valid } }),
      phone: (valid: boolean) => setMeta({ phone: { ...fieldsMeta.value.phone, valid } }),
      fax: (valid: boolean) => setMeta({ fax: { ...fieldsMeta.value.fax, valid } }),
    }

    watch(values, values => context.emit('on-input', values))
    watch(fieldsMeta, validation => context.emit('on-meta', validation))
    onBeforeMount(() => (values.value = props.prescriber))

    return {
      fieldsMeta,
      firstNameRequired,
      inputHandlers,
      lastNameRequired,
      organizationNameRequired,
      values,
      validations,
      validationHandlers,
      isOnlyBenefitsTransaction,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/input';

.information-fields-container {
  display: flex;
  gap: 2em;
  flex-flow: row wrap;
  span {
    padding-top: 35px;
    height: 100%;
    flex-grow: 0;
    align-self: flex-start;
  }
}

.information-input {
  width: 300px;
}

.information-input-group {
  width: 100%;
}
</style>
