import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b18e0fec"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "diagnosis-input input-small" }
const _hoisted_3 = { class: "diagnosis-input input-large" }
const _hoisted_4 = { class: "diagnosis-input input-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'diagnosis-block-' + _ctx.elementKey,
    class: "diagnosis-fields-container"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_input, {
        label: "Code",
        "element-key": 'diagnosis-code-' + _ctx.elementKey,
        required: !!_ctx.codeMeta?.code?.required && !_ctx.isBenefitsTransactionOnly,
        validation: _ctx.validations.code,
        invalid: !!_ctx.codeMeta?.code?.dirty && !_ctx.codeMeta?.code?.valid,
        invalidHintText: "This field is required and must be between 3 and 7 characters.",
        value: _ctx.code.code || undefined,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.code,
        onOnValidate: _ctx.validationHandlers.code
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_input, {
        label: "Code Description",
        "element-key": 'diagnosis-code-description-' + _ctx.elementKey,
        required: !!_ctx.codeMeta?.description?.required && !_ctx.isBenefitsTransactionOnly,
        validation: _ctx.validations.description,
        invalid: !!_ctx.codeMeta?.description?.dirty && !_ctx.codeMeta?.description?.valid,
        invalidHintText: "This field is required and must be 255 characters or less.",
        value: _ctx.code.description || undefined,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.description,
        onOnValidate: _ctx.validationHandlers.description
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_select_input, {
        label: "Code Qualifier",
        "element-key": 'diagnosis-code-qualifier-' + _ctx.elementKey,
        options: _ctx.diagnosisQualifierOptions,
        placeholder: "Select Code Qualifier",
        required: !!_ctx.codeMeta?.qualifier?.required && !_ctx.isBenefitsTransactionOnly,
        validation: _ctx.validations.qualifier,
        invalid: !!_ctx.codeMeta?.qualifier?.dirty && !_ctx.codeMeta?.qualifier?.valid,
        value: _ctx.code.qualifier || undefined,
        "data-public": true,
        onOnSelection: _ctx.inputHandlers.qualifier,
        onOnValidate: _ctx.validationHandlers.qualifier
      }, null, 8, ["element-key", "options", "required", "validation", "invalid", "value", "onOnSelection", "onOnValidate"])
    ])
  ], 8, _hoisted_1))
}