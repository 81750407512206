import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cac25da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = {
  key: 0,
  class: "table-header"
}
const _hoisted_3 = {
  key: 0,
  "data-private": ""
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  "data-private": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableView = _resolveComponent("TableView", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.noTitle && _ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
            return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(_ctx.formatNodeKey(header)), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (row, rowIndex) => {
          return (_openBlock(), _createElementBlock("tr", { key: rowIndex }, [
            (!_ctx.isObject(row))
              ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(row), 1))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(row, (value, colIndex) => {
                  return (_openBlock(), _createElementBlock("td", { key: colIndex }, [
                    (_ctx.isObject(value) && _ctx.useNestedTables)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_TableView, { data: value }, null, 8, ["data"])
                        ]))
                      : (!_ctx.isObject(value))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(value), 1))
                        : _createCommentVNode("", true)
                  ]))
                }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}