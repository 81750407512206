import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wizard-container" }
const _hoisted_2 = { class: "wizard" }
const _hoisted_3 = { class: "wizard-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!
  const _component_wizard_menu = _resolveComponent("wizard-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_section_navigation = _resolveComponent("section-navigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_banner, {
        userName: _ctx.userName,
        logout: _ctx.logout,
        settings: _ctx.settings.banner
      }, null, 8, ["userName", "logout", "settings"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("nav", null, [
          _createVNode(_component_wizard_menu, {
            transactions: _ctx.transactions,
            "valid-sections": _ctx.validSections,
            "review-visited": _ctx.reviewVisited
          }, null, 8, ["transactions", "valid-sections", "review-visited"])
        ]),
        _createElementVNode("main", null, [
          _createVNode(_component_router_view),
          _createElementVNode("footer", null, [
            _createVNode(_component_section_navigation, {
              nextSectionName: _ctx.nextSectionDetails?.name,
              nextHidden: _ctx.nextSectionDetails?.hidden,
              nextDisabled: _ctx.nextSectionDetails?.disabled,
              previousSectionName: _ctx.previousSectionDetails?.name,
              previousHidden: _ctx.previousSectionDetails?.hidden,
              previousDisabled: _ctx.previousSectionDetails?.disabled
            }, null, 8, ["nextSectionName", "nextHidden", "nextDisabled", "previousSectionName", "previousHidden", "previousDisabled"])
          ])
        ])
      ])
    ])
  ]))
}