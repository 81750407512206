import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bd68335"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "data-required"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id", "onMousedown"]
const _hoisted_5 = ["id", "for", "data-active", "data-disabled"]
const _hoisted_6 = ["id", "name", "checked", "data-active", "disabled", "data-public"]
const _hoisted_7 = ["data-public"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'radio-button-container-' + _ctx.elementKey,
    class: "radio-button-container"
  }, [
    _createElementVNode("label", {
      id: 'radio-button-container-label-' + _ctx.elementKey,
      class: "form-field-label",
      "data-required": _ctx.required
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.additionalLabel), 1)
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      id: 'radio-button-options-container-' + _ctx.elementKey,
      class: "radio-button-options-container"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radioButtonComponentOptions, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          id: 'radio-button-option-' + _ctx.elementKey + '-' + option.key,
          key: _ctx.elementKey + '-' + option.key,
          onMousedown: _withModifiers(($event: any) => (_ctx.selectionHandler(option)), ["stop"])
        }, [
          _createElementVNode("label", {
            id: 'radio-button-option-label-' + _ctx.elementKey + '-' + option.key,
            for: 'radio-button-option-input-' + _ctx.elementKey + '-' + option.key,
            class: "option",
            "data-active": option.active,
            "data-disabled": _ctx.disabled
          }, [
            _createElementVNode("input", {
              type: "radio",
              id: 'radio-button-option-input-' + _ctx.elementKey + '-' + option.key,
              name: 'radio-button-option-input-' + _ctx.elementKey + '-' + option.key,
              checked: option.active,
              "data-active": option.active,
              disabled: _ctx.disabled,
              "data-public": !!_ctx.dataPublic || undefined
            }, null, 8, _hoisted_6),
            _createElementVNode("span", {
              "data-public": !!_ctx.dataPublic || undefined
            }, _toDisplayString(option.label), 9, _hoisted_7)
          ], 8, _hoisted_5)
        ], 40, _hoisted_4))
      }), 128))
    ], 8, _hoisted_3)
  ], 8, _hoisted_1))
}