import sweetAlert from 'sweetalert2'

export enum Icon {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}
export const notify = (text: string, title: string, icon: Icon, timer = 5000): Promise<any> => {
  const toast = sweetAlert.mixin({
    toast: true,
    showConfirmButton: false,
    showCloseButton: true,
    position: 'bottom-end',
    timerProgressBar: true,
    timer,
    didOpen: t => {
      t.addEventListener('mouseenter', sweetAlert.stopTimer)
      t.addEventListener('mouseleave', sweetAlert.resumeTimer)
    },
  })
  return toast.fire({
    icon,
    title,
    text,
    denyButtonColor: getComputedStyle(document.documentElement).getPropertyValue(
      '--tertiary-color'
    ),
    customClass: {
      popup: `notification-popup-${icon}`,
    },
  })
}
