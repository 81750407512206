export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  IN_PROCESS = 'IN_PROCESS',
  PENDING = 'PENDING',
  CANCEL_PENDING = 'CANCEL_PENDING',
  CANCEL_DENIED = 'CANCEL_DENIED',
  CANCELED = 'CANCELED',
}

export interface SummaryStatus {
  status: Status
  caseNumber: string | null
}

export interface SummaryAddress {
  lineOne: string
  lineTwo: string
  city: string
  state: string
  zip: string
  country: string
}

export interface SummaryPatient {
  firstName: string
  lastName: string
  dob: string
  gender: string
  phone: string
  address: SummaryAddress
}

export interface SummaryInsurance {
  bin: string
  pcn: string
  payer: string
  memberId: string
  groupId: string
}

export interface SummaryMedicationDiagnosis {
  code: string
  qualifier: string
  description: string
}

export interface SummaryMedication {
  description: string
  code: string
  qualifier: string
  quantity: string
  daysSupply: number
  substitutions: string
  diagnosisQualifier: string
  primaryDiagnosis: SummaryMedicationDiagnosis
  secondaryDiagnosis: SummaryMedicationDiagnosis
}

export interface SummaryPrescriber {
  firstName: string
  lastName: string
  npi: number
  fax: string | null
  phone: string | null
  address: SummaryAddress
}

export interface SummarySubmission {
  priority: string
  patient: SummaryPatient
  insurance: SummaryInsurance
  medication: SummaryMedication
  prescriber: SummaryPrescriber
}

// TODO - update this to contain submission when needed for summary (SummarySubmission)
export interface Summary {
  submission: SummarySubmission | null
  status: SummaryStatus | null
}
