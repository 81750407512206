<template>
  <div :id="'drug-block-' + elementKey" class="drug-fields-container">
    <div class="drug-input input-full">
      <text-input
        label="Prescription Name"
        :element-key="'prescription-name-' + elementKey"
        :required="!!drugMeta?.description?.required"
        :validation="validations.description"
        :invalid="!!drugMeta?.description?.dirty && !drugMeta.description.valid"
        :value="values?.description || undefined"
        :data-public="true"
        invalidHintText="Field is required and must be 105 characters or less."
        @onInput="inputHandlers.description"
        @onValidate="validationHandlers.description"
      />
    </div>
    <div class="drug-input input-small">
      <text-input
        label="NDC"
        :element-key="'prescription-code-' + elementKey"
        :required="!!drugMeta?.product?.code?.required"
        :validation="validations.productCode"
        :invalid="!!drugMeta?.product?.code?.dirty && !drugMeta.product?.code?.valid"
        invalidHintText="Field must be a valid 11-digit NDC."
        :value="values.product?.code || undefined"
        :data-public="true"
        @onInput="inputHandlers.productCode"
        @onValidate="validationHandlers.productCode"
      />
    </div>
    <div class="drug-input input-small">
      <text-input
        label="Quantity"
        :element-key="'prescription-quantity-' + elementKey"
        :required="!!drugMeta?.quantity?.value?.required"
        :validation="validations.quantityValue"
        :invalid="!!drugMeta?.quantity?.value?.dirty && !drugMeta.quantity?.value?.valid"
        invalidHintText="Field must be a valid whole number."
        :value="values.quantity?.value ? `${values.quantity.value}` : undefined"
        :data-public="true"
        @onInput="inputHandlers.quantityValue"
        @onValidate="validationHandlers.quantityValue"
      />
    </div>
    <div class="drug-input input-medium" v-if="priorAuthTransactionEnabled">
      <select-input
        label="Quantity List Qualifier"
        :element-key="'prescription-quantity-qualifier-' + elementKey"
        :options="quantityQualifierOptions"
        placeholder="Select List Qualifier"
        :required="!!drugMeta?.quantity?.codeListQualifier?.required"
        :validation="validations.quantityQualifier"
        :invalid="
          !!drugMeta?.quantity?.codeListQualifier?.dirty &&
          !drugMeta.quantity?.codeListQualifier?.valid
        "
        :value="values.quantity?.codeListQualifier || undefined"
        :data-public="true"
        @onSelection="inputHandlers.quantityQualifier"
        @onValidate="validationHandlers.quantityQualifier"
      />
    </div>
    <div class="drug-input input-medium">
      <select-input
        label="Unit Of Measure (NCI Code)"
        option-value-key="value"
        option-label-key="label"
        :options="unitsOfMeasureOptions"
        :element-key="'prescription-quantity-uom-' + elementKey"
        :required="!!drugMeta?.quantity?.unitOfMeasure?.required"
        :validation="validations.quantityUnitOfMeasure"
        :invalid="
          !!drugMeta?.quantity?.unitOfMeasure?.dirty && !drugMeta.quantity?.unitOfMeasure?.valid
        "
        :value="values.quantity?.unitOfMeasure || undefined"
        :data-public="true"
        @onSelection="inputHandlers.quantityUnitOfMeasure"
        @onValidate="validationHandlers.quantityUnitOfMeasure"
      >
      </select-input>
    </div>
    <div class="drug-input input-small">
      <text-input
        label="Days Supply"
        :element-key="'prescription-days-supply-' + elementKey"
        :required="!!drugMeta?.daysSupply?.required"
        :validation="validations.daysSupply"
        :invalid="!!drugMeta?.daysSupply?.dirty && !drugMeta.daysSupply?.valid"
        invalidHintText="Field must be a valid whole number."
        :value="values.daysSupply ? `${values.daysSupply}` : undefined"
        :data-public="true"
        @onInput="inputHandlers.daysSupply"
        @onValidate="validationHandlers.daysSupply"
      />
    </div>
    <div class="drug-input input-medium">
      <select-input
        label="Substitutions"
        :element-key="'prescription-substitutions-' + elementKey"
        :options="substitutionOptions"
        placeholder="Select Substitutions"
        :required="!!rtbcTransactionEnabled"
        :invalid="!!drugMeta?.substitutions?.dirty && !drugMeta.substitutions?.valid"
        :value="values.substitutions || undefined"
        :data-public="true"
        @onSelection="inputHandlers.substitutions"
        @onValidate="validationHandlers.substitutions"
      />
    </div>
    <div class="slot-padding">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid'
import { computed, defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import { TextInput, SelectInput } from '@/components/shared'
import { PrescriptionDrugModels, QualifierModels } from '@/models/submission'
import { WizardStateModels } from '@/models/store'
import { inputValidations } from '@/modules/validations'
import { TransactionOptions } from '@/models/wizard/transactions'

type OptionsType = { label: string; value: string }[]

export default defineComponent({
  name: 'DrugBlock',
  emits: ['on-input', 'on-meta'],
  components: { TextInput, SelectInput },
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    transactions: {
      type: Array,
      required: true,
      default: () => [],
    },
    drug: {
      type: Object as PropType<PrescriptionDrugModels.PrescriptionDrugInformation>,
      default: () => ({
        description: null,
        product: {
          code: null,
        },
        quantity: {
          value: null,
          unitOfMeasure: null,
          codeListQualifier: null,
        },
        daysSupply: null,
        substitutions: null,
      }),
    },
    substitutionOptions: {
      type: Array as PropType<OptionsType>,
      default: () => [],
    },
    quantityQualifierOptions: {
      type: Array as PropType<OptionsType>,
      default: () => [],
    },
    unitsOfMeasureOptions: {
      type: Array as PropType<OptionsType>,
      default: () => [],
    },
    meta: {
      type: Object as PropType<WizardStateModels.WizardMedicationPrescribedInformationFields>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const rtbcTransactionEnabled = computed(() =>
      props.transactions.includes(TransactionOptions.ELIGIBILITY_AND_BENEFITS)
    )
    const priorAuthTransactionEnabled = computed(
      () =>
        props.transactions?.includes(TransactionOptions.PRIOR_AUTH) || !props.transactions.length
    )
    const values = ref<PrescriptionDrugModels.PrescriptionDrugInformation>({ ...props.drug })
    const drugMeta = ref<WizardStateModels.WizardMedicationPrescribedInformationFields>({
      ...props.meta,
    })

    const validations = {
      description: (value: string): boolean =>
        inputValidations.textLengthValidation(value, inputValidations.LENGTH_ONE_HUNDRED_FIVE),
      daysSupply: (value: string): boolean => inputValidations.positiveIntegerValidation(value, 4),
      productCode: (value: string): boolean => inputValidations.ndcCodeValidation(value),
      quantityValue: (value: string): boolean =>
        inputValidations.positiveIntegerValidation(value, 12),
      quantityQualifier: (value: string): boolean => inputValidations.some(value),
      quantityUnitOfMeasure: (value: string): boolean =>
        inputValidations.drugQuantityValidation(value),
    }

    const setDrugValues = (value: PrescriptionDrugModels.PartialPrescriptionDrugInformation) => {
      values.value = {
        ...values.value,
        ...value,
        product: { ...values.value.product },
        quantity: { ...values.value.quantity },
      }
    }

    const setDrugProductValues = (value: PrescriptionDrugModels.PartialPrescriptionProduct) => {
      values.value = { ...values.value, product: { ...values.value.product, ...value } }
    }

    const setDrugQuantityValues = (value: PrescriptionDrugModels.PartialPrescriptionQuantity) => {
      values.value = { ...values.value, quantity: { ...values.value.quantity, ...value } }
    }

    const setDrugMeta = (value: WizardStateModels.WizardMedicationPrescribedInformationFields) => {
      drugMeta.value = { ...drugMeta.value, ...value }
    }

    const setProductMeta = (value: WizardStateModels.WizardMedicationProduct) => {
      drugMeta.value = { ...drugMeta.value, product: { ...drugMeta.value?.product, ...value } }
    }

    const setQuantityMeta = (value: WizardStateModels.WizardMedicationQuantity) => {
      drugMeta.value = { ...drugMeta.value, quantity: { ...drugMeta.value.quantity, ...value } }
    }

    const convertNumberStringOrNull = (value: string): number | null => {
      return inputValidations.positiveIntegerValidation(value) ? parseInt(value, 10) : null
    }

    const ndcReducer = (value: string) => value?.replaceAll('-', '')

    const inputHandlers = {
      description: (value: string) => {
        setDrugValues({ description: value })
        setDrugMeta({ description: { ...drugMeta.value?.description, dirty: true } })
      },
      daysSupply: (value: string) => {
        const numberConversion = convertNumberStringOrNull(value)
        setDrugValues({ daysSupply: numberConversion })
        setDrugMeta({ daysSupply: { ...drugMeta.value?.daysSupply, dirty: true } })
      },
      substitutions: (value: string) => {
        setDrugValues({ substitutions: value })
        setDrugMeta({ substitutions: { ...drugMeta.value?.substitutions, dirty: true } })
      },
      productCode: (value: string) => {
        setDrugProductValues({ ...values.value.product, code: value ? ndcReducer(value) : value })
        setProductMeta({ code: { ...drugMeta.value?.product?.code, dirty: true } })
      },
      quantityValue: (value: string) => {
        const numberConversion = convertNumberStringOrNull(value)
        setDrugQuantityValues({ ...values.value.product, value: numberConversion })
        setQuantityMeta({ value: { ...drugMeta.value?.quantity?.value, dirty: true } })
      },
      quantityQualifier: (value: QualifierModels.QuantityQualifierStrings) => {
        setDrugQuantityValues({ ...values.value.quantity, codeListQualifier: value })
        setQuantityMeta({
          codeListQualifier: { ...drugMeta.value?.quantity?.codeListQualifier, dirty: true },
        })
      },
      quantityUnitOfMeasure: (value: string) => {
        setDrugQuantityValues({ ...values.value.quantity, unitOfMeasure: value })
        setQuantityMeta({
          unitOfMeasure: { ...drugMeta.value?.quantity?.unitOfMeasure, dirty: true },
        })
      },
    }

    const validationHandlers = {
      description: (valid: boolean) =>
        setDrugMeta({ description: { ...drugMeta.value.description, valid } }),
      daysSupply: (valid: boolean) =>
        setDrugMeta({ daysSupply: { ...drugMeta.value.daysSupply, valid } }),
      substitutions: (valid: boolean) =>
        setDrugMeta({ substitutions: { ...drugMeta.value.substitutions, valid } }),
      productCode: (valid: boolean) =>
        setProductMeta({ code: { ...drugMeta.value?.product?.code, valid } }),
      quantityValue: (valid: boolean) => {
        setQuantityMeta({ value: { ...drugMeta.value?.quantity?.value, valid } })
      },
      quantityQualifier: (valid: boolean) => {
        setQuantityMeta({
          codeListQualifier: { ...drugMeta.value?.quantity?.codeListQualifier, valid },
        })
      },
      quantityUnitOfMeasure: (valid: boolean) => {
        setQuantityMeta({ unitOfMeasure: { ...drugMeta.value?.quantity?.unitOfMeasure, valid } })
      },
    }

    watch(values, values => context.emit('on-input', values))
    watch(drugMeta, validation => context.emit('on-meta', validation))
    onBeforeMount(() => (values.value = props.drug))

    return {
      drugMeta,
      inputHandlers,
      rtbcTransactionEnabled,
      priorAuthTransactionEnabled,
      values,
      validations,
      validationHandlers,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/input';

.drug-fields-container {
  display: flex;
  flex-flow: row wrap;
}

.drug-input {
  padding: 0 30px 20px 0;
}

.slot-padding {
  padding-top: 10px;
  width: 100%;
}
</style>
