<template>
  <div :id="'multi-select-container-' + elementKey" class="multi-select-container">
    <div :id="'multi-select-label-container-' + elementKey" class="multi-select-label-container">
      <label
        :id="'multi-select-label-' + elementKey"
        class="form-field-label"
        :data-required="required"
      >
        {{ label }}
      </label>
      <span class="select-info-text">( Select {{ multiSelect ? 'all that apply' : 'one' }} )</span>
    </div>
    <div :id="'multi-select-area-' + elementKey" class="multi-select-area" :invalid="invalid">
      <div :id="'multi-select-area-search-' + elementKey" class="multi-select-area-search">
        <input
          :ref="inputElement"
          :id="'multi-select-container-search-' + elementKey"
          class="form-field"
          autocomplete="off"
          @keydown.enter.prevent="keyboardEnterKeyHandler"
          @input="inputHandler"
          :value="searchValue"
          :disabled="disabled"
          placeholder="Search..."
          :data-public="!!dataPublic || undefined"
        />
        <div class="search-clear-icon">
          <fa-icon
            :id="'multi-select-container-search-times-icon-' + elementKey"
            @click="clearSearch"
            v-if="searchValue?.length > 0 && !disabled"
            icon="fa-solid fa-xmark"
            tabindex="-1"
          ></fa-icon>
        </div>
      </div>
      <div :id="'multi-select-area-items-' + elementKey" class="multi-select-area-items">
        <div class="no-items" v-if="!filteredDisplayOptions?.length">No Selections Found...</div>
        <div
          v-for="item of filteredDisplayOptions"
          :id="'multi-select-area-item-' + elementKey + '-' + item.key"
          :key="item.key"
          :selected="item.selected"
          class="item"
          @click="itemSelectionHandler(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, onBeforeMount } from 'vue'
import { v4 as uuid } from 'uuid'

interface ExpectedOptionType {
  value: string
  label: string
}

const DefaultOptionFormatter = (option: any): any => {
  const optionKeys = Object.keys(option)
  if (optionKeys.includes('value') && optionKeys.includes('label')) {
    return option
  } else {
    throw new Error(
      'Invalid option types: Prop optionFormatter can be used to format options to include label and value, or options need to be formatted manually.'
    )
  }
}

const DefaultOptionReducer = (option: any): any => {
  const optionKeys = Object.keys(option)
  if (optionKeys.includes('value') && optionKeys.includes('label')) {
    return { label: option.label, value: option.value }
  } else {
    return option
  }
}

interface MultiSelectDisplayOption {
  key: string
  value: string
  label: string
  selected: boolean
}

export default defineComponent({
  name: 'MultiSelectQuestionInput',
  emits: ['on-selection', 'on-input', 'on-validate'],
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    options: {
      type: Array as PropType<(ExpectedOptionType & any)[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<any>,
      default: () => [],
    },
    validation: {
      type: Function,
      default: () => true,
    },
    optionFormatter: {
      type: Function,
      default: DefaultOptionFormatter,
    },
    reducer: {
      type: Function,
      default: DefaultOptionReducer,
    },
    valueKey: String,
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const filteredDisplayOptions = ref<MultiSelectDisplayOption[]>([] as MultiSelectDisplayOption[])
    const allDisplayOptions = ref<MultiSelectDisplayOption[]>([] as MultiSelectDisplayOption[])
    const searchValue = ref<string | null>(null)
    const placeholderDisplay = ref<string>(props.placeholder)
    const selections = ref<MultiSelectDisplayOption[]>([])
    const inputElement = ref<HTMLInputElement | null>(null)

    const validate = (values: any[]): boolean => {
      if (!props.required) {
        return props.validation ? props.validation(values) : !props.validation
      }
      return !!values?.length && props.validation ? props.validation(values) : !props.validation
    }

    const createOptionsFromProps = (options: any[]): MultiSelectDisplayOption[] => {
      return options.map(option => ({
        key: option.value,
        value: option.value,
        label: option.label,
        selected: false,
        ...option,
      }))
    }

    const checkAndHandleSelections = () => {
      const displayOptionsCopy = allDisplayOptions.value.map(displayOption => ({
        ...displayOption,
        selected: false,
      }))
      const values = selections.value.map(selection => props.reducer(selection))

      for (const selection of selections.value) {
        const index = displayOptionsCopy.findIndex(option => option.key === selection.key)
        if (index !== -1) {
          displayOptionsCopy[index].selected = true
        }
      }
      allDisplayOptions.value = displayOptionsCopy

      context.emit('on-validate', validate(selections.value))
      context.emit('on-selection', values)
    }

    const deselectAllItems = (): void => {
      const valuesCopy = [...allDisplayOptions.value].map(item => ({ ...item, selected: false }))
      allDisplayOptions.value = valuesCopy
    }

    const updateSelectedValues = (items: any[]): void => {
      deselectAllItems()
      selections.value = items
      const optionsCopy = [...allDisplayOptions.value]

      for (const item of items) {
        const index = optionsCopy.findIndex(option => option.value === item.value)
        if (index !== -1) {
          optionsCopy[index].selected = true
        }
      }

      allDisplayOptions.value = optionsCopy
    }

    const handleValueFromProps = (values: any[] | null): void => {
      try {
        if (values?.length) {
          const tempValues: any[] = []

          for (const value of values) {
            let valueItem: any = null

            if (typeof value === 'object' && props.valueKey) {
              const itemToCompare = value[props.valueKey]
              valueItem = allDisplayOptions.value?.find(item => item.value === itemToCompare)
            } else {
              valueItem = allDisplayOptions.value?.find(item => item.value === value)
            }
            tempValues.push({ ...valueItem, selected: true })
          }

          updateSelectedValues(tempValues)
        } else {
          updateSelectedValues([])
        }
      } catch (error: any) {
        throw new Error(
          'value must be an array of values, use the value-key prop to distinguish which value to compare for objects.'
        )
      }
    }

    const clearSearch = (): void => {
      if (searchValue.value) {
        searchValue.value = null
      }
    }

    const itemSelectionHandler = (item: MultiSelectDisplayOption): void => {
      let selectionsCopy = [...selections.value]
      const existingSelection = selectionsCopy.findIndex(selection => selection.key === item.key)
      if (existingSelection !== -1) {
        selectionsCopy.splice(existingSelection, 1)
      } else if (props.multiSelect) {
        selectionsCopy.push(item)
      } else {
        selectionsCopy = [item]
      }
      clearSearch()
      selections.value = selectionsCopy
      checkAndHandleSelections()
    }

    const inputHandler = (event: any): void => {
      if (!props.disabled) {
        if (event?.target?.value) {
          searchValue.value = event.target.value
        } else {
          searchValue.value = null
        }
      }
    }

    const keyboardEnterKeyHandler = (event: any): void => {
      event.preventDefault()
    }

    watch(
      searchValue,
      searchString => {
        if (searchString) {
          filteredDisplayOptions.value = allDisplayOptions.value.filter(item =>
            item?.label?.toLowerCase().includes(searchString?.toLowerCase())
          )
          context.emit('on-input', searchString)
        } else {
          filteredDisplayOptions.value = allDisplayOptions.value
        }
      },
      { immediate: true }
    )

    watch(allDisplayOptions, allOptions => {
      const searchString = searchValue.value || ''
      if (searchString) {
        filteredDisplayOptions.value = allOptions.filter(item =>
          item?.label?.toLowerCase().includes(searchString.toLowerCase())
        )
      } else {
        filteredDisplayOptions.value = allOptions
      }
    })

    watch(() => props.options, createOptionsFromProps, { immediate: true })
    watch(() => props.value, handleValueFromProps, { immediate: true })

    onBeforeMount(() => {
      if (props.options?.length) {
        const formattedOptions = props.options.map((option: any) => props.optionFormatter(option))
        allDisplayOptions.value = createOptionsFromProps(formattedOptions)
      }
    })

    return {
      filteredDisplayOptions,
      inputElement,
      placeholderDisplay,
      searchValue,

      clearSearch,
      inputHandler,
      itemSelectionHandler,
      keyboardEnterKeyHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.multi-select-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.multi-select-label-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.select-info-text {
  font-size: 12px;
  color: $myndshft-dark-gray;
  padding: 0 10px;
  align-self: center;
}

.multi-select-area {
  display: flex;
  flex-direction: column;
  border: 1px solid $myndshft-light-gray;
  border-radius: 2px;
  width: 100%;
  max-height: 600px;
  &[invalid='true'] {
    border: 1px solid $myndshft-required-pink;
    box-shadow: 0 0 2px $myndshft-required-pink;
  }
}

.multi-select-area-search {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $myndshft-light-gray;

  .form-field:focus {
    box-shadow: none;
  }

  input {
    border: none;
    border-radius: 0;
    :focus {
      border: none;
      border-radius: 0;
    }
  }
  .search-clear-icon {
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: $myndshft-dark-orange;
    :hover {
      transform: translateY(-1px);
    }
    :focus {
      border: none;
      outline: none;
    }
  }
}

.multi-select-area-items {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  overflow-y: scroll;
  gap: 10px;
  padding: 10px;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    cursor: pointer;
    background-color: $myndshft-white;
    padding: 8px 16px;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    min-width: 140px;
    max-width: 45%;
    max-height: 80px;
    overflow-y: scroll;
    word-wrap: normal;

    &:hover {
      transform: translateY(-1px);
      box-shadow: -1px 1px 2px -1px var(--secondary-color);
    }

    &[selected='true'] {
      color: $myndshft-white;
      background: var(--secondary-color);
    }
  }

  .no-items {
    width: 100%;
    text-align: center;
    color: $myndshft-dark-gray;
  }
}
</style>
