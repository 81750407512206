<template>
  <div class="section-navigation-container">
    <div id="section-navigation-previous-button" v-if="!previousHidden">
      <button class="wl-button" @click="previousButtonClickHandler" :disabled="previousDisabled">
        <fa-icon icon="fa-solid fa-chevron-left"></fa-icon><label>Previous</label>
      </button>
    </div>
    <div v-else></div>
    <div id="section-navigation-next-button" v-if="!nextHidden">
      <button class="wl-button" @click="nextButtonClickHandler" :disabled="nextDisabled">
        <label>Next</label><fa-icon icon="fa-solid fa-chevron-right" transform="right-5"></fa-icon>
      </button>
    </div>
    <div v-else></div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'

const props = defineProps({
  nextSectionName: {
    type: String,
    default: null,
  },
  nextDisabled: {
    type: Boolean,
    default: false,
  },
  nextHidden: {
    type: Boolean,
    default: false,
  },
  previousSectionName: {
    type: String,
    default: null,
  },
  previousDisabled: {
    type: Boolean,
    default: false,
  },
  previousHidden: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()

const nextButtonClickHandler = () => {
  if (!props.nextDisabled && !props.nextHidden && props.nextSectionName !== null) {
    router.push({ name: props.nextSectionName })
  }
}

const previousButtonClickHandler = () => {
  if (!props.previousDisabled && !props.previousHidden && props.previousSectionName !== null) {
    router.push({ name: props.previousSectionName })
  }
}
</script>

<style lang="scss" scoped>
@import '@myndshft/styles/src/components/mixins.scss';

.section-navigation-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
</style>
