<template>
  <div id="details-group">
    <form-group-section :element-key="'details'" :section-label="'Details'">
      <div class="transactions">
        <radio-button
          :element-key="'transactions-selections'"
          :label="'Select Transactions to Perform'"
          :options="transactionOptions"
          :required="true"
          :multi-select="true"
          :value="transactionsSelected"
          :data-public="true"
          @onSelection="transactionsUpdate"
        ></radio-button>
      </div>
      <div class="provider">
        <select-input
          :element-key="'details-provider'"
          :label="'Provider'"
          :options="providerOptions"
          :option-label-key="'name'"
          :option-value-key="'npi'"
          :placeholder="'Select Provider'"
          :value="detailsValues?.npi"
          :required="!!detailsMeta?.provider?.required"
          :invalid="detailsMeta?.provider?.dirty && !detailsMeta?.provider?.valid"
          :data-public="true"
          :loading="providersLoading"
          @onSelection="providerUpdate"
        ></select-input>
      </div>
      <div class="priority">
        <radio-button
          :element-key="'details-priority'"
          :label="'Urgency'"
          :options="priorityOptions"
          :required="!!detailsMeta?.priority?.required"
          :value="detailsValues?.priority"
          :data-public="true"
          @onSelection="priorityUpdate"
        ></radio-button>
      </div>
    </form-group-section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { FormGroupSection, RadioButton, SelectInput } from '@/components/shared'
import { useStore } from 'vuex'
import { RadioButtonModels } from '@/models/wizard'
import { WizardStateModels } from '@/models/store'
import { PriorityModels } from '@/models/submission'
import * as options from '@/modules/options'

export default defineComponent({
  name: 'DetailsSection',
  components: { FormGroupSection, SelectInput, RadioButton },
  setup() {
    const store = useStore()
    const priorityOptions = ref<RadioButtonModels.RadioButtonOptions>(options.priorityOptions)
    const transactionOptions = ref<RadioButtonModels.RadioButtonOptions>(options.transactionOptions)
    const providerOptions = computed<WizardStateModels.Provider[]>(() => {
      const options = store.state.wizard.providers
      return options.map((option: WizardStateModels.Provider) => ({
        name: `${option.npi} - ${option.name}`,
        npi: option.npi,
      }))
    })
    const providersLoading = computed(() => store.getters['wizard/getProvidersLoading'])
    const detailsValues = computed(() => store.getters['submission/getDetailsSection'])
    const detailsMeta = computed(() => store.getters['wizard/getDetailsSectionMeta'])
    const transactionsSelected = computed(() => store.getters['wizard/getTransactions'])

    const priorityUpdate = (selectedOptions: PriorityModels.PriorityStrings[]): void => {
      if (!!selectedOptions?.length && selectedOptions.length === 1) {
        const valid = true
        store.commit('wizard/setDetailsSectionMeta', { priority: { dirty: true, valid } })
        store.commit('submission/setPriority', selectedOptions?.[0])
      }
    }

    const providerUpdate = (selectedOption: string | null): void => {
      const valid = !!selectedOption
      store.commit('wizard/setDetailsSectionMeta', { provider: { dirty: true, valid } })
      store.commit('submission/setNPI', selectedOption)
    }

    const transactionsUpdate = (selectedTransactions: string[]): void => {
      store.commit('wizard/setTransactions', selectedTransactions)
    }

    return {
      detailsMeta,
      detailsValues,
      priorityOptions,
      providerOptions,
      providersLoading,
      transactionOptions,
      transactionsSelected,

      priorityUpdate,
      providerUpdate,
      transactionsUpdate,
    }
  },
})
</script>

<style lang="scss" scoped>
.provider,
.priority,
.transactions {
  padding: 0 30px 20px 0;
}
</style>
