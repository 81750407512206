export const getUITimeOfDayText = (): string => {
  const currentHours = new Date().getHours()
  let response = 'evening'
  if (currentHours < 12) {
    response = 'morning'
  } else if (currentHours < 17) {
    response = 'afternoon'
  }
  return response
}
