import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b39e574"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "address-input input-large" }
const _hoisted_3 = { class: "address-input input-large" }
const _hoisted_4 = { class: "address-input input-medium" }
const _hoisted_5 = { class: "address-input input-medium" }
const _hoisted_6 = { class: "address-input input-small" }
const _hoisted_7 = { class: "address-input input-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_SelectInput = _resolveComponent("SelectInput")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'address-block-' + _ctx.elementKey,
    class: "address-fields-container"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_input, {
        label: "Address Line 1",
        "element-key": 'address-line-one-' + _ctx.elementKey,
        required: !!_ctx.meta?.lineOne?.required,
        validation: _ctx.validations.lineOne,
        invalid: !!_ctx.addressMeta?.lineOne?.dirty && !_ctx.addressMeta.lineOne.valid,
        invalidHintText: "This field is required and must be 40 characters or less.",
        value: _ctx.values.lineOne || undefined,
        "data-public": _ctx.dataPublic,
        onOnInput: _ctx.inputHandlers.lineOne,
        onOnValidate: _ctx.validationHandlers.lineOne
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "data-public", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_input, {
        label: "Address Line 2",
        "element-key": 'address-line-two-' + _ctx.elementKey,
        required: !!_ctx.meta?.lineTwo?.required,
        validation: _ctx.validations.lineTwo,
        invalid: !!_ctx.addressMeta?.lineTwo?.dirty && !_ctx.addressMeta.lineTwo.valid,
        invalidHintText: "Although this field is NOT required, when present, it must be 40 characters or less.",
        value: _ctx.values.lineTwo || undefined,
        "data-public": _ctx.dataPublic,
        onOnInput: _ctx.inputHandlers.lineTwo,
        onOnValidate: _ctx.validationHandlers.lineTwo
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "data-public", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_text_input, {
        label: "City",
        "element-key": 'address-city-' + _ctx.elementKey,
        required: !!_ctx.meta?.city?.required,
        validation: _ctx.validations.city,
        invalid: !!_ctx.addressMeta?.city?.dirty && !_ctx.addressMeta.city.valid,
        invalidHintText: "This field is required and must be 35 characters or less.",
        value: _ctx.values.city || undefined,
        "data-public": _ctx.dataPublic,
        onOnInput: _ctx.inputHandlers.city,
        onOnValidate: _ctx.validationHandlers.city
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "data-public", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_SelectInput, {
        label: "State",
        "element-key": 'address-state-' + _ctx.elementKey,
        placeholder: 'Select State',
        options: _ctx.stateOptions,
        required: !!_ctx.meta?.state?.required,
        validation: _ctx.validations.state,
        invalid: !!_ctx.addressMeta?.state?.dirty && !_ctx.addressMeta.state.valid,
        "option-label-key": "label",
        "option-value-key": "value",
        value: _ctx.values.state || undefined,
        "data-public": _ctx.dataPublic,
        onOnSelection: _ctx.inputHandlers.state,
        onOnValidate: _ctx.validationHandlers.state
      }, null, 8, ["element-key", "options", "required", "validation", "invalid", "value", "data-public", "onOnSelection", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_text_input, {
        label: "Zip Code",
        "element-key": 'address-zip-' + _ctx.elementKey,
        required: !!_ctx.meta?.zip?.required,
        validation: _ctx.validations.zip,
        invalid: !!_ctx.addressMeta?.zip?.dirty && !_ctx.addressMeta.zip.valid,
        invalidHintText: "This field requires the format: #####, #####-####, or #########.",
        value: _ctx.values.zip || undefined,
        "data-public": _ctx.dataPublic,
        onOnInput: _ctx.inputHandlers.zip,
        onOnValidate: _ctx.validationHandlers.zip
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "data-public", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_text_input, {
        label: "Country",
        "element-key": 'address-country-' + _ctx.elementKey,
        value: "United States",
        disabled: true,
        "data-public": _ctx.dataPublic
      }, null, 8, ["element-key", "data-public"])
    ])
  ], 8, _hoisted_1))
}