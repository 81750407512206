import { QualifierModels } from '../../models/submission'

const prescriptionQuantityQualifierOptions = [
  {
    label: '38 - Original Quantity',
    value: '38' as QualifierModels.QuantityQualifierStrings,
  },
  {
    label: '40 - Remaining Quantity',
    value: '40' as QualifierModels.QuantityQualifierStrings,
  },
  {
    label: '87 - Quantity Received',
    value: '87' as QualifierModels.QuantityQualifierStrings,
  },
  {
    label: 'QS - Quantity sufficient as determined by the dispensing pharmacy',
    value: QualifierModels.QuantityQualifier[QualifierModels.QuantityQualifier.QS],
  },
  {
    label: 'CF - Compound Final Quantity',
    value: QualifierModels.QuantityQualifier[QualifierModels.QuantityQualifier.CF],
  },
  {
    label: 'UQ - Central Fill Unit of Use Quantity',
    value: QualifierModels.QuantityQualifier[QualifierModels.QuantityQualifier.UQ],
  },
  {
    label: 'QT - Quantity Transferred',
    value: QualifierModels.QuantityQualifier[QualifierModels.QuantityQualifier.QT],
  },
]

export { prescriptionQuantityQualifierOptions }
