import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12ba34d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "patient-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patient_information_block = _resolveComponent("patient-information-block")!
  const _component_form_group_section = _resolveComponent("form-group-section")!
  const _component_address_block = _resolveComponent("address-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_group_section, {
      "element-key": "patient-information",
      "section-label": "Patient Information"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_patient_information_block, {
          "element-key": "patient-information-block",
          "gender-options": _ctx.options.genderOptions,
          meta: _ctx.patientMeta,
          patient: _ctx.patient,
          onOnInput: _ctx.patientInformationInputHandler,
          onOnMeta: _ctx.patientInformationMetaHandler
        }, null, 8, ["gender-options", "meta", "patient", "onOnInput", "onOnMeta"])
      ]),
      _: 1
    }),
    _createVNode(_component_form_group_section, {
      "element-key": "patient-address",
      "section-label": "Patient Address"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_address_block, {
          "element-key": "patient-address-block",
          "state-options": _ctx.options.stateOptions,
          meta: _ctx.patientMeta.address,
          address: _ctx.patient.address || undefined,
          onOnInput: _ctx.addressInputHandler,
          onOnMeta: _ctx.addressMetaHandler
        }, null, 8, ["state-options", "meta", "address", "onOnInput", "onOnMeta"])
      ]),
      _: 1
    })
  ]))
}