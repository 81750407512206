<template>
  <div :id="'diagnosis-block-' + elementKey" class="diagnosis-fields-container">
    <div class="diagnosis-input input-small">
      <text-input
        label="Code"
        :element-key="'diagnosis-code-' + elementKey"
        :required="!!codeMeta?.code?.required && !isBenefitsTransactionOnly"
        :validation="validations.code"
        :invalid="!!codeMeta?.code?.dirty && !codeMeta?.code?.valid"
        invalidHintText="This field is required and must be between 3 and 7 characters."
        :value="code.code || undefined"
        :data-public="true"
        @onInput="inputHandlers.code"
        @onValidate="validationHandlers.code"
      />
    </div>
    <div class="diagnosis-input input-large">
      <text-input
        label="Code Description"
        :element-key="'diagnosis-code-description-' + elementKey"
        :required="!!codeMeta?.description?.required && !isBenefitsTransactionOnly"
        :validation="validations.description"
        :invalid="!!codeMeta?.description?.dirty && !codeMeta?.description?.valid"
        invalidHintText="This field is required and must be 255 characters or less."
        :value="code.description || undefined"
        :data-public="true"
        @onInput="inputHandlers.description"
        @onValidate="validationHandlers.description"
      />
    </div>
    <div class="diagnosis-input input-large">
      <select-input
        label="Code Qualifier"
        :element-key="'diagnosis-code-qualifier-' + elementKey"
        :options="diagnosisQualifierOptions"
        placeholder="Select Code Qualifier"
        :required="!!codeMeta?.qualifier?.required && !isBenefitsTransactionOnly"
        :validation="validations.qualifier"
        :invalid="!!codeMeta?.qualifier?.dirty && !codeMeta?.qualifier?.valid"
        :value="code.qualifier || undefined"
        :data-public="true"
        @onSelection="inputHandlers.qualifier"
        @onValidate="validationHandlers.qualifier"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid'
import { defineComponent, onBeforeMount, PropType, ref, watch, computed } from 'vue'
import { TextInput, SelectInput } from '@/components/shared'
import { DiagnosisModels, QualifierModels } from '@/models/submission'
import { WizardStateModels } from '@/models/store'
import { inputValidations } from '@/modules/validations'
import { TransactionOptions } from '@/models/wizard/transactions'

type OptionsType = { label: string; value: string }[]

export default defineComponent({
  name: 'DiagnosisBlock',
  emits: ['on-input', 'on-meta'],
  components: { TextInput, SelectInput },
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    primary: {
      type: Boolean,
      default: true,
    },
    code: {
      type: Object as PropType<DiagnosisModels.DiagnosisCode>,
      default: () => ({
        code: null,
        description: null,
        qualifier: null,
      }),
    },
    diagnosisQualifierOptions: {
      type: Array as PropType<OptionsType>,
      default: () => [],
    },
    meta: {
      type: Object as PropType<WizardStateModels.WizardMedicationDiagnosisCode>,
      default: () => ({}),
    },
    transactions: {
      type: Array,
      default: () => [] as string[],
    },
  },
  setup(props, context) {
    const isBenefitsTransactionOnly = computed(
      () =>
        props.transactions.includes(TransactionOptions.ELIGIBILITY_AND_BENEFITS) &&
        !props.transactions.includes(TransactionOptions.PRIOR_AUTH)
    )
    const values = ref<DiagnosisModels.DiagnosisCode>({ ...props.code })
    const codeMeta = ref<WizardStateModels.WizardMedicationDiagnosisCode>({ ...props.meta })
    const validations = {
      code: (value: string): boolean => inputValidations.diagnosisCodeValidation(value),
      description: (value: string): boolean =>
        inputValidations.textLengthValidation(value, inputValidations.LENGTH_TWO_FIFTY_FIVE),
      qualifier: (value: string): boolean => inputValidations.some(value),
    }

    const setDiagnosisCodeValues = (value: DiagnosisModels.PartialDiagnosisCode) => {
      values.value = { ...values.value, ...value }
    }
    const setDiagnosisCodeMeta = (value: WizardStateModels.WizardMedicationDiagnosisCode) => {
      codeMeta.value = {
        code: { ...codeMeta.value?.code, ...value?.code },
        description: { ...codeMeta.value?.description, ...value?.description },
        qualifier: { ...codeMeta.value?.qualifier, ...value?.qualifier },
      }
    }

    const inputHandlers = {
      code: (value: string) => {
        setDiagnosisCodeValues({ code: value })
        setDiagnosisCodeMeta({ code: { ...codeMeta.value.code, dirty: true } })
      },
      description: (value: string) => {
        setDiagnosisCodeValues({ description: value })
        setDiagnosisCodeMeta({
          description: { ...codeMeta.value.description, dirty: true },
        })
      },
      qualifier: (value: QualifierModels.DiagnosisQualifierStrings) => {
        setDiagnosisCodeValues({ qualifier: value })
        setDiagnosisCodeMeta({ qualifier: { ...codeMeta.value.qualifier, dirty: true } })
      },
    }

    const validationHandlers = {
      code: (valid: boolean) => setDiagnosisCodeMeta({ code: { ...codeMeta.value.code, valid } }),
      description: (valid: boolean) =>
        setDiagnosisCodeMeta({ description: { ...codeMeta.value.description, valid } }),
      qualifier: (valid: boolean) =>
        setDiagnosisCodeMeta({ qualifier: { ...codeMeta.value.qualifier, valid } }),
    }

    watch(values, values => context.emit('on-input', values))
    watch(codeMeta, meta => context.emit('on-meta', meta))
    onBeforeMount(() => (values.value = props.code))

    return {
      values,
      codeMeta,
      isBenefitsTransactionOnly,
      inputHandlers,
      validations,
      validationHandlers,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/input';

.diagnosis-fields-container {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
}

.diagnosis-input {
  padding: 0 30px 20px 0;
}
</style>
