import { State } from './state'
import { MessageModels } from '@/models/message'
import { UserModels } from '@/models/user'
import { WizardStateModels } from '@/models/store'
import { deepMerge } from '@/utils'
import { QuestionModels } from '@/models/questions'
import { InputMeta } from '@/models/wizard'

const setDebug = (state: State, value: boolean) => {
  state.debugMode = value
}

const setActiveFeatures = (state: State, value: string[]) => {
  state.activeFeatures = value
}

const setAuthorized = (state: State, value: boolean) => {
  state.authorization = { ...state.authorization, isAuthorized: value }
}

const setToken = (state: State, value: string) => {
  state.authorization = { ...state.authorization, token: value }
}

const setProvidersLoading = (state: State, value: boolean) => {
  state.providersLoading = value
}

const setCSRFToken = (state: State, value: string | null) => {
  state.csrf = value
}

const setUser = (state: State, value: UserModels.UserState) => {
  state.user = { ...value }
}

const setAuthorization = (state: State, value: WizardStateModels.Authorization) => {
  state.authorization = { ...value }
}

const setError = (state: State, value: MessageModels.MessageState) => {
  state.error = { ...value } as MessageModels.MessageState
}

const setIsCancellationLoading = (state: State, value: boolean) => {
  state.isCancellationLoading = value
}

const setIsLinksLoading = (state: State, value: boolean) => {
  state.isLinksLoading = value
}

const setExpiration = (state: State, value: number) => {
  state.authorization = { ...state.authorization, expires: value }
}

const setMessage = (state: State, value: MessageModels.MessageState) => {
  state.message = { ...value } as MessageModels.MessageState
}

const setProviders = (state: State, value: WizardStateModels.Provider[]) => {
  state.providers = value
}

const setSubmissionId = (state: State, value: string | null) => {
  state.submissionId = value
}

const setActivityMonitoringInterval = (state: State, value: number | null) => {
  state.monitoringInterval = value
}

const setPageLoading = (state: State, value: boolean) => {
  state.pageLoading = value
}

const setCancellationMeta = (state: State, value: WizardStateModels.WizardCancellationMeta) => {
  state.cancellationMeta = { ...state.cancellationMeta, ...value }
}

const setCancellationRequestMeta = (
  state: State,
  value: WizardStateModels.WizardCancellationRequestMeta
) => {
  state.cancellationMeta = { ...state.cancellationMeta, ...value }
}

const setDetailsSectionMeta = (
  state: State,
  value: WizardStateModels.WizardDetailsSectionFields
) => {
  state.fields.detailsSection = deepMerge(state.fields.detailsSection, value)
}

const setPatientSectionMeta = (
  state: State,
  value: WizardStateModels.WizardPatientSectionFields
) => {
  state.fields.patientSection = deepMerge(state.fields.patientSection, value)
}

const setPrescriberSectionMeta = (
  state: State,
  value: WizardStateModels.WizardPrescriberFields
) => {
  state.fields.prescriber = deepMerge(state.fields.prescriber, value)
}

const setPharmacySectionMeta = (state: State, value: WizardStateModels.WizardPharmacyFields) => {
  state.fields.pharmacy = deepMerge(state.fields.pharmacy, value)
}

const setLastActive = (state: State, value: string) => {
  state.lastActive = value
}

const resetPrescriberMeta = (state: State) => {
  const getNewMeta = (required: boolean): InputMeta => ({
    id: '',
    disabled: false,
    dirty: false,
    hidden: false,
    valid: false,
    required,
    path: '/submission/prescriber',
  })
  state.fields.prescriber = {
    firstName: getNewMeta(true),
    lastName: getNewMeta(true),
    organizationName: getNewMeta(true),
    npi: getNewMeta(true),
    fax: getNewMeta(false),
    phone: getNewMeta(true),
    address: {
      lineOne: getNewMeta(true),
      lineTwo: getNewMeta(false),
      city: getNewMeta(true),
      state: getNewMeta(true),
      zip: getNewMeta(true),
    },
  }
}

const setPatientInformationMeta = (
  state: State,
  value: WizardStateModels.WizardPatientInformationFields
) => {
  setPatientSectionMeta(state, {
    firstName: deepMerge(state.fields.patientSection?.firstName, value?.firstName),
    middleName: deepMerge(state.fields.patientSection?.middleName, value?.middleName),
    lastName: deepMerge(state.fields.patientSection?.lastName, value?.lastName),
    dob: deepMerge(state.fields.patientSection?.dob, value?.dob),
    gender: deepMerge(state.fields.patientSection?.gender, value?.gender),
    phone: deepMerge(state.fields.patientSection?.phone, value?.phone),
  })
}

const setPrescriberInformationMeta = (
  state: State,
  value: WizardStateModels.WizardPrescriberInformationFields
) => {
  setPrescriberSectionMeta(state, {
    firstName: deepMerge(state.fields.prescriber?.firstName, value?.firstName),
    lastName: deepMerge(state.fields.prescriber?.lastName, value?.lastName),
    organizationName: deepMerge(state.fields.prescriber?.organizationName, value?.organizationName),
    npi: deepMerge(state.fields.prescriber?.npi, value?.npi),
    fax: deepMerge(state.fields.prescriber?.fax, value?.fax),
    phone: deepMerge(state.fields.prescriber?.phone, value?.phone),
  })
}

const setPharmacyInformationMeta = (
  state: State,
  value: WizardStateModels.WizardPharmacyFields
) => {
  setPharmacySectionMeta(state, {
    npi: deepMerge(state.fields.pharmacy?.npi, value?.npi),
    ncpdpId: deepMerge(state.fields.pharmacy?.ncpdpId, value?.ncpdpId),
  })
}

const setPatientAddressMeta = (state: State, value: WizardStateModels.WizardAddressFields) =>
  setPatientSectionMeta(state, { ...state.fields.patientSection, address: value })

const setPrescriberAddressMeta = (state: State, value: WizardStateModels.WizardAddressFields) => {
  setPrescriberSectionMeta(state, { ...state.fields.prescriber, address: value })
}

const setMedicationPrescribedMeta = (
  state: State,
  value: WizardStateModels.WizardMedicationPrescribedFields
) => {
  state.fields.medicationPrescribed = deepMerge(state.fields.medicationPrescribed, value)
}

const setMedicationPrescribedDetailsMeta = (
  state: State,
  value: WizardStateModels.WizardMedicationPrescribedFields
) => {
  state.fields.medicationPrescribed = deepMerge(state.fields.medicationPrescribed, {
    description: deepMerge(state.fields.medicationPrescribed.description, value.description),
    daysSupply: deepMerge(state.fields.medicationPrescribed.daysSupply, value.daysSupply),
    substitutions: deepMerge(state.fields.medicationPrescribed.substitutions, value.substitutions),
  })
}

const setMedicationDiagnosisMeta = (
  state: State,
  value: WizardStateModels.WizardMedicationDiagnosis
) => {
  setMedicationPrescribedMeta(state, {
    ...state.fields.medicationPrescribed,
    diagnosis: deepMerge(state.fields.medicationPrescribed.diagnosis, value),
  })
}

const setMedicationDiagnosisPrimaryCodeMeta = (
  state: State,
  value: WizardStateModels.WizardMedicationDiagnosisCode
) => setMedicationDiagnosisMeta(state, { primary: value })

const setMedicationDiagnosisSecondaryCodeMeta = (
  state: State,
  value: WizardStateModels.WizardMedicationDiagnosisCode
) => {
  setMedicationDiagnosisMeta(state, { secondary: value })
}

const resetMedicationDiagnosisSecondaryCodeMeta = (state: State) => {
  const defaultDiagnosisInputMeta = () => ({
    required: false,
    path: 'submission/drug-and-diagnosis',
    valid: true,
    dirty: false,
  })

  setMedicationDiagnosisSecondaryCodeMeta(state, {
    code: defaultDiagnosisInputMeta(),
    description: defaultDiagnosisInputMeta(),
    qualifier: defaultDiagnosisInputMeta(),
  })
}

const setMedicationProductCodeMeta = (
  state: State,
  value: WizardStateModels.WizardMedicationProduct
) => setMedicationPrescribedMeta(state, { product: value })

const setMedicationQuantityMeta = (
  state: State,
  value: WizardStateModels.WizardMedicationQuantity
) => setMedicationPrescribedMeta(state, { quantity: value })

const setCurrentQuestionMeta = (state: State, value: WizardStateModels.WizardQuestionMeta) => {
  state.currentQuestion = deepMerge(state.currentQuestion, value)
}

const setQualityUnitsOfMeasureLoading = (state: State, value: boolean) => {
  state.qualityUnitsOfMeasureLoading = value
}

const setQualityUnitsOfMeasure = (state: State, value: WizardStateModels.SelectOption[]) => {
  state.qualityUnitsOfMeasure = value
}

const setReVerify = (state: State, value: boolean) => {
  state.reVerifyNeeded = value
}

const resetCurrentQuestionMeta = (state: State, question: QuestionModels.Question) => {
  if (question?.type === QuestionModels.QuestionType.SELECT) {
    state.currentQuestion = {
      type: QuestionModels.QuestionType.SELECT,
      select: {
        required: true,
        valid: false,
        dirty: false,
        path: '/submission/questionnaire',
      },
      values: [],
    }
  } else if (question?.type === QuestionModels.QuestionType.ATTACHMENT) {
    state.currentQuestion = {
      type: question.type as QuestionModels.QuestionType.ATTACHMENT,
      value: {
        required: question.required || false,
        valid: false,
        dirty: false,
        path: '/submission/questionnaire',
      },
    }
  } else if (
    [
      QuestionModels.QuestionType.TEXT,
      QuestionModels.QuestionType.DATE,
      QuestionModels.QuestionType.NUMERIC,
    ].includes(question?.type)
  ) {
    state.currentQuestion = {
      type: question.type as
        | QuestionModels.QuestionType.DATE
        | QuestionModels.QuestionType.NUMERIC
        | QuestionModels.QuestionType.TEXT,
      value: {
        required: true,
        valid: false,
        dirty: false,
        path: '/submission/questionnaire',
      },
    }
  }
}

const setTransactions = (state: State, transactions: string[]) => {
  state.transactions = transactions
}

const setReviewSectionVisited = (state: State, value: boolean) => {
  state.reviewSectionVisited = value
}

export default {
  resetCurrentQuestionMeta,
  resetMedicationDiagnosisSecondaryCodeMeta,
  resetPrescriberMeta,
  setActiveFeatures,
  setAuthorized,
  setAuthorization,
  setCancellationMeta,
  setCancellationRequestMeta,
  setCSRFToken,
  setCurrentQuestionMeta,
  setDebug,
  setDetailsSectionMeta,
  setError,
  setExpiration,
  setIsCancellationLoading,
  setIsLinksLoading,
  setLastActive,
  setMessage,
  setMedicationDiagnosisMeta,
  setMedicationDiagnosisPrimaryCodeMeta,
  setMedicationDiagnosisSecondaryCodeMeta,
  setMedicationPrescribedMeta,
  setMedicationPrescribedDetailsMeta,
  setMedicationProductCodeMeta,
  setMedicationQuantityMeta,
  setPatientAddressMeta,
  setPatientInformationMeta,
  setPatientSectionMeta,
  setPharmacyInformationMeta,
  setPrescriberSectionMeta,
  setPrescriberInformationMeta,
  setPrescriberAddressMeta,
  setProviders,
  setProvidersLoading,
  setSubmissionId,
  setReVerify,
  setToken,
  setTransactions,
  setUser,
  setActivityMonitoringInterval,
  setPageLoading,
  setQualityUnitsOfMeasure,
  setQualityUnitsOfMeasureLoading,
  setReviewSectionVisited,
}
