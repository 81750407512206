export interface InputMeta {
  id?: string
  disabled?: boolean
  dirty?: boolean
  hidden?: boolean
  path?: string
  required?: boolean
  valid?: boolean
}

export const InputMetaKeys: string[] = [
  'id',
  'disabled',
  'dirty',
  'hidden',
  'path',
  'required',
  'valid',
]

export const isInputMeta = (item: any): item is InputMeta => {
  const itemKeys = Object.keys(item)
  return !itemKeys.some(key => !InputMetaKeys.includes(key))
}
