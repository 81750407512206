export enum StateCode {
  AL,
  AK,
  AZ,
  AR,
  CA,
  CO,
  CT,
  DE,
  DC,
  FL,
  GA,
  HI,
  ID,
  IL,
  IN,
  IA,
  KS,
  KY,
  LA,
  ME,
  MD,
  MA,
  MI,
  MN,
  MS,
  MO,
  MT,
  NE,
  NV,
  NH,
  NJ,
  NM,
  NY,
  NC,
  ND,
  OH,
  OK,
  OR,
  PW,
  PA,
  RI,
  SC,
  SD,
  TN,
  TX,
  UT,
  VT,
  VA,
  WA,
  WV,
  WI,
  WY,
}
export type StateCodeStrings = keyof typeof StateCode

export interface Address {
  lineOne: string | null
  lineTwo?: string | null
  city: string | null
  state: StateCodeStrings | null
  zip: string | null
}

export interface AddressPartial {
  lineOne?: string | null
  lineTwo?: string | null
  city?: string | null
  state?: StateCodeStrings | null
  zip?: string | null
}
