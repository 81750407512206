<template>
  <div :id="'cancel-request-block-container-' + elementKey" class="cancel-request-block-container">
    <div class="request-input reason-input">
      <select-input
        :element-key="'reason-' + elementKey"
        label="Reason"
        :options="reasonOptions"
        option-label-key="label"
        option-value-key="value"
        :required="!!meta?.reason?.required"
        :invalid="!!fieldsMeta?.reason?.dirty && !fieldsMeta?.reason.valid"
        :value="values.reason || undefined"
        @onSelection="inputHandlers.reason"
      />
    </div>
    <div class="request-input notified-input">
      <radio-button
        :element-key="'patient-notified-' + elementKey"
        label="Patient Notified"
        :options="notifiedOptions"
        :required="!!meta?.patientHasBeenNotified?.required"
        :invalid="
          !!fieldsMeta?.patientHasBeenNotified?.dirty && !fieldsMeta?.patientHasBeenNotified.valid
        "
        :value="values.patientHasBeenNotified || undefined"
        @onSelection="inputHandlers.patientHasBeenNotified"
      />
    </div>
    <div class="request-input additional-notes-input">
      <text-area-input
        :element-key="'notes-' + elementKey"
        label="Additional Notes"
        :validation="validations.additionalNotes"
        :required="!!meta?.additionalNotes?.required"
        :invalid="!!fieldsMeta?.additionalNotes?.dirty && !fieldsMeta?.additionalNotes.valid"
        :value="values.additionalNotes || undefined"
        @onInput="inputHandlers.additionalNotes"
        @onValidate="validationHandlers.additionalNotes"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid'
import { defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import { RadioButton, SelectInput, TextAreaInput } from '@/components/shared'
import { WizardStateModels } from '@/models/store'
import { CancellationModels } from '@/models/cancellation'
import { cancelOptions } from '@/modules/options'
import { inputValidations } from '@/modules/validations'

type Options = { label: string; value: string }[]

export default defineComponent({
  name: 'CancellationRequestBlock',
  emits: ['on-input', 'on-meta'],
  components: { RadioButton, SelectInput, TextAreaInput },
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    request: {
      type: Object as PropType<CancellationModels.CancelRequest>,
      default: () => ({
        reason: null,
        patientHasBeenNotified: null,
        additionalNotes: null,
      }),
    },
    reasonOptions: {
      type: Array as PropType<Options>,
      default: () => cancelOptions,
    },
    notifiedOptions: {
      type: Array as PropType<Options>,
      default: () => [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    meta: {
      type: Object as PropType<WizardStateModels.WizardCancellationMeta>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const values = ref<CancellationModels.CancelRequest>({ ...props.request })
    const fieldsMeta = ref<WizardStateModels.WizardCancellationMeta>({ ...props.meta })
    const validations = {
      additionalNotes: (value: string | null): boolean =>
        value === null ||
        inputValidations.textLengthValidation(
          value,
          inputValidations.LENGTH_TWO_THOUSAND,
          inputValidations.LENGTH_ONE
        ),
    }

    const setValues = (value: CancellationModels.CancelRequest) => {
      values.value = { ...values.value, ...value }
    }

    const setMeta = (value: WizardStateModels.WizardCancellationMeta) => {
      fieldsMeta.value = { ...fieldsMeta.value, ...value }
    }

    const inputHandlers = {
      reason: (value: CancellationModels.CancelReason) => {
        setValues({ reason: value })
        setMeta({ ...fieldsMeta.value, reason: { ...fieldsMeta.value.reason, dirty: true } })
      },
      patientHasBeenNotified: (value: boolean[]) => {
        setValues({ patientHasBeenNotified: value?.[0] || null })
        setMeta({
          ...fieldsMeta.value,
          patientHasBeenNotified: { ...fieldsMeta.value.patientHasBeenNotified, dirty: true },
        })
      },
      additionalNotes: (value: string) => {
        setValues({ additionalNotes: value })
        setMeta({
          ...fieldsMeta.value,
          additionalNotes: { ...fieldsMeta.value.additionalNotes, dirty: true },
        })
      },
    }

    const validationHandlers = {
      reason: (valid: boolean) =>
        setMeta({ ...fieldsMeta.value, reason: { ...fieldsMeta.value.reason, valid } }),
      patientHasBeenNotified: (valid: boolean) =>
        setMeta({
          ...fieldsMeta.value,
          patientHasBeenNotified: { ...fieldsMeta.value.patientHasBeenNotified, valid },
        }),
      additionalNotes: (valid: boolean) =>
        setMeta({
          ...fieldsMeta.value,
          additionalNotes: { ...fieldsMeta.value.additionalNotes, valid },
        }),
    }

    watch(values, values => context.emit('on-input', values))
    watch(fieldsMeta, validation => context.emit('on-meta', validation))
    onBeforeMount(() => (values.value = props.request))

    return {
      fieldsMeta,
      inputHandlers,
      validations,
      values,
      validationHandlers,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/input';

.cancel-request-block-container {
  display: flex;
  flex-flow: row wrap;
  gap: 2em;
}

.reason-input {
  width: 45%;
}

.notified-input {
  width: 45%;
}

.additional-notes-input {
  width: 100%;
}
</style>
