<template>
  <div id="payer-questionnaire">
    <form-group-section element-key="payer-questionnaire-group" section-label="Question Set">
      <div class="questionnaire">
        <transition name="question-slide">
          <div v-if="isCheckingForNextQuestion" class="loader-container">
            <div class="loading-spinner"></div>
          </div>
          <div v-else-if="question !== null" :key="'question-number-' + questionNumber">
            <question-block
              element-key="question-block"
              :question="question"
              :meta="meta"
              :error="error"
              :isLoading="uploadingAttachment"
              @onMeta="updateMeta"
              @onInput="updateValue"
              @onUpload="uploadAttachment"
              @onDownload="downloadAttachment"
            ></question-block>
            <div class="question-submit">
              <div v-if="submitting" class="loading-spinner white"></div>
              <button
                v-else
                id="question-submit-answer-button"
                class="wl-button center"
                @click="submit"
                :disabled="!isQuestionValid"
              >
                Submit Answer
              </button>
            </div>
            <div class="disclaimer">
              <span
                >Disclaimer: Due to the nature of the question sets, all answers are considered
                final.</span
              >
            </div>
          </div>
          <div class="complete-container" v-else>
            <div class="complete-congrats">
              <div class="wiggle-animation">&#x1F389;</div>
              <h2>You are one step closer!</h2>
              <div class="wiggle-animation">&#x1F389;</div>
            </div>
            <div class="complete-text">
              There are no more questions at this time. Please check your authorization status as it
              may take some time for the payers response.
            </div>
            <button
              id="question-back-to-dashboard-button"
              class="ms-button complete-button"
              @click="completeButtonHandler"
            >
              Back to the Status Dashboard
            </button>
          </div>
        </transition>
      </div>
    </form-group-section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch, ref, onBeforeMount } from 'vue'
import { FormGroupSection } from '@/components/shared'
import { QuestionBlock } from '@/components/wizard/blocks'
import { useStore } from 'vuex'
import { QuestionModels } from '@/models/questions'
import { useRoute, useRouter } from 'vue-router'
import { doNothing, getMyndviewUrl } from '@/utils'

export default defineComponent({
  name: 'PayerQuestionnaireSection',
  components: { QuestionBlock, FormGroupSection },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const question = computed(() => store.getters['questions/getCurrentQuestion'])
    const error = computed(() => store.getters['questions/getError'])
    const meta = computed(() => store.getters['wizard/getCurrentQuestionMeta'])
    const isAttachmentWithAttachmentID = computed(
      () =>
        QuestionModels.isAttachmentQuestion(question.value) &&
        (!question.value.required || (!!question.value.attachmentId && meta.value.value?.valid))
    )
    const isSelectQuestionAndValid = computed(
      () =>
        QuestionModels.isSelectQuestion(question.value) &&
        meta.value.select?.valid &&
        !meta.value?.values?.some((metaVal: any) => !metaVal.valid)
    )
    const isQuestionValid = computed(() => {
      return (
        isAttachmentWithAttachmentID.value ||
        isSelectQuestionAndValid.value ||
        meta.value.value?.valid
      )
    })
    const token = computed(() => store.getters['getAuthToken'])
    const isCheckingForNextQuestion = computed(
      () => store.getters['questions/isCheckingForNextQuestion']
    )
    const questionNumber = computed(() => store.getters['questions/getQuestionNumber'])
    const submitting = ref(false)
    const uploadingAttachment = ref(false)

    const submit = () => {
      submitting.value = true
      store.dispatch('questions/sendAnswer').then(result => {
        if (result) {
          store.dispatch('questions/getQuestion').then(hasQuestion => {
            if (hasQuestion) {
              store.commit('wizard/resetCurrentQuestionMeta', question.value)
              submitting.value = false
            }
          })
        }
      })
    }

    const downloadAttachment = (attachmentId: string) =>
      store.dispatch('questions/downloadAttachment', attachmentId).catch(doNothing)

    const uploadAttachment = (attachment: any) => {
      uploadingAttachment.value = true
      store.dispatch('questions/uploadAttachment', attachment).catch(() => {
        uploadingAttachment.value = false
      })
    }

    const updateValue = (value: QuestionModels.Question) => {
      store.commit('questions/setQuestionValues', value)
    }

    const updateMeta = (value: any) => {
      store.commit('wizard/setCurrentQuestionMeta', value)
    }

    const completeButtonHandler = () => {
      const url = `${getMyndviewUrl(document.location.origin)}/pa-status`
      window.location.replace(url)
    }

    watch(question, question => {
      if (!question) {
        submitting.value = false
      } else if (QuestionModels.isAttachmentQuestion(question) && !!question.attachmentId) {
        updateMeta({ ...meta.value, value: { ...meta.value.value, valid: true } })
        uploadingAttachment.value = false
      }
    })

    onBeforeMount(() => {
      store.commit('questions/resetQuestionNumber')
      store.commit('questions/setCheckingForNextQuestion', true)
    })

    watch(
      token,
      () => {
        const submissionId = route?.query?.submission_id
        if (submissionId) {
          store.commit('setSubmissionIdOnRoot', submissionId)
          store
            .dispatch('questions/getQuestion')
            .then(hasQuestion => {
              if (hasQuestion) {
                store.commit('wizard/resetCurrentQuestionMeta', question.value)
              }
            })
            .catch(() => {
              router?.push({ name: 'Error' })
            })
        } else {
          router?.push({ name: 'Error' })
        }
      },
      { immediate: true }
    )

    return {
      error,
      isCheckingForNextQuestion,
      isQuestionValid,
      uploadingAttachment,
      meta,
      submitting,
      question,
      questionNumber,

      completeButtonHandler,
      downloadAttachment,
      submit,
      uploadAttachment,
      updateValue,
      updateMeta,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';

.question-slide-enter-active {
  transition: all 0.5s ease-in 0.8s;
  .question {
    display: block;
    position: absolute;
  }
}
.question-slide-leave-active {
  transition: opacity 0.4s ease 0.4s;
  .question {
    display: block;
    position: absolute;
  }
}
.question-slide-enter-from {
  opacity: 0;
  transform: translateX(100px);
  .question {
    display: block;
    position: absolute;
  }
}
.question-slide-leave-to {
  opacity: 0;
  .question {
    display: block;
    position: absolute;
  }
}

.loader-container {
  display: flex;
  width: 100%;
  padding: 50px;
  justify-content: center;
}

.loading-spinner {
  margin-right: 75px;
}

.questionnaire {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.disclaimer {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0;
  color: $myndshft-dark-gray;
}

.question-submit {
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  padding: 10px;
}

.complete-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: $myndshft-light-black;
}

.complete-congrats {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.complete-text {
  display: flex;
  width: 60%;
  padding: 20px;
}

.complete-button {
}
</style>
