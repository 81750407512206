import { MessageModels } from '../models/message'

const doNothing = (): void => void 0
class UIDisplayMessage {
  messageTitle: string
  messageLineOne?: string | null
  messageLineTwo?: string | null
  messageLineThree?: string | null
  bannerUrl?: string | null
  bannerTitle?: string | null
  buttonLabel?: string | null
  action?: any | null

  constructor(messageLineOne?: string) {
    this.messageTitle = 'Uh oh!'
    this.messageLineOne = messageLineOne || null
    this.messageLineTwo = null
    this.messageLineThree = null
    this.bannerUrl = null
    this.buttonLabel = null
    this.action = null
  }

  setMessage(value: MessageModels.MessageState) {
    this.messageTitle = value?.messageTitle || 'Uh oh!'
    this.messageLineOne = value?.messageLineOne || null
    this.messageLineTwo = value?.messageLineTwo || null
    this.messageLineThree = value?.messageLineThree || null
    this.bannerUrl = value?.bannerUrl || null
    this.buttonLabel = value?.buttonLabel || null
    this.action = value?.action || null
    return this
  }

  setMessageTitle(value: string) {
    this.messageTitle = value
    return this
  }

  setMessageLineOne(value: string) {
    this.messageLineOne = value
    return this
  }

  setMessageLineTwo(value: string) {
    this.messageLineTwo = value
    return this
  }

  setMessageLineThree(value: string) {
    this.messageLineThree = value
    return this
  }

  setBannerUrl(value: string) {
    this.bannerUrl = value
    return this
  }

  setBannerTitle(value: string) {
    this.bannerTitle = value
    return this
  }

  setButtonLabel(value: string) {
    this.buttonLabel = value
    return this
  }

  setAction(value: any) {
    this.action = value
    return this
  }

  getUiMessage(): MessageModels.MessageState {
    const error: MessageModels.MessageState = {
      messageTitle: this.messageTitle || '',
      messageLineOne: this.messageLineOne || '',
    }
    if (this.messageLineTwo) {
      error.messageLineTwo = this.messageLineTwo
    }
    if (this.messageLineThree) {
      error.messageLineThree = this.messageLineThree
    }
    if (this.bannerTitle) {
      error.bannerTitle = this.bannerTitle
    }
    if (this.bannerUrl) {
      error.bannerUrl = this.bannerUrl
    }
    if (this.buttonLabel) {
      error.buttonLabel = this.buttonLabel
    }
    if (this.action) {
      error.action = this.action
    }

    return error
  }
}

const getBFFErrorMessage = (error: any) => {
  return error?.response?.data?.error?.message || error.message
}

export { doNothing, UIDisplayMessage, getBFFErrorMessage }
