<template>
  <div :id="'information-fields-' + elementKey" class="information-fields-container">
    <div class="information-input">
      <text-input
        :element-key="'patient-first-name-' + elementKey"
        label="First Name"
        :required="!!meta?.firstName?.required"
        :validation="validations.firstName"
        :invalid="!!fieldsMeta?.firstName?.dirty && !fieldsMeta?.firstName.valid"
        invalidHintText="This field is required and must be 35 characters or less."
        :value="values.firstName || undefined"
        @onInput="inputHandlers.firstName"
        @onValidate="validationHandlers.firstName"
      />
    </div>
    <div class="information-input">
      <text-input
        label="Middle Name"
        :element-key="'patient-middle-name-' + elementKey"
        :required="!!meta?.middleName?.required"
        :validation="validations.middleName"
        :invalid="!!fieldsMeta?.middleName?.dirty && !fieldsMeta?.middleName.valid"
        invalidHintText="Although this field is NOT required, when present, it must be 35 characters or less."
        :value="values.middleName || undefined"
        @onInput="inputHandlers.middleName"
        @onValidate="validationHandlers.middleName"
      />
    </div>
    <div class="information-input">
      <text-input
        label="Last Name"
        :element-key="'patient-last-name-' + elementKey"
        :required="!!meta?.lastName?.required"
        :validation="validations.lastName"
        :invalid="!!fieldsMeta?.lastName?.dirty && !fieldsMeta?.lastName.valid"
        invalidHintText="This field is required and must be 35 characters or less."
        :value="values.lastName"
        @onInput="inputHandlers.lastName"
        @onValidate="validationHandlers.lastName"
      />
    </div>
    <div class="information-input">
      <date-input
        label="Date of Birth"
        :element-key="'patient-dob-' + elementKey"
        :max="new Date().toISOString()"
        :required="!!meta?.dob?.required"
        :validation="validations.dob"
        :invalid="!!fieldsMeta?.dob?.dirty && !fieldsMeta?.dob.valid"
        :value="values.dob"
        @onInput="inputHandlers.dob"
        @onValidate="validationHandlers.dob"
      />
    </div>
    <div class="information-input">
      <select-input
        label="Gender"
        :element-key="'patient-gender-' + elementKey"
        placeholder="Select Gender"
        option-label-key="label"
        option-value-key="value"
        :options="genderOptions"
        :required="!!meta?.gender?.required"
        :validation="validations.gender"
        :invalid="!!fieldsMeta?.gender?.dirty && !fieldsMeta?.gender.valid"
        :value="values.gender"
        @onSelection="inputHandlers.gender"
        @onValidate="validationHandlers.gender"
      />
    </div>
    <div class="information-input">
      <phone-input
        label="Phone"
        :element-key="'patient-phone-' + elementKey"
        :required="!!meta?.phone?.required"
        :invalid="!!fieldsMeta?.phone?.dirty && !fieldsMeta?.phone.valid"
        invalidHintText="This field requires a valid phone number."
        :value="values.phone || undefined"
        @onInput="inputHandlers.phone"
        @onValidate="validationHandlers.phone"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid'
import { defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import { DateInput, PhoneInput, SelectInput, TextInput } from '@/components/shared'
import { WizardStateModels } from '@/models/store'
import { PatientModels } from '@/models/submission'
import { inputValidations } from '@/modules/validations'

type StateOptions = { label: string; value: string }[]

export default defineComponent({
  name: 'PatientInformationBlock',
  emits: ['on-input', 'on-meta'],
  components: { DateInput, PhoneInput, SelectInput, TextInput },
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    patient: {
      type: Object as PropType<PatientModels.PatientInformation>,
      default: () => ({
        firstName: null,
        middleName: null,
        lastName: null,
        dob: null,
        gender: null,
        phone: null,
        address: null,
      }),
    },
    genderOptions: {
      type: Array as PropType<StateOptions>,
      default: () => [],
    },
    meta: {
      type: Object as PropType<WizardStateModels.WizardPatientInformationFields>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const values = ref<PatientModels.PatientPartial>({ ...props.patient })
    const fieldsMeta = ref<WizardStateModels.WizardPatientInformationFields>({ ...props.meta })
    const validations = {
      firstName: (value: string): boolean =>
        inputValidations.textLengthValidation(value, inputValidations.LENGTH_THIRTY_FIVE),
      middleName: (value?: string | null): boolean =>
        !value || inputValidations.textLengthValidation(value, inputValidations.LENGTH_THIRTY_FIVE),
      lastName: (value: string): boolean =>
        inputValidations.textLengthValidation(value, inputValidations.LENGTH_THIRTY_FIVE),
      dob: (value: string): boolean => inputValidations.some(value),
      gender: (value: string): boolean => inputValidations.some(value),
    }

    const setValues = (value: PatientModels.PatientPartial) => {
      values.value = { ...values.value, ...value }
    }

    const setMeta = (value: WizardStateModels.WizardPatientInformationFields) => {
      fieldsMeta.value = { ...fieldsMeta.value, ...value }
    }

    const inputHandlers = {
      firstName: (value: string) => {
        setValues({ firstName: value })
        setMeta({ firstName: { ...fieldsMeta.value.firstName, dirty: true } })
      },
      middleName: (value: string) => {
        setValues({ middleName: value })
        setMeta({ middleName: { ...fieldsMeta.value.middleName, dirty: true } })
      },
      lastName: (value: string) => {
        setValues({ lastName: value })
        setMeta({ lastName: { ...fieldsMeta.value.lastName, dirty: true } })
      },
      dob: (value: string) => {
        setValues({ dob: value })
        setMeta({ dob: { ...fieldsMeta.value.dob, dirty: true } })
      },
      gender: (value: PatientModels.GenderStrings | null) => {
        setValues({ gender: value })
        setMeta({ gender: { ...fieldsMeta.value.gender, dirty: true } })
      },
      phone: (value: string) => {
        setValues({ phone: value })
        setMeta({ phone: { ...fieldsMeta.value.phone, dirty: true } })
      },
    }

    const validationHandlers = {
      firstName: (valid: boolean) =>
        setMeta({ firstName: { ...fieldsMeta.value.firstName, valid } }),
      middleName: (valid: boolean) =>
        setMeta({ middleName: { ...fieldsMeta.value.middleName, valid } }),
      lastName: (valid: boolean) => setMeta({ lastName: { ...fieldsMeta.value.lastName, valid } }),
      dob: (valid: boolean) => setMeta({ dob: { ...fieldsMeta.value.dob, valid } }),
      gender: (valid: boolean) => setMeta({ gender: { ...fieldsMeta.value.gender, valid } }),
      phone: (valid: boolean) => setMeta({ phone: { ...fieldsMeta.value.phone, valid } }),
    }

    watch(values, values => context.emit('on-input', values))
    watch(fieldsMeta, validation => context.emit('on-meta', validation))
    onBeforeMount(() => (values.value = props.patient))

    return {
      fieldsMeta,
      inputHandlers,
      values,
      validations,
      validationHandlers,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/input';

.information-fields-container {
  display: flex;
  gap: 2em;
  flex-flow: row wrap;
}

.information-input {
  width: 324px;
}
</style>
