import { State } from './state'
import { SummaryStateModels as StateModels } from '@/models/store'

const setSummary = (state: State, value: StateModels.Summary) => {
  state.submission = value?.submission || null
  state.status = value?.status || null
}

const setStatus = (state: State, value: StateModels.SummaryStatus | null) => {
  state.status = value
}

const setSubmission = (state: State, value: StateModels.SummarySubmission | null) => {
  state.submission = value
}

export default {
  setSummary,
  setStatus,
  setSubmission,
}
