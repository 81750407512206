<template>
  <div id="drug-and-diagnosis-group">
    <form-group-section element-key="drug-group" section-label="Drug and Diagnosis">
      <form-field-set-section
        element-key="primary-drug-section"
        section-label="Drug"
        color="secondary"
      >
        <drug-block
          element-key="primary-drug-fields"
          :drug="primaryDrug"
          :meta="primaryDrugMeta"
          :substitution-options="options.substitutionOptions"
          :quantity-qualifier-options="options.prescriptionQuantityQualifierOptions"
          :units-of-measure-options="unitsOfMeasureOptions"
          :transactions="transactions || []"
          @onInput="primaryDrugInputHandler"
          @onMeta="primaryDrugMetaHandler"
        >
          <div>
            <form-field-set-inner-section
              color="secondary"
              element-key="primary-drug-clinical-information-qualifier"
              section-label="Clinical Diagnosis Information"
            >
              <select-input
                :id="'primary-drug-clinical-information-qualifier-selection'"
                :element-key="'primary-drug-clinical-information-qualifier-selection'"
                class="clinical-information-qualifier input-large"
                label="Clinical Information Qualifier"
                placeholder="Select Information Qualifier"
                :options="options.clinicalInformationQualifiersOptions"
                :required="
                  !!primaryDrugMeta?.diagnosis?.clinicalInformationQualifier?.required &&
                  !isBenefitsTransactionOnly
                "
                :validation="clinicalInformationValidation"
                :invalid="
                  !!primaryDrugMeta?.diagnosis?.clinicalInformationQualifier?.dirty &&
                  !primaryDrugMeta?.diagnosis?.clinicalInformationQualifier?.valid
                "
                :value="primaryDrug?.diagnosis?.clinicalInformationQualifier || undefined"
                :data-public="true"
                @onSelection="clinicalInformationQualifierSelectionHandler"
                @onValidate="clinicalInformationQualifierMetaHandler"
              ></select-input>
            </form-field-set-inner-section>
            <form-field-set-inner-section
              color="secondary"
              element-key="primary-drug-primary-diagnosis-section"
              section-label="Primary Diagnosis"
            >
              <diagnosis-block
                element-key="primary-drug-primary-diagnosis"
                :code="primaryDrug?.diagnosis?.primary"
                :diagnosis-qualifier-options="options.diagnosisCodeQualifierOptions"
                :meta="primaryDrugMeta?.diagnosis?.primary"
                :transactions="transactions || []"
                @onInput="primaryDiagnosisCodeHandler"
                @onMeta="primaryDiagnosisMetaHandler"
              ></diagnosis-block>
            </form-field-set-inner-section>
            <div v-if="!showSecondaryDiagnosis" class="button-wrapper">
              <span class="add-button" @click="addSecondaryDiagnosis">
                + Add Additional Diagnosis
              </span>
            </div>
            <form-field-set-inner-section
              v-if="showSecondaryDiagnosis"
              color="tertiary"
              element-key="primary-drug-secondary-diagnosis-section"
              section-label="Additional Diagnosis"
            >
              <diagnosis-block
                element-key="primary-drug-secondary-diagnosis"
                :code="primaryDrug?.diagnosis?.secondary"
                :diagnosis-qualifier-options="options.diagnosisCodeQualifierOptions"
                :meta="primaryDrugMeta?.diagnosis?.secondary"
                :transactions="transactions || []"
                @onInput="secondaryDiagnosisCodeHandler"
                @onMeta="secondaryDiagnosisMetaHandler"
              ></diagnosis-block>
            </form-field-set-inner-section>
            <div v-if="showSecondaryDiagnosis" class="remove-button-wrapper">
              <button class="remove-button" @click="removeSecondaryDiagnosis">Remove</button>
            </div>
          </div>
        </drug-block>
      </form-field-set-section>
    </form-group-section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import { DiagnosisBlock, DrugBlock } from '@/components/wizard/blocks'
import {
  FormGroupSection,
  FormFieldSetSection,
  FormFieldSetInnerSection,
  SelectInput,
} from '@/components/shared'
import { PrescriptionDrugModels, QualifierModels, DiagnosisModels } from '@/models/submission'
import { WizardStateModels } from '@/models/store'
import * as options from '@/modules/options'
import { inputValidations } from '@/modules/validations'
import { TransactionOptions } from '@/models/wizard/transactions'

export default defineComponent({
  name: 'DrugAndDiagnosisSection',
  components: {
    SelectInput,
    DiagnosisBlock,
    FormFieldSetInnerSection,
    DrugBlock,
    FormFieldSetSection,
    FormGroupSection,
  },
  setup() {
    const store = useStore()
    const primaryDrug = computed(() => store.getters['submission/getMedicationPrescribed'])
    const primaryDrugMeta = computed(() => store.getters['wizard/getMedicationPrescribedMeta'])
    const unitsOfMeasureOptions = computed(() => store.getters['wizard/getQualityUnitsOfMeasure'])
    const showSecondaryDiagnosis = ref<boolean>(false)
    const clinicalInformationValidation = (text?: string | null) => inputValidations.some(text)
    const transactions = computed(() => store.getters['wizard/getTransactions'])
    const isBenefitsTransactionOnly = computed(
      () =>
        transactions.value.includes(TransactionOptions.ELIGIBILITY_AND_BENEFITS) &&
        !transactions.value.includes(TransactionOptions.PRIOR_AUTH)
    )
    const showDiagnosisSection = computed(
      () => !transactions.value.length || transactions.value.includes(TransactionOptions.PRIOR_AUTH)
    )

    const primaryDrugInputHandler = (drugValue: PrescriptionDrugModels.PartialPrescriptionDrug) => {
      store.commit('submission/setMedicationPrescribed', drugValue)
    }
    const primaryDrugMetaHandler = (
      drugMeta: WizardStateModels.WizardMedicationPrescribedFields
    ) => {
      store.commit('wizard/setMedicationPrescribedMeta', {
        ...primaryDrugMeta.value,
        ...drugMeta,
        diagnosis: { ...primaryDrugMeta.value.diagnosis },
      })
    }

    const clinicalInformationQualifierSelectionHandler = (
      value: QualifierModels.ClinicalInformationQualifierStrings
    ) => {
      store.commit('submission/setMedicationDiagnosis', { clinicalInformationQualifier: value })
      store.commit('wizard/setMedicationDiagnosisMeta', {
        clinicalInformationQualifier: {
          ...primaryDrugMeta?.value?.diagnosis?.clinicalInformationQualifier,
          dirty: true,
        },
      })
    }

    const clinicalInformationQualifierMetaHandler = (valid: boolean) => {
      store.commit('wizard/setMedicationDiagnosisMeta', {
        clinicalInformationQualifier: {
          ...primaryDrugMeta?.value?.diagnosis?.clinicalInformationQualifier,
          valid,
        },
      })
    }

    const primaryDiagnosisCodeHandler = (value: DiagnosisModels.DiagnosisCode) =>
      store.commit('submission/setMedicationDiagnosisPrimaryCode', value)
    const secondaryDiagnosisCodeHandler = (value: DiagnosisModels.DiagnosisCode) =>
      store.commit('submission/setMedicationDiagnosisSecondaryCode', value)

    const primaryDiagnosisMetaHandler = (value: WizardStateModels.WizardMedicationDiagnosisCode) =>
      store.commit('wizard/setMedicationDiagnosisPrimaryCodeMeta', value)
    const secondaryDiagnosisMetaHandler = (
      value: WizardStateModels.WizardMedicationDiagnosisCode
    ) => store.commit('wizard/setMedicationDiagnosisSecondaryCodeMeta', value)

    const addSecondaryDiagnosis = () => {
      store.commit('submission/addSecondaryDiagnosis')
      store.commit('wizard/setMedicationDiagnosisSecondaryCodeMeta', {
        code: {
          ...primaryDrugMeta?.value?.diagnosis?.secondary?.code,
          required: true,
          valid: false,
        },
        description: {
          ...primaryDrugMeta?.value?.diagnosis?.secondary?.description,
          required: true,
          valid: false,
        },
        qualifier: {
          ...primaryDrugMeta?.value?.diagnosis?.secondary?.qualifier,
          required: true,
          valid: false,
        },
      })
      showSecondaryDiagnosis.value = true
    }

    const removeSecondaryDiagnosis = () => {
      showSecondaryDiagnosis.value = false
      store.commit('submission/removeSecondaryDiagnosis')
      store.commit('wizard/resetMedicationDiagnosisSecondaryCodeMeta')
    }

    onBeforeMount(() => {
      showSecondaryDiagnosis.value = primaryDrug?.value?.diagnosis?.secondary !== null
    })

    return {
      isBenefitsTransactionOnly,
      options,
      unitsOfMeasureOptions,
      primaryDrug,
      primaryDrugMeta,
      showSecondaryDiagnosis,
      showDiagnosisSection,
      transactions,

      addSecondaryDiagnosis,
      removeSecondaryDiagnosis,
      clinicalInformationValidation,
      clinicalInformationQualifierSelectionHandler,
      clinicalInformationQualifierMetaHandler,
      primaryDrugInputHandler,
      primaryDrugMetaHandler,
      primaryDiagnosisCodeHandler,
      primaryDiagnosisMetaHandler,
      secondaryDiagnosisCodeHandler,
      secondaryDiagnosisMetaHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@myndshft/styles/src/components/wl-button-mixin.scss';

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 24px 0 10px;
}

.clinical-information-qualifier {
  padding: 0 30px 20px 0;
}

.remove-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 24px 0 10px;
}

.add-button {
  cursor: pointer;
  font-weight: 700;
  color: var(--primary-color);
  padding: 8px;
  margin: 8px;
}

.remove-button {
  @include wl-button-mixin($primary: #dd3737, $width: 8.61rem);
  padding: 8px;
  margin: 8px;
}
</style>
