import { QuestionsStateModels } from '@/models/store'
const defaultState = (): QuestionsStateModels.QuestionsState => ({
  currentQuestion: null,
  questionNumber: 0,
  checkingForNextQuestion: null,
  isFirstQuestion: true,
  error: null,
})

export const state = { ...defaultState() }
export type State = typeof state
