import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c2afe7d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "drug-input input-full" }
const _hoisted_3 = { class: "drug-input input-small" }
const _hoisted_4 = { class: "drug-input input-small" }
const _hoisted_5 = {
  key: 0,
  class: "drug-input input-medium"
}
const _hoisted_6 = { class: "drug-input input-medium" }
const _hoisted_7 = { class: "drug-input input-small" }
const _hoisted_8 = { class: "drug-input input-medium" }
const _hoisted_9 = { class: "slot-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'drug-block-' + _ctx.elementKey,
    class: "drug-fields-container"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_text_input, {
        label: "Prescription Name",
        "element-key": 'prescription-name-' + _ctx.elementKey,
        required: !!_ctx.drugMeta?.description?.required,
        validation: _ctx.validations.description,
        invalid: !!_ctx.drugMeta?.description?.dirty && !_ctx.drugMeta.description.valid,
        value: _ctx.values?.description || undefined,
        "data-public": true,
        invalidHintText: "Field is required and must be 105 characters or less.",
        onOnInput: _ctx.inputHandlers.description,
        onOnValidate: _ctx.validationHandlers.description
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_text_input, {
        label: "NDC",
        "element-key": 'prescription-code-' + _ctx.elementKey,
        required: !!_ctx.drugMeta?.product?.code?.required,
        validation: _ctx.validations.productCode,
        invalid: !!_ctx.drugMeta?.product?.code?.dirty && !_ctx.drugMeta.product?.code?.valid,
        invalidHintText: "Field must be a valid 11-digit NDC.",
        value: _ctx.values.product?.code || undefined,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.productCode,
        onOnValidate: _ctx.validationHandlers.productCode
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_text_input, {
        label: "Quantity",
        "element-key": 'prescription-quantity-' + _ctx.elementKey,
        required: !!_ctx.drugMeta?.quantity?.value?.required,
        validation: _ctx.validations.quantityValue,
        invalid: !!_ctx.drugMeta?.quantity?.value?.dirty && !_ctx.drugMeta.quantity?.value?.valid,
        invalidHintText: "Field must be a valid whole number.",
        value: _ctx.values.quantity?.value ? `${_ctx.values.quantity.value}` : undefined,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.quantityValue,
        onOnValidate: _ctx.validationHandlers.quantityValue
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    (_ctx.priorAuthTransactionEnabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_select_input, {
            label: "Quantity List Qualifier",
            "element-key": 'prescription-quantity-qualifier-' + _ctx.elementKey,
            options: _ctx.quantityQualifierOptions,
            placeholder: "Select List Qualifier",
            required: !!_ctx.drugMeta?.quantity?.codeListQualifier?.required,
            validation: _ctx.validations.quantityQualifier,
            invalid: 
          !!_ctx.drugMeta?.quantity?.codeListQualifier?.dirty &&
          !_ctx.drugMeta.quantity?.codeListQualifier?.valid
        ,
            value: _ctx.values.quantity?.codeListQualifier || undefined,
            "data-public": true,
            onOnSelection: _ctx.inputHandlers.quantityQualifier,
            onOnValidate: _ctx.validationHandlers.quantityQualifier
          }, null, 8, ["element-key", "options", "required", "validation", "invalid", "value", "onOnSelection", "onOnValidate"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_select_input, {
        label: "Unit Of Measure (NCI Code)",
        "option-value-key": "value",
        "option-label-key": "label",
        options: _ctx.unitsOfMeasureOptions,
        "element-key": 'prescription-quantity-uom-' + _ctx.elementKey,
        required: !!_ctx.drugMeta?.quantity?.unitOfMeasure?.required,
        validation: _ctx.validations.quantityUnitOfMeasure,
        invalid: 
          !!_ctx.drugMeta?.quantity?.unitOfMeasure?.dirty && !_ctx.drugMeta.quantity?.unitOfMeasure?.valid
        ,
        value: _ctx.values.quantity?.unitOfMeasure || undefined,
        "data-public": true,
        onOnSelection: _ctx.inputHandlers.quantityUnitOfMeasure,
        onOnValidate: _ctx.validationHandlers.quantityUnitOfMeasure
      }, null, 8, ["options", "element-key", "required", "validation", "invalid", "value", "onOnSelection", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_text_input, {
        label: "Days Supply",
        "element-key": 'prescription-days-supply-' + _ctx.elementKey,
        required: !!_ctx.drugMeta?.daysSupply?.required,
        validation: _ctx.validations.daysSupply,
        invalid: !!_ctx.drugMeta?.daysSupply?.dirty && !_ctx.drugMeta.daysSupply?.valid,
        invalidHintText: "Field must be a valid whole number.",
        value: _ctx.values.daysSupply ? `${_ctx.values.daysSupply}` : undefined,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.daysSupply,
        onOnValidate: _ctx.validationHandlers.daysSupply
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_select_input, {
        label: "Substitutions",
        "element-key": 'prescription-substitutions-' + _ctx.elementKey,
        options: _ctx.substitutionOptions,
        placeholder: "Select Substitutions",
        required: !!_ctx.rtbcTransactionEnabled,
        invalid: !!_ctx.drugMeta?.substitutions?.dirty && !_ctx.drugMeta.substitutions?.valid,
        value: _ctx.values.substitutions || undefined,
        "data-public": true,
        onOnSelection: _ctx.inputHandlers.substitutions,
        onOnValidate: _ctx.validationHandlers.substitutions
      }, null, 8, ["element-key", "options", "required", "invalid", "value", "onOnSelection", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 8, _hoisted_1))
}