<template>
  <div>
    <transition name="fade">
      <div v-if="isLinksLoading" class="loading-background-full">
        <div class="loading-spinner"></div>
      </div>
      <div v-else>
        <router-view></router-view>
        <div class="footer" v-if="!initialPageLoading">
          <terms-of-service
            :show-copyright="termsUrl && isAuthorized"
            :url="termsUrl"
            :version="version"
          ></terms-of-service>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import * as logRocket from '@/modules/logrocket'
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Router, useRoute, useRouter } from 'vue-router'
import { TermsOfService } from '@/components/shared'
import { initializeFirebaseApp } from '@/modules/firebase'
import { InactivityTracker } from '@/modules/inactivity'

import { doNothing, getMyndviewUrl, UIDisplayMessage } from '@/utils'
import { SettingStateModels } from '@/models/store'

export default defineComponent({
  name: 'App',
  components: { TermsOfService },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router: Router = useRouter()
    const error = computed(() => store.getters['wizard/getError'])
    const expirationConfig = computed(() => store.getters['config/getExpirationConfig'])
    const termsUrl = computed(() => store.getters['config/getTermsUrl'])
    const version = computed(() => store.getters['config/getVersion'])
    const isAuthorized = computed(() => store.getters['wizard/isAuthorized'])
    const isPageLoading = computed(() => store.getters['wizard/isPageLoading'])
    const settings = computed(() => store.getters['config/getSettings'])
    const authConfig = computed(() => store.getters['config/getAuthConfig'])
    const isLinksLoading = computed(() => store.getters['wizard/getIsLinksLoading'])
    const reVerifyNeeded = computed(() => store.getters['wizard/getReVerify'])
    const initialPageLoading = ref<boolean>(true)

    const action = () => window.location.replace(getMyndviewUrl(document.location.origin))

    const setAndRouteToErrorPage = (message: string) => {
      const uiError = new UIDisplayMessage()
        .setMessage(error.value)
        .setMessageLineOne(message)
        .setButtonLabel('Back to Myndview')
        .setAction(action)
        .getUiMessage()
      store.commit('wizard/setError', uiError)
      router.push({ name: 'Error' }).catch(doNothing).finally()
    }

    const debugPlease = () => {
      store.commit('wizard/setDebug', true)
      console.warn('\nDebug Mode Enabled\n')
    }
    const stopPlease = () => {
      store.commit('wizard/setDebug', false)
      console.warn('\nDebug Mode Disabled\n')
    }

    const setupThePleaseFunctions = () => {
      ;(window as any).debugPlease = debugPlease
      ;(window as any).stopPlease = stopPlease
    }

    const checkParams = () => {
      const params = route.query
      if (Object.keys(params).includes('submission_id')) {
        store.commit('setSubmissionIdOnRoot', params.submission_id)
      }
    }

    const initAfterAuth = () => {
      checkParams()
      InactivityTracker.startInactivityMonitoring(
        expirationConfig.value.inactivityLogoutWarningTime,
        expirationConfig.value.inactivityLogoutWaitTime,
        store
      )
      store.dispatch('wizard/features').catch(doNothing).finally()
      store.dispatch('wizard/providers').catch(doNothing).finally()
      store.dispatch('wizard/getQualityUnitsOfMeasure').catch(doNothing).finally()
      setupThePleaseFunctions()
    }

    const initializeLogRocket = () => {
      const settings = computed(() => store.getters['config/getEnvironmentSettings'])

      logRocket.init(settings.value)
      logRocket.identify(store.getters['wizard/getUser'])
    }

    // Disabled until we are ready to turn use
    // const initializeChatBot = () => {
    //   const chatbotKey = computed(() => store.getters['config/getChatbotKey'])
    //   if (chatbotKey.value) {
    //     createBotScript(chatbotKey.value)
    //   }
    // }

    const setColorsFromSettings = (colors?: any) => {
      const root = document.documentElement

      const primary = colors?.primary
      const secondary = colors?.secondary
      const tertiary = colors?.tertiary

      if (primary) {
        root?.style.setProperty('--primary-color', primary)
      }
      if (secondary) {
        root?.style.setProperty('--secondary-color', secondary)
      }
      if (tertiary) {
        root?.style.setProperty('--tertiary-color', tertiary)
      }
    }

    const setFontFromSettings = async (font?: any) => {
      const domain = window.location.hostname.split('.')?.[0] || ''
      store.commit('wizard/setIsLinksLoading', true)

      if (['rx-submit', 'localhost'].includes(domain)) {
        document.body.style.fontFamily = `'Effra', sans-serif`
      } else if (font?.links?.length && font?.name) {
        const linkPromises: Promise<any>[] = []
        const fontFamily = `${font.name}, 'Effra', sans-serif`

        font.links.forEach((fontLink: SettingStateModels.FontLink) => {
          const promise = new Promise((resolve, reject) => {
            const element = document.createElement('link')
            element.rel = fontLink.rel
            element.href = fontLink.href

            if (fontLink?.crossorigin) {
              element.crossOrigin = fontLink.crossorigin
            }
            if (fontLink.rel === 'stylesheet') {
              element.onload = () => {
                resolve(true)
                store.commit('wizard/setIsLinksLoading', false)
              }
              element.onerror = () => {
                reject()
              }

              document.head.appendChild(element)
            } else {
              document.head.appendChild(element)
              resolve(null)
            }
          })

          linkPromises.push(promise)
        })

        await Promise.all(linkPromises)
        document.body.style.fontFamily = fontFamily
      }

      return true
    }

    const initializeTabSettings = (browserTab?: any) => {
      const title = browserTab?.title
      const favicon = browserTab?.imageUrl

      if (favicon) {
        const link = document.querySelector('#favicon')
        link?.setAttribute('href', favicon)
      }

      if (title) {
        document.title = title
      }
    }

    const initializeStylesFromSettings = () => {
      initializeTabSettings(settings.value.browserTab)
      setColorsFromSettings(settings.value.colors)
      setFontFromSettings(settings.value.font).finally(() => {
        store.commit('wizard/setIsLinksLoading', false)
      })
    }

    watch(reVerifyNeeded, reVerify => {
      if (reVerify) {
        store.dispatch('wizard/verify').then(() => store.commit('wizard/setReVerify', false))
      }
    })

    watch(isAuthorized, authorized => {
      if (authorized) {
        initAfterAuth()
      }
    })

    watch(isPageLoading, loading => {
      if (!loading) {
        initialPageLoading.value = false
      }
    })

    onBeforeMount(() => {
      store
        .dispatch('config/getConfig')
        .then(() => {
          initializeStylesFromSettings()
          initializeFirebaseApp(window.location.hostname, authConfig.value)
          store.dispatch('wizard/features').catch(doNothing).finally()
          store
            .dispatch('wizard/verify')
            .then(() => {
              initializeLogRocket()

              // Disabled until we are ready to turn use
              // initializeChatBot()
            })
            .catch(doNothing)
        })
        .catch(error => setAndRouteToErrorPage(error.message))
    })

    onBeforeUnmount(() => {
      InactivityTracker.stopInactivityMonitoring()
    })

    return {
      isLinksLoading,
      termsUrl,
      isAuthorized,
      initialPageLoading,
      version,
    }
  },
})
</script>

<style lang="scss">
@import '@/styles/global.scss';
@import '@/styles/input.scss';
@import '@myndshft/color-palette/src/colors.scss';
@import '@myndshft/styles/src/components/mixins.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $myndshft-light-black;
}

:root {
  --primary-color: #{$myndshft-blue};
  --secondary-color: #{$myndshft-notification-accent-blue};
  --tertiary-color: #{$myndshft-notification-accent-orange};
}

.footer {
  padding: 50px 0;
}

.loading-background-full {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
  backdrop-filter: blur(10px);
  opacity: 1;
}

.fade-leave-active {
  transition: opacity 0.5s 0.1s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
