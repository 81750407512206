import { ConfigStateModels, SettingStateModels } from '@/models/store'

const defaultSettings = (): SettingStateModels.Settings =>
  ({
    id: '',
    banner: {},
    logo: {},
    colors: {},
    browserTab: {},
    font: {},
  } as SettingStateModels.Settings)

const defaultExpirationConfig = (): ConfigStateModels.ExpirationConfig => ({
  inactivityLogoutWaitTime: 300000,
  inactivityLogoutWarningTime: 900000,
})

const defaultState = (): ConfigStateModels.Config =>
  ({
    chatbotKey: '',
    environment: null,
    expirationConfig: defaultExpirationConfig(),
    settings: defaultSettings(),
    version: null,
    termsUrl: null,
  } as ConfigStateModels.Config)
export const state = { ...defaultState() }
export type State = typeof state
