import axios from 'axios'
import { getBffRoute } from '@/utils'

const SUMMARY_DETAILS_PATH = (submissionId: string) => `/api/submissions/${submissionId}/details`

const getSummaryDetails = async (submissionId: string, auth: string, csrf: string) => {
  if (submissionId) {
    const headers = { authorization: auth, 'x-csrf-token': csrf }
    const response = await axios
      .get(`${getBffRoute()}${SUMMARY_DETAILS_PATH(submissionId)}`, {
        headers,
      })
      .catch(error => {
        throw Error(error.message)
      })
    return response?.data
  } else {
    throw Error('Submission is invalid.')
  }
}

export { getSummaryDetails }
