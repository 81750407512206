import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79924c78"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "information-input-group" }
const _hoisted_3 = { class: "information-input" }
const _hoisted_4 = { class: "information-input" }
const _hoisted_5 = { class: "information-input" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "information-input"
}
const _hoisted_8 = { class: "information-input" }
const _hoisted_9 = { class: "information-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_phone_input = _resolveComponent("phone-input")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'information-fields-' + _ctx.elementKey,
    class: "information-fields-container"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_text_input, {
          label: "NPI",
          invalidHintText: "This field is required and must be a valid NPI.",
          "element-key": 'prescriber-npi-' + _ctx.elementKey,
          required: !!_ctx.meta?.npi?.required,
          validation: _ctx.validations.npi,
          invalid: !!_ctx.fieldsMeta?.npi?.dirty && !_ctx.fieldsMeta?.npi.valid,
          value: _ctx.values.npi || undefined,
          "data-public": true,
          onOnInput: _ctx.inputHandlers.npi,
          onOnValidate: _ctx.validationHandlers.npi
        }, null, 8, ["element-key", "required", "validation", "invalid", "value", "onOnInput", "onOnValidate"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_text_input, {
        label: "First Name",
        invalidHintText: "This field is required and must be 35 characters or less.",
        "element-key": 'prescriber-first-name-' + _ctx.elementKey,
        required: _ctx.firstNameRequired,
        validation: _ctx.validations.firstName,
        invalid: !!_ctx.fieldsMeta?.firstName?.dirty && !_ctx.fieldsMeta?.firstName.valid,
        value: _ctx.values?.firstName || undefined,
        disabled: !!_ctx.values?.organizationName && _ctx.isOnlyBenefitsTransaction,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.firstName,
        onOnValidate: _ctx.validationHandlers.firstName
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "disabled", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_text_input, {
        label: "Last Name",
        invalidHintText: "This field is required and must be 35 characters or less.",
        "element-key": 'prescriber-last-name-' + _ctx.elementKey,
        required: _ctx.lastNameRequired,
        validation: _ctx.validations.lastName,
        invalid: !!_ctx.fieldsMeta?.lastName?.dirty && !_ctx.fieldsMeta?.lastName.valid,
        value: _ctx.values?.lastName || undefined,
        disabled: !!_ctx.values?.organizationName && _ctx.isOnlyBenefitsTransaction,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.lastName,
        onOnValidate: _ctx.validationHandlers.lastName
      }, null, 8, ["element-key", "required", "validation", "invalid", "value", "disabled", "onOnInput", "onOnValidate"])
    ]),
    (_ctx.isOnlyBenefitsTransaction)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, "OR"))
      : _createCommentVNode("", true),
    (_ctx.isOnlyBenefitsTransaction)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_text_input, {
            label: "Organization Name",
            invalidHintText: "This field is required and must be 35 characters or less.",
            "element-key": 'prescriber-organization-name-' + _ctx.elementKey,
            required: _ctx.organizationNameRequired,
            validation: _ctx.validations.organizationName,
            invalid: !!_ctx.fieldsMeta?.organizationName?.dirty && !_ctx.fieldsMeta?.organizationName.valid,
            value: _ctx.values?.organizationName || undefined,
            disabled: !!_ctx.values?.firstName || !!_ctx.values?.lastName,
            "data-public": true,
            onOnInput: _ctx.inputHandlers.organizationName,
            onOnValidate: _ctx.validationHandlers.organizationName
          }, null, 8, ["element-key", "required", "validation", "invalid", "value", "disabled", "onOnInput", "onOnValidate"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_phone_input, {
        label: "Phone",
        invalidHintText: "This field requires a valid phone number.",
        "element-key": 'prescriber-phone-' + _ctx.elementKey,
        required: !!_ctx.meta?.phone?.required,
        invalid: !!_ctx.fieldsMeta?.phone?.dirty && !_ctx.fieldsMeta?.phone.valid,
        value: _ctx.values?.phone || undefined,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.phone,
        onOnValidate: _ctx.validationHandlers.phone
      }, null, 8, ["element-key", "required", "invalid", "value", "onOnInput", "onOnValidate"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_phone_input, {
        label: "Fax",
        invalidHintText: "Although this field is NOT required, when present, it must be a valid phone number.",
        "element-key": 'prescriber-fax-' + _ctx.elementKey,
        required: !!_ctx.meta?.fax?.required,
        invalid: !!_ctx.fieldsMeta?.fax?.dirty && !_ctx.fieldsMeta?.fax.valid,
        value: _ctx.values?.fax || undefined,
        "data-public": true,
        onOnInput: _ctx.inputHandlers.fax,
        onOnValidate: _ctx.validationHandlers.fax
      }, null, 8, ["element-key", "required", "invalid", "value", "onOnInput", "onOnValidate"])
    ])
  ], 8, _hoisted_1))
}