<template>
  <div :id="'phone-container-' + elementKey" class="text-input-container">
    <label
      :id="'phone-container-label-' + elementKey"
      class="form-field-label"
      :data-required="required"
    >
      {{ label }}
    </label>
    <input
      :id="'phone-container-input-' + elementKey"
      type="text"
      class="form-field"
      autocomplete="off"
      @keydown.enter.prevent="ignoreInput"
      @input="inputHandler"
      :value="displayValue"
      :data-error="invalid"
      :data-required="dataRequired"
      :disabled="disabled"
      :placeholder="disabled ? '' : placeholder"
      :data-public="!!dataPublic || undefined"
    />
  </div>
  <div v-if="invalid && invalidHintText">
    <div :id="'phone-container-input-invalid-hint-' + elementKey" class="invalid-hint">
      *{{ invalidHintText }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onBeforeMount, computed } from 'vue'
import { v4 as uuid } from 'uuid'
import { AsYouType, isValidNumberForRegion, parsePhoneNumber } from 'libphonenumber-js'

const REGION = 'US'
const VALID_LENGTH = 14

export default defineComponent({
  name: 'PhoneInput',
  emits: ['on-input', 'on-validate'],
  props: {
    dataPublic: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidHintText: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const inputValue = ref<string | null>(null)
    const displayValue = ref<string | null>(null)
    const dataRequired = computed(() => props.required && !displayValue.value)

    const validate = (value: any): boolean => {
      try {
        if (value) {
          return value.length === VALID_LENGTH && isValidNumberForRegion(value, REGION)
        } else {
          return !props.required
        }
      } catch (error: any) {
        return false
      }
    }

    const ignoreInput = (event: any) => event.preventDefault()

    const inputHandler = (event: any): void => {
      event.preventDefault()

      const value = event?.target?.value

      if (!props.disabled) {
        if (value) {
          displayValue.value =
            value.length < VALID_LENGTH + 1 ? new AsYouType(REGION).input(value) : value
        } else {
          displayValue.value = null
        }
      }
    }

    watch(displayValue, value => {
      if (value) {
        inputValue.value =
          value.length < VALID_LENGTH + 1 ? new AsYouType(REGION).input(value) : value
      } else {
        inputValue.value = null
      }
    })

    watch(inputValue, value => {
      context.emit('on-input', value)
      context.emit('on-validate', validate(value))
    })

    onBeforeMount(() => {
      if (props.value) {
        const phone = new AsYouType(REGION).input(props.value)
        displayValue.value = phone
        inputValue.value = parsePhoneNumber(phone, REGION).formatNational()
      }
    })

    return {
      dataRequired,
      displayValue,
      inputValue,

      ignoreInput,
      inputHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.text-input-container {
  position: relative;
}

.form-field {
  width: 100%;
  padding-right: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &focus {
    outline: none;
  }
}

.invalid-hint {
  padding: 2px;
  color: #f14668;
  font-size: 12px;
  line-height: 14px;
}
</style>
