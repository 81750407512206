import { SubstitutionModels } from '../../models/submission'

const substitutionOptions = [
  {
    label: 'No Product Selection Indicated',
    value: SubstitutionModels.SubstitutionsTypes.NO_PRODUCT_SELECTION_INDICATED,
  },
  {
    label: 'Substitution Not Allowed by Prescriber',
    value: SubstitutionModels.SubstitutionsTypes.SUBSTITUTION_NOT_ALLOWED_BY_PRESCRIBER,
  },
]

export { substitutionOptions }
