<template>
  <div
    :id="'attachment-container-' + elementKey"
    class="attachment-container"
    :data-public="dataPublic"
  >
    <span v-if="fileErrorMessage" class="file-upload-error-message">{{ fileErrorMessage }}</span>
    <label
      :id="'attachment-label-' + elementKey"
      class="form-field-label"
      :data-required="required"
    >
      {{ label }}
    </label>
    <div
      :id="'attachment-input-container-' + elementKey"
      class="attachment-input-container"
      data-private
    >
      <label
        class="attachment-input-label wl-button outlined"
        :disabled="disabled"
        @click="openFileSelect"
      >
        <div v-if="isLoading" class="loading-spinner"></div>
        <span v-else>{{ inputValue?.name ? 'Choose Different File' : 'Choose File' }}</span>
      </label>
      <input
        :id="'attachment-input-' + elementKey"
        ref="fileRef"
        class="attachment-input-hidden"
        type="file"
        @click="clearCurrentFile"
        @change="fileChangeHandler"
        :disabled="disabled"
        accept="application/ti"
        data-private
      />
      <div class="file-name" data-private>
        <span v-if="inputValue?.name && !isLoading">{{ inputValue?.name }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { v4 as uuid } from 'uuid'

interface Attachment {
  name: string
  data: FormData
}

function hasValidExtension(file: any, fileTypes: any[]): boolean {
  if (file && file.name) {
    return fileTypes.includes((file.name.split('.').reverse()[0] || '').toLowerCase())
  }
  return false
}

function hasValidMimeType(file: any, fileTypes: any[]): boolean {
  if (file && file.type) {
    const validMimeTypes = [
      ...fileTypes.map(fileType => `application/${fileType}`),
      ...fileTypes.map(fileType => `image/${fileType}`),
    ]
    return validMimeTypes.includes(file.type)
  }
  return false
}

function isValidFile(file: any, fileTypes: any[]): boolean {
  return hasValidExtension(file, fileTypes) && hasValidMimeType(file, fileTypes)
}
export default defineComponent({
  name: 'AttachmentInput',
  emits: ['on-upload', 'on-validate'],
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    label: {
      type: String,
      default: '',
    },
    fileTypes: {
      type: Array,
      default: () => ['pdf', 'png', 'tiff'],
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const inputValue = ref<Attachment | null>(null)
    const fileRef = ref<any>(null)
    const fileIsValid = ref<boolean>(!props.required)
    const fileErrorMessage = ref<string | null>(null)

    const openFileSelect = () => {
      if (!props.disabled && !props.isLoading) {
        fileRef.value?.click()
      }
    }

    const setFileAndEmit = (file: File) => {
      if (file?.name) {
        const formData = new FormData()
        formData.append('file', file, file.name)
        inputValue.value = { name: file.name, data: formData } as Attachment
      }
    }

    const fileChangeHandler = (event: any): void => {
      try {
        if (event && event.target) {
          const files: Array<File> = Array.from(event.target.files as FileList)
          files.forEach(file => {
            fileIsValid.value = isValidFile(file, props.fileTypes)
            if (fileIsValid.value) {
              const reader = new FileReader()
              reader.readAsBinaryString(file)
              reader.onload = () => {
                if (reader.result) {
                  setFileAndEmit(file)
                }
              }
            } else {
              fileErrorMessage.value = `Invalid file type, File must be one of type(s): (*.${props.fileTypes.join(
                ', *.'
              )})`
            }
          })
        }
      } catch (error: any) {
        fileIsValid.value = false
        fileErrorMessage.value = 'An error occurred uploading the file. Please try again.'
      }
    }

    const clearCurrentFile = (): void => {
      if (fileRef.value && fileRef.value?.target?.value) {
        fileRef.value.target.value = ''
      }
    }

    watch(inputValue, value => context.emit('on-upload', value))
    watch(fileIsValid, valid => context.emit('on-validate', valid))

    return {
      fileRef,
      fileErrorMessage,
      inputValue,

      clearCurrentFile,
      fileChangeHandler,
      openFileSelect,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.attachment-container {
  position: relative;
}

.attachment-input-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
}

.attachment-input-hidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  z-index: -1;
}

.attachment-input-label {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;

  &[disabled='true'] {
    color: #ffffff;
    background-color: #c1c1c1;
    cursor: not-allowed;
    box-shadow: none;
    border: none;
  }
}

.file-name {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 0 10px;
  height: 40px;
}

.file-upload-error-message {
  width: 100%;
  color: $myndshft-required-pink;
  justify-content: center;
}
</style>
