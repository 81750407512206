<template>
  <div :id="'radio-button-container-' + elementKey" class="radio-button-container">
    <label
      :id="'radio-button-container-label-' + elementKey"
      class="form-field-label"
      :data-required="required"
    >
      {{ label }} <span>{{ additionalLabel }}</span>
    </label>
    <div
      :id="'radio-button-options-container-' + elementKey"
      class="radio-button-options-container"
    >
      <div
        :id="'radio-button-option-' + elementKey + '-' + option.key"
        :key="elementKey + '-' + option.key"
        @mousedown.stop="selectionHandler(option)"
        v-for="option in radioButtonComponentOptions"
      >
        <label
          :id="'radio-button-option-label-' + elementKey + '-' + option.key"
          :for="'radio-button-option-input-' + elementKey + '-' + option.key"
          class="option"
          :data-active="option.active"
          :data-disabled="disabled"
        >
          <input
            type="radio"
            :id="'radio-button-option-input-' + elementKey + '-' + option.key"
            :name="'radio-button-option-input-' + elementKey + '-' + option.key"
            :checked="option.active"
            :data-active="option.active"
            :disabled="disabled"
            :data-public="!!dataPublic || undefined"
          />
          <span :data-public="!!dataPublic || undefined">{{ option.label }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType, onBeforeMount } from 'vue'
import { v4 as uuid } from 'uuid'

import { RadioButtonModels } from '@/models/wizard'

interface RadioButtonComponentOption extends RadioButtonModels.RadioButtonOption {
  key: string
  active: boolean
}
type RadioButtonComponentOptions = RadioButtonComponentOption[]

export default defineComponent({
  name: 'RadioButton',
  emits: ['on-selection', 'on-validate'],
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    options: {
      type: Array as PropType<RadioButtonModels.RadioButtonOptions>,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    additionalLabel: {
      type: String,
      default: '',
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Boolean, Array],
    },
    validation: Function,
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const radioButtonComponentOptions = ref<RadioButtonComponentOptions>(
      [] as RadioButtonComponentOptions
    )

    const validate = (value: any): boolean => {
      if (!props.required) {
        return props.validation ? props.validation(value) : !props.validation
      }
      return !!value && props.validation ? props.validation(value) : !props.validation
    }

    const selectionHandler = (option: RadioButtonComponentOption): void => {
      if (!props.disabled) {
        let options = [...radioButtonComponentOptions.value]
        const selectionIndex = options.findIndex(optionsItem => optionsItem.value === option.value)
        if (!props.multiSelect) {
          options = options.map(item => ({ ...item, active: false } as RadioButtonComponentOption))
        }

        options[selectionIndex].active = !options[selectionIndex].active
        radioButtonComponentOptions.value = options

        const selectedOptions = radioButtonComponentOptions.value
          ?.filter(item => item.active)
          ?.map(item => item.value)

        context.emit('on-validate', validate(selectedOptions))
        context.emit('on-selection', selectedOptions)
      }
    }

    const createRadioButtonOptionKey = (label: string): string => {
      return label.replaceAll(' ', '').toLowerCase().trim()
    }

    const createRadioButtonComponentOption = (
      option: RadioButtonModels.RadioButtonOption
    ): RadioButtonComponentOption => {
      const key = createRadioButtonOptionKey(option.label)
      return {
        ...option,
        active: false,
        key,
      } as RadioButtonComponentOption
    }

    const setOptionsAndSelectionsFromProps = (options: RadioButtonModels.RadioButtonOptions) => {
      const newOptions = [] as RadioButtonComponentOptions
      for (const option of options) {
        const radioOption = createRadioButtonComponentOption(option)
        newOptions.push(radioOption)

        if (props.value) {
          if (typeof props.value === 'object') {
            for (const val of props.value) {
              if (option.value === val) {
                radioOption.active = true
              }
            }
          } else if (option.value === props.value) {
            radioOption.active = true
          }
        }
      }
      radioButtonComponentOptions.value = newOptions
    }

    watch(
      () => props.options,
      options => setOptionsAndSelectionsFromProps(options),
      { immediate: true }
    )

    onBeforeMount(() => setOptionsAndSelectionsFromProps(props.options))

    return {
      radioButtonComponentOptions,

      selectionHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';

.radio-button-container {
  display: inherit;
}

.radio-button-options-container {
  display: flex;
  gap: 8px;
}

span {
  padding-left: 2px;
}

input[type='radio'] {
  display: grid;
  width: 0.929em;
  height: 0.929em;
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  transform: translateY(-0.05em);
  place-content: center;
  cursor: pointer;
}

input[type='radio'][data-active='true']::before {
  content: '';
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  font-size: 14px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  white-space: nowrap;
  padding: 0 14px 0 8px;
  user-select: none;
  cursor: pointer;
}

.option[data-active='true'] {
  color: $myndshft-white;
  background: var(--primary-color);
}

.option[data-invalid='true'] {
  border: 1px solid $myndshft-required-pink;
  & > input {
    border: 1px solid $myndshft-required-pink;
  }
}

.option[data-disabled='true'] {
  cursor: not-allowed;
  &[data-active='false'] {
    opacity: 0.5;
  }
}
</style>
