import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { ErrorPage, LandingPage, LoginPage } from '@/components'
import { WizardPage } from '@/components/wizard'
import { wizardSections } from './sections'
import store from '../store'
import Features from '@/services/features'

const baseUrl = process.env.BASE_URL

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    component: LandingPage,
    meta: {
      hidden: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      hidden: true,
    },
    beforeEnter: (_to, _from, next) => {
      Features.getFeatures().then(features => {
        if (features?.includes('isEnabledToUseTheSessionCookieToFetchTheToken')) {
          next()
        } else {
          next({ name: 'Landing' })
        }
      })
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
    meta: {
      hidden: true,
    },
  },
  {
    path: '/submission',
    name: 'Wizard',
    component: WizardPage,
    children: wizardSections,
    meta: {
      hidden: true,
      requiresAuth: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!!from.query && Object.keys(from.query)?.length) {
    to.query = { ...to.query, ...from.query }
  }

  if ((!from.name || from.name === 'Error') && to.name === 'Error') {
    next({ name: 'Landing' })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthorized = store.getters['wizard/isAuthorized']

    if (to.path.includes('questionnaire') && !isAuthorized) {
      to.query = { ...to.query, reRoute: 'questionnaire' }
    }

    if (to.path.includes('cancel-request') && !isAuthorized) {
      to.query = { ...to.query, reRoute: 'cancel-request' }
    }

    if (isAuthorized) {
      next()
    } else {
      next({ name: 'Landing', query: { ...to.query } })
    }
  } else {
    next()
  }
})

export default router
