<template>
  <div id="pharamacy-group">
    <form-group-section element-key="pharmacy-information" section-label="Pharmacy Information">
      <pharmacy-information-block
        element-key="pharmacy-information-block"
        :pharmacy="pharmacy"
        :meta="pharmacyMeta"
        @onInput="pharmacyInformationInputHandler"
        @onMeta="pharmacyInformationMetaHandler"
      ></pharmacy-information-block>
    </form-group-section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { FormGroupSection } from '@/components/shared'
import { PharmacyInformationBlock } from '../blocks'
import * as options from '@/modules/options'
import { useStore } from 'vuex'
import { WizardStateModels } from '@/models/store'
import { PharmacyModels } from '@/models/submission'

export default defineComponent({
  name: 'PharmacySection',
  components: { FormGroupSection, PharmacyInformationBlock },
  setup() {
    const store = useStore()
    const pharmacy = computed<PharmacyModels.PharmacyInformation>(
      () => store.getters['submission/getPharmacyInformation']
    )
    const pharmacyMeta = computed<WizardStateModels.WizardPrescriberFields>(
      () => store.getters['wizard/getPharmacyMeta']
    )

    const pharmacyInformationInputHandler = (
      pharmacyInformationValue: PharmacyModels.PharmacyInformation
    ): void => {
      store.commit('submission/setPharmacyInformation', pharmacyInformationValue)
    }

    const pharmacyInformationMetaHandler = (
      pharmacyInformationMeta: WizardStateModels.WizardPharmacyFields
    ): void => {
      store.commit('wizard/setPharmacyInformationMeta', pharmacyInformationMeta)
    }

    return {
      pharmacy,
      pharmacyMeta,
      options,

      pharmacyInformationInputHandler,
      pharmacyInformationMetaHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
.section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
}

.loading-background-section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 50px;
  width: 100%;
  z-index: 2;
}
</style>
