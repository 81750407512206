import axios from 'axios'
import { formatErrorMessageForDisplay, getBffRoute, getHeaderFromResponse } from '@/utils'
import { saveAs } from 'file-saver'

const getQuestion = async (submissionId: string, token: string, csrf: string) => {
  if (submissionId) {
    const headers = { authorization: token, 'x-csrf-token': csrf }
    const path = `${getBffRoute()}/api/submissions/${submissionId}/question`
    const response = await axios
      .get(path, {
        headers,
      })
      .catch(error => {
        throw new Error(error.message)
      })
    return response.data
  } else {
    throw Error('No Question Exists')
  }
}

const sendAnswer = async (
  submissionId: string,
  questionId: string,
  question: any,
  token: string,
  csrf: string
) => {
  if (submissionId) {
    const headers = { authorization: token, 'x-csrf-token': csrf }
    const path = `${getBffRoute()}/api/submissions/${submissionId}/question/${questionId}`
    const payload = {
      question: {
        answer: {
          type: question.type,
          value: question.value,
          attachmentId: question?.attachmentId,
        },
      },
    }
    const response = await axios
      .post(path, payload, {
        headers,
      })
      .catch(error => {
        const message = formatErrorMessageForDisplay(
          error?.response?.data?.error?.message || error.message || ''
        )
        throw Error(message)
      })
    return response
  } else {
    throw Error('No Question Exists')
  }
}

const uploadAttachment = async (
  submissionId: string,
  token: string,
  csrf: string,
  attachment: FormData,
  questionId: string | null
) => {
  if (submissionId) {
    const headers = {
      authorization: token,
      'x-csrf-token': csrf,
    }
    const params = { question_id: questionId }
    const path = `${getBffRoute()}/api/submissions/${submissionId}/question-attachment`
    const response = await axios
      .post(path, attachment, {
        responseType: 'json',
        headers,
        params,
      })
      .catch(error => {
        throw Error(error?.response?.data?.error?.message || error.message || '')
      })
    return response?.data?.attachmentId || null
  } else {
    throw Error('No submission exists.')
  }
}

const downloadAttachment = async (
  submissionId: string,
  token: string,
  csrf: string,
  attachmentId: string
) => {
  if (submissionId) {
    const headers = {
      authorization: token,
      'x-csrf-token': csrf,
    }
    const path = `${getBffRoute()}/api/submissions/${submissionId}/attachments/${attachmentId}`
    const response = await axios
      .get(path, {
        responseType: 'blob',
        headers,
      })
      .catch(error => {
        throw Error(error?.response?.data?.error?.message || error.message || '')
      })

    const mimeType = getHeaderFromResponse('x-file-type', response)

    if (response?.data && mimeType) {
      const blob = new Blob([response?.data], { type: mimeType })
      const ext = mimeType.split('/')[1]
      const fileName = `questionnaire-${attachmentId}`
      saveAs(blob, `${fileName}.${ext}`)
    } else {
      return false
    }
    return true
  } else {
    throw Error('No submission exists.')
  }
}

export { downloadAttachment, getQuestion, sendAnswer, uploadAttachment }
