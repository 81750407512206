import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0788bd9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id", "color"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'fieldset-container-' + _ctx.elementKey,
    class: "fieldset-container"
  }, [
    _createElementVNode("fieldset", {
      id: 'fieldset-' + _ctx.elementKey
    }, [
      _createElementVNode("legend", {
        id: 'legend-' + _ctx.elementKey,
        color: _ctx.color
      }, _toDisplayString(_ctx.sectionLabel), 9, _hoisted_3),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}