<template>
  <div
    v-if="attachmentId"
    :id="'questions-attachment-container-' + elementKey"
    class="questions-attachment-container"
  >
    <div class="row">
      <label :id="'questions-attachment-label-' + elementKey" class="form-field-label">
        {{ label }}
      </label>
      <div :id="'attachment-download-container-' + elementKey" class="attachment-input-container">
        <label class="wl-link" :disabled="disabled" @click="downloadFile">
          <span>{{ buttonLabel }}</span>
        </label>
      </div>
      <label :id="'questions-attachment-label-two-' + elementKey" class="form-field-label">
        {{ labelTwo }}
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { v4 as uuid } from 'uuid'

const props = defineProps({
  elementKey: {
    type: String,
    default: () => uuid(),
  },
  label: {
    type: String,
    default: '',
  },
  labelTwo: {
    type: String,
    default: '',
  },
  buttonLabel: {
    type: String,
    default: 'Download',
  },
  attachmentId: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dataPublic: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['on-click'])
const downloadFile = () => {
  if (!props.disabled && props.attachmentId) {
    emit('on-click', props.attachmentId)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.questions-attachment-container {
  position: relative;
}

.row {
  display: inline-flex;
  width: 100%;
  gap: 2px;
}
</style>
