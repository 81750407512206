<template>
  <div :id="'textarea-container-' + elementKey" class="textarea-input-container">
    <label
      v-if="label"
      :id="'textarea-container-label-' + elementKey"
      class="form-field-label"
      :data-required="required"
    >
      {{ label }}
    </label>
    <textarea
      :id="'textarea-container-input-' + elementKey"
      class="form-field"
      autocomplete="off"
      rows="4"
      @input="inputHandler"
      @blur="blurHandler"
      :value="inputValue"
      :data-error="invalid"
      :data-required="dataRequired"
      :disabled="disabled"
      :placeholder="disabled ? '' : placeholder"
      :data-public="!!dataPublic || undefined"
    />
    <div v-if="invalid && invalidHintText">
      <div :id="'textarea-container-input-invalid-hint-' + elementKey" class="invalid-hint">
        *{{ invalidHintText }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { v4 as uuid } from 'uuid'

export default defineComponent({
  name: 'TextAreaInput',
  emits: ['on-input', 'on-validate'],
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidHintText: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    validation: Function,
    dataPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const inputValue = ref<string | null>(null)
    const dataRequired = computed(() => props.required && !inputValue.value)

    const validate = (value: any): boolean => {
      if (!props.required) {
        return props.validation ? props.validation(value) : !props.validation
      }

      return !!value && !!(props.validation ? props.validation(value) : !props.validation)
    }

    const sendUpdateEvents = (value: any): void => {
      context.emit('on-input', value)
      context.emit('on-validate', validate(value))
    }

    const inputHandler = (event: any): void => {
      if (!props.disabled) {
        if (event?.target?.value) {
          inputValue.value = event?.target?.value
        } else {
          inputValue.value = null
        }
        sendUpdateEvents(inputValue.value)
      }
    }

    const blurHandler = (): void => {
      inputValue.value = inputValue.value?.trim() || null
      sendUpdateEvents(inputValue.value)
    }

    watch(
      () => props.value,
      value => {
        inputValue.value = value
      },
      { immediate: true }
    )

    return {
      dataRequired,
      inputValue,

      blurHandler,
      inputHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.textarea-input-container {
  position: relative;
}

textarea {
  resize: none;
  white-space: pre-wrap !important;
  overflow-wrap: break-word;
}

.form-field {
  width: 100%;
  padding-right: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &focus {
    outline: none;
  }
}

.invalid-hint {
  padding: 2px;
  color: #f14668;
  font-size: 12px;
  line-height: 14px;
  word-wrap: normal;
}
</style>
