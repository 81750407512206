export type ClinicalInformationQualifierStrings = '1' | '2'

export enum DiagnosisQualifier {
  ABF,
  DX,
  LD,
}
export type DiagnosisQualifierStrings = keyof typeof DiagnosisQualifier

export type QuantityQualifierNumbers = '38' | '40' | '87'
export enum QuantityQualifier {
  QS,
  CF,
  UQ,
  QT,
}
export type QuantityQualifierStrings = QuantityQualifierNumbers | keyof typeof QuantityQualifier
