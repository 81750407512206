<template>
  <div class="table-container">
    <div v-if="!noTitle && title" class="table-header">
      <h3>{{ title }}</h3>
    </div>
    <table>
      <thead>
        <tr>
          <th v-for="(header, index) in headers" :key="index">
            {{ formatNodeKey(header) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
          <td v-if="!isObject(row)" data-private>{{ row }}</td>
          <td v-else v-for="(value, colIndex) in row" :key="colIndex">
            <div v-if="isObject(value) && useNestedTables">
              <TableView :data="value" />
            </div>
            <div v-else-if="!isObject(value)" data-private>
              {{ value }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'

export default defineComponent({
  name: 'TableView',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    useNestedTables: {
      type: Boolean,
      required: false,
      default: true,
    },
    noTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    ignoreKeys: {
      type: Array,
      required: false,
      default: () => [] as string[],
    },
  },
  setup(props) {
    const headers = computed(() => {
      if (Array.isArray(props.data) && isObject(props.data[0])) {
        return Object.keys(props.data[0])
      } else if (Array.isArray(props.data) && !isObject(props.data[0])) {
        return []
      } else {
        const keys = Object.keys(props.data)
        const nonIgnoredKeys = keys.filter((key: string) => !props.ignoreKeys.includes(key))
        return nonIgnoredKeys.filter(
          (key: string) => !isObject(props.data[key]) || props.useNestedTables
        )
      }
    })

    const tableData = computed(() => {
      if (Array.isArray(props.data)) {
        return props.data
      } else {
        const showData: any = {}
        for (const header of headers.value) {
          showData[header] = props.data[header]
        }
        return [showData]
      }
    })

    const formatNodeKey = (key: string): string => {
      const spacedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2')
      return spacedKey.charAt(0).toUpperCase() + spacedKey.slice(1)
    }

    const isObject = (value: any) => {
      return typeof value === 'object' && value !== null
    }

    return {
      headers,
      tableData,

      formatNodeKey,
      isObject,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global';
@import '@/styles/input';

.table-container {
  display: block;
  padding: 5px;
  width: 100%;
}

.table-header {
  font-style: normal;
  letter-spacing: normal;
  font-weight: 600;
  color: #000;
  background-color: #ededed;
  padding: 0.625rem;
}

table {
  display: table;
  background-color: #fff;
  color: #363636;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    border: 1px solid #dee2e6;
    padding: 0 5px;
  }

  td {
    border: 1px solid #dbdbdb;
    padding: 0 5px;
    text-align: center;
  }
}
</style>
