import { State } from './state'
import { Icon, notify } from '@/modules/notification'
import CancellationService from '@/services/cancellation'

const ERROR_MESSAGE_TITLE = 'Oops! Something happened!'

const cancelRequest = async ({ state, rootState }: { rootState: any; state: State }) => {
  if (rootState.submissionId) {
    try {
      await CancellationService.cancelRequest(
        rootState.authToken,
        rootState.csrfToken,
        rootState.submissionId,
        { ...state }
      )
      return true
    } catch (error: any) {
      const errorText =
        error?.response?.status === 422
          ? 'Cancellation request was rejected by the payer. Be sure to check your authorization status in the dashboard. This authorization may have already completed.'
          : 'Cancellation request failed, please check your request and try again.'
      await notify(errorText, ERROR_MESSAGE_TITLE, Icon.ERROR)
    }
  } else {
    await notify(
      'Cancellation request failed, please check your request and try again.',
      ERROR_MESSAGE_TITLE,
      Icon.ERROR
    )
  }
  return false
}
export default { cancelRequest }
