<template>
  <div :id="'inner-fieldset-sub-container-' + elementKey" class="inner-container">
    <div :id="'inner-divider-' + elementKey" class="divider">
      <div :color="color" class="divider-label">{{ sectionLabel }}</div>
    </div>
    <div :id="'inner-sub-fields-container-' + elementKey" class="inner-fields">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { v4 as uuid } from 'uuid'

export default defineComponent({
  name: 'FormFieldSetInnerSection',
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    sectionLabel: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
}

.inner-fields {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 32px;
  -moz-column-gap: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
}

.divider {
  position: relative;
  margin: 22px 0 31px;
  width: 100%;
  border-bottom: 1px solid $myndshft-dark-blue;

  &-label {
    position: absolute;
    top: -15px;
    left: 32px;
    width: fit-content;
    font-size: 14px;
    color: $myndshft-white;
    padding: 4px 16px;
    border-radius: 4px;

    &[color='primary'] {
      background: var(--primary-color);
    }
    &[color='secondary'] {
      background: var(--secondary-color);
    }
    &[color='tertiary'] {
      background: var(--tertiary-color);
    }
  }
}
</style>
