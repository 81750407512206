import { AddressModels } from '@/models/submission'

const stateOptions = [
  {
    label: 'Alabama',
    value: AddressModels.StateCode[AddressModels.StateCode.AL],
  },
  {
    label: 'Alaska',
    value: AddressModels.StateCode[AddressModels.StateCode.AK],
  },
  {
    label: 'Arizona',
    value: AddressModels.StateCode[AddressModels.StateCode.AZ],
  },
  {
    label: 'Arkansas',
    value: AddressModels.StateCode[AddressModels.StateCode.AR],
  },
  {
    label: 'California',
    value: AddressModels.StateCode[AddressModels.StateCode.CA],
  },
  {
    label: 'Colorado',
    value: AddressModels.StateCode[AddressModels.StateCode.CO],
  },
  {
    label: 'Connecticut',
    value: AddressModels.StateCode[AddressModels.StateCode.CT],
  },
  {
    label: 'Delaware',
    value: AddressModels.StateCode[AddressModels.StateCode.DE],
  },
  {
    label: 'District Of Columbia',
    value: AddressModels.StateCode[AddressModels.StateCode.DC],
  },
  {
    label: 'Florida',
    value: AddressModels.StateCode[AddressModels.StateCode.FL],
  },
  {
    label: 'Georgia',
    value: AddressModels.StateCode[AddressModels.StateCode.GA],
  },
  {
    label: 'Hawaii',
    value: AddressModels.StateCode[AddressModels.StateCode.HI],
  },
  {
    label: 'Idaho',
    value: AddressModels.StateCode[AddressModels.StateCode.ID],
  },
  {
    label: 'Illinois',
    value: AddressModels.StateCode[AddressModels.StateCode.IL],
  },
  {
    label: 'Indiana',
    value: AddressModels.StateCode[AddressModels.StateCode.IN],
  },
  {
    label: 'Iowa',
    value: AddressModels.StateCode[AddressModels.StateCode.IA],
  },
  {
    label: 'Kansas',
    value: AddressModels.StateCode[AddressModels.StateCode.KS],
  },
  {
    label: 'Kentucky',
    value: AddressModels.StateCode[AddressModels.StateCode.KY],
  },
  {
    label: 'Louisiana',
    value: AddressModels.StateCode[AddressModels.StateCode.LA],
  },
  {
    label: 'Maine',
    value: AddressModels.StateCode[AddressModels.StateCode.ME],
  },
  {
    label: 'Maryland',
    value: AddressModels.StateCode[AddressModels.StateCode.MD],
  },
  {
    label: 'Massachusetts',
    value: AddressModels.StateCode[AddressModels.StateCode.MA],
  },
  {
    label: 'Michigan',
    value: AddressModels.StateCode[AddressModels.StateCode.MI],
  },
  {
    label: 'Minnesota',
    value: AddressModels.StateCode[AddressModels.StateCode.MN],
  },
  {
    label: 'Mississippi',
    value: AddressModels.StateCode[AddressModels.StateCode.MS],
  },
  {
    label: 'Missouri',
    value: AddressModels.StateCode[AddressModels.StateCode.MO],
  },
  {
    label: 'Montana',
    value: AddressModels.StateCode[AddressModels.StateCode.MT],
  },
  {
    label: 'Nebraska',
    value: AddressModels.StateCode[AddressModels.StateCode.NE],
  },
  {
    label: 'Nevada',
    value: AddressModels.StateCode[AddressModels.StateCode.NV],
  },
  {
    label: 'New Hampshire',
    value: AddressModels.StateCode[AddressModels.StateCode.NH],
  },
  {
    label: 'New Jersey',
    value: AddressModels.StateCode[AddressModels.StateCode.NJ],
  },
  {
    label: 'New Mexico',
    value: AddressModels.StateCode[AddressModels.StateCode.NM],
  },
  {
    label: 'New York',
    value: AddressModels.StateCode[AddressModels.StateCode.NY],
  },
  {
    label: 'North Carolina',
    value: AddressModels.StateCode[AddressModels.StateCode.NC],
  },
  {
    label: 'North Dakota',
    value: AddressModels.StateCode[AddressModels.StateCode.ND],
  },
  {
    label: 'Ohio',
    value: AddressModels.StateCode[AddressModels.StateCode.OH],
  },
  {
    label: 'Oklahoma',
    value: AddressModels.StateCode[AddressModels.StateCode.OK],
  },
  {
    label: 'Oregon',
    value: AddressModels.StateCode[AddressModels.StateCode.OR],
  },
  {
    label: 'Palau',
    value: AddressModels.StateCode[AddressModels.StateCode.PW],
  },
  {
    label: 'Pennsylvania',
    value: AddressModels.StateCode[AddressModels.StateCode.PA],
  },
  {
    label: 'Rhode Island',
    value: AddressModels.StateCode[AddressModels.StateCode.RI],
  },
  {
    label: 'South Carolina',
    value: AddressModels.StateCode[AddressModels.StateCode.SC],
  },
  {
    label: 'South Dakota',
    value: AddressModels.StateCode[AddressModels.StateCode.SD],
  },
  {
    label: 'Tennessee',
    value: AddressModels.StateCode[AddressModels.StateCode.TN],
  },
  {
    label: 'Texas',
    value: AddressModels.StateCode[AddressModels.StateCode.TX],
  },
  {
    label: 'Utah',
    value: AddressModels.StateCode[AddressModels.StateCode.UT],
  },
  {
    label: 'Vermont',
    value: AddressModels.StateCode[AddressModels.StateCode.VT],
  },
  {
    label: 'Virginia',
    value: AddressModels.StateCode[AddressModels.StateCode.VA],
  },
  {
    label: 'Washington',
    value: AddressModels.StateCode[AddressModels.StateCode.WA],
  },
  {
    label: 'West Virginia',
    value: AddressModels.StateCode[AddressModels.StateCode.WV],
  },
  {
    label: 'Wisconsin',
    value: AddressModels.StateCode[AddressModels.StateCode.WI],
  },
  {
    label: 'Wyoming',
    value: AddressModels.StateCode[AddressModels.StateCode.WY],
  },
]

export { stateOptions }
