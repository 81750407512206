import { State } from './state'
import { CancellationModels as TypeModels } from '@/models/cancellation'
import { CancellationModels as StoreModels } from '@/models/store'

const getCancellationState = (state: State): StoreModels.CancellationState => {
  return state
}

const getCase = (state: State): TypeModels.Case => {
  return state.case
}

const getReason = (state: State): TypeModels.Reason => {
  return state.reason ?? null
}

const getPatientHasBeenNotified = (state: State): TypeModels.PatientHasBeenNotified => {
  return state.patientHasBeenNotified ?? null
}

const getAdditionalNotes = (state: State): TypeModels.AdditionalNotes => {
  return state.additionalNotes ?? null
}

export default {
  getAdditionalNotes,
  getCase,
  getReason,
  getCancellationState,
  getPatientHasBeenNotified,
}
